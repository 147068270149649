import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
const ItemHotel = ({
  id,
  nombre,
  ciudad,
  descuento,
  imagenes,
  descripcionCorta,
  dias,
  tipo,
  habitaciones,
}) => {
  const navigate = useNavigate();
  let precioFinal = 0;
  const [imag, setImag] = useState([]);
  const [precio, setPrecio] = useState(0);
  let precios = 0;
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";

  useEffect(() => {
    if (imagenes.length > 0) {
      getImagen();
    }
    if (habitaciones) {
      changePrecio();
    }
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes[0]);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imag);
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const changePrecio = () => {
    if (habitaciones[0]) setPrecio((prev) => Number(habitaciones[0]?.precio));
  };

  return (
    <div class="media media-list-view media-border flex-column flex-md-row align-items-stretch mb-5">
      <div class="media-img position-relative">
        <img
          class="img-fluid position-relative lazyestload"
          src={imag}
          alt="gallery-img"
          style={{ maxHeight: "18rem" }}
        />
        <a
          class="media-img-overlay"
          href="javascript:void(0)"
          onClick={() => navigate(`/hoteles/${id}`)}
        ></a>
      </div>

      <div class="media-body">
        <div class="row">
          <div class="col-md-7 col-xl-8">
            <h4>
              <a
                class="media-title"
                href="javascript:void(0)"
                onClick={() => navigate(`/hoteles/${id}`)}
              >
                {nombre}
              </a>
            </h4>
            <div class="rating-view">
              <span class="star add-rating-default pl-0"></span>
              <span class="content-view text-uppercase border-right-0 pl-1">
                {/* (Reviews 29) */}
              </span>
            </div>

            <p
              class="mb-md-0"
              dangerouslySetInnerHTML={{
                __html: descripcionCorta?.slice(0, 200),
              }}
            ></p>
            <div class="date-view" style={{ width: "99%" }}>
              <span
                className="text-truncate"
                style={{
                  textWrap: "nowrap",
                  overflow: "hidden",
                  maxWidth: "23rem",
                  width: "100%",
                }}
              >
                {ciudad}
              </span>
              {/* <span>
                <i class="fa fa-clock-o" aria-hidden="true"></i> 5 days
              </span> */}
            </div>
          </div>

          <div class="col-md-5 col-xl-4">
            <div class="bg-primary text-center p-3 view-details">
              <h2 class="text-white font-weight-bold h1">
                <span
                  className="font-weight-normal"
                  style={{ fontSize: "15px" }}
                >
                  Desde
                </span>{" "}
                ${precio}
              </h2>
              <p
                class="text-white text-capitalize"
                style={{ fontSize: "15px" }}
              >
                Por noche
              </p>
              <div>
                <a
                  href="javascript:void(0)"
                  onClick={() => navigate(`/hoteles/${id}`)}
                  class="btn btn-xs btn-block btn-outline-white btn-booking text-uppercase mb-2"
                >
                  Details
                </a>
              </div>
              <div>
                {" "}
                <p
                  class="text-white text-uppercase mb-2"
                  style={{ fontSize: "15px" }}
                >
                  <span className="d-block"> {descuento}% off </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemHotel;
