import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import Amenidades from "./Amenidades";
import ImagenesModal from "./ImagenesModal";
import { useCart } from "react-use-cart";
import { AuthContext } from "../../../context/AuthContext";
import { Button } from "@mui/material";
import HabitacionService from "../../../services/Habitacion.service";

const ItemHabitacion = ({ habitacion, fechas, hotelId, disponibilidad }) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  let precioFinal = 0;
  const [imag, setImag] = useState([]);
  const [precio, setPrecio] = useState(0);
  const [fecha, setFecha] = useState();
  let precios = 0;
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";

  const {
    items,
    addItem,
    removeItem,
    removeLineItem,
    clearCart,
    lineItemsCount,
  } = useCart();

  useEffect(() => {
    setImag([]);
    if (habitacion.imagenes.length > 0) {
      getImagen();
    }
  }, []);

  const getImagen = async () => {
    try {
      for (const url of habitacion.imagenes) {
        const imagen = await S3Service.get(url);
        setImag((imagenes) => [...imagenes, imagen.result]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const ImpDescuento = () => {
    if (habitacion.descuento > 0) {
      return (
        <div className="badge badge-primary badge-rounded-circle">
          <span className="d-block">
            {" "}
            {habitacion.descuento}%
            <br />
            off{" "}
          </span>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const vaciarCart = async () => {
    items.map((record, index) => {
      removeItem(record.id);
    });
  };

  const reservar = async () => {
    if (currentUser === undefined) {
      toast.error("Por favor inicia sesión para comprar");
      return;
    }

    if (!disponibilidad) {
      toast.info("Por favor consulta la diponibilidad para comprar");
      return;
    }

    // let fechaFinal = new Date(fecha);
    //let fechaFinal=f.getDate()+paquete.dias.length();
    // fechaFinal.setDate(fechaFinal.getDate() + dias);
    const result = await HabitacionService.habitacionDisponible({
      habitacionId: habitacion.id,
      fechaInicial: fechas.fechaInicio,
      fechaFinal: fechas.fechaFinal,
    });

    if (result?.total < 1) {
      toast.info(
        "No tenemos habitaciones disponibles en la fecha seleccionada."
      );
      return;
    }

    console.log(result);

    const object = {
      id: idUnico(habitacion.id + 1),
      hotelId: hotelId,
      habitacionId: habitacion.id,
      tipo: habitacion.tipo,
      price: habitacion.precio,
      cupo: habitacion.cupo,
      fechaInicial: fechas.fechaInicio,
      fechaFinal: fechas.fechaFinal,
      agenda: result.data[0].agendas[0],
    };
    addItem(object);
    console.log("Item agregado Adulto:" + object.name);
    navigate(`/bookingHotelStepUno/${habitacion.id}`);
  };

  const idUnico = (length) => {
    try {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    } catch (error) {
      CSSConditionRule.log(error);
    }
  };
  return (
    <div class="mb-6 border-bottom">
      <h4 class="text-uppercase mb-5">{habitacion?.tipo}</h4>
      <div class="mb-5">
        <div class="row">
          <Amenidades amenidades={habitacion?.amenidades} />
        </div>
      </div>

      <div class="d-md-flex justify-content-between align-items-center mb-5">
        <ul class="list-unstyled d-flex flex-wrap justify-content-center justify-content-md-start font-weight-medium mb-md-0">
          <li class="">
            {habitacion.cama.map((cama) => (
              <p>
                <span>Cama: </span>
                {cama.cama}
                {/* <span> Ocupantes: </span> {cama.ocupantes} */}
              </p>
            ))}
          </li>

          <li class=""></li>
        </ul>

        <h5 class="text-center text-lg-left">
          <span class="text-gray-color font-weight-bold text-capitalize">
            Por noche:
          </span>
          <span class="text-primary font-weight-bold">
            ${habitacion?.precio}
          </span>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="outlined"
              color="success"
              onClick={() => reservar()}
              style={{ margin: "5px" }}
            >
              Reservar
            </Button>
          </div>
        </h5>
      </div>

      <ImagenesModal
        fechas={fechas}
        habitacion={habitacion}
        hotelId={hotelId}
        imagenes={imag}
      />
    </div>
  );
};

export default ItemHabitacion;
