/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";

/* Servicios */
import NoticiaService from "../../../services/Noticia.service";
import S3Service from "../../../services/S3.service";

import styles from "../layout.module.css";

const EliminarBlog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noticia, setNoticia] = useState({});
  const [loading, setLoading] = useState(true);
  const [imagen, setImagen] = useState("");
  useEffect(() => {
    getNoticia();
  }, []);

  const getNoticia = async () => {
    setLoading(true);
    try {
      const data = await NoticiaService.getById(parseInt(id));
      setNoticia(data[0]);
      if (data[0].imagen) {
        const imagenS3 = await S3Service.get(data[0].imagen);
        setImagen(imagenS3.result);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteNoticia = async () => {
    setLoading(true);
    try {
      console.log("ELIMINAR");
      if (noticia.imagen) {
        const imagen = await S3Service.delete(noticia.imagen);
        console.log("---------se borro" + imagen.result);
      }
      const noticiaBorrada = await NoticiaService.remove(id);
      toast.info("Noticia eliminada");
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setLoading(false);
      navigate("/blog");
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          style={{
            boxShadow: "var(--box-shadow)",
            backgroundColor: "var(--color-white)",
          }}
        >
          <div className={styles.fulldiv}>
            <div style={{ boxShadow: "none" }}>
              <h1>ELIMINAR BLOG</h1>
              <div className={styles.recentorders}>
                <div style={{ overflowX: "auto" }}>
                {noticia.imagen ? (
                      <div className={styles.row}>
                        <div className="col-lg-12">
                          <div style={{ textAlign: "center" }}>
                            <img
                              src={String(imagen)}
                              style={{
                                height: 300,
                                objectFit: "contain",
                                width: "-webkit-fill-available",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  <table
                    id="recent-orders--table"
                    style={{ boxShadow: "initial", textAlign: "justify" }}
                  >
                   
                    <thead>
                      <tr>
                        <th>Titulo</th>
                        <th>Categoria</th>
                      </tr>
                    </thead>
                    <tr />
                    <tbody>
                      <tr>
                        <td>{noticia.titulo}</td>
                        <td>{noticia.categoria}</td>
                      </tr>
                      <tr>
                        <th>Descripcion</th>
                      </tr>
                      <tr>
                        <td>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: noticia.cuerpoNoticia,
                            }}
                            style={{
                              textAlign: "justify",
                              marginRight: "10%",
                            }}
                          ></p>
                        </td>
                      </tr>
                      <tr>
                        <th>Frase celebre</th>
                        <th>Autor</th>
                      </tr>
                      <tr>
                        <td>
                          {noticia.fraseCelebre}
                        </td>
                        <td>
                          {noticia.autorFraseCelebre}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className={styles.recentorders}>
            <div style={{ overflowX: "auto" }}>
              <table
                id="recent-orders--table"
                style={{ boxShadow: "initial", textAlign: "justify" }}
              >
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => navigate("/blog")}
                      style={{ margin: "5px" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => deleteNoticia()}
                      style={{ margin: "5px" }}
                    >
                      ELIMINAR
                    </Button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EliminarBlog;
