import { useState, useEffect, useContext } from "react";
import { CircularProgress, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import HabitacionService from "../../../services/Habitacion.service";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { AuthContext } from "../../../context/AuthContext";
import ReservaService from "../../../services/Reserva.service";
import { nanoid } from "nanoid";
import Reservacion from "./Reservacion";
import { toast } from "react-toastify";
import PostAddIcon from "@mui/icons-material/PostAdd";
import IconButton from "@mui/material/IconButton";
import FormReservacion from "./FormReservacion";

const ListaReservaciones = ({ handleStatus, fechaData, onSumit }) => {
  const [habitacion, setHabitacion] = useState(null);
  const [reservas, setReservas] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [showFormReserva, setShowFormReserva] = useState(false);
  const [agenda, setAgenda] = useState(null);
  const [update, setUpdate] = useState(false);
  const [fechaInicioAgenda, setFechaInicioAgenda] = useState(null);
  const [fechaFinalAgenda, setFechaFinalAgenda] = useState(null);

  let today = new Date();
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );

  useEffect(() => {
    if (fechaData?.date) {
      console.log(fechaData.date);
      let newDate = new Date(`${fechaData.date} 00:00:00`);
      setFiltroFechaInicio(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(newDate)
      );
    }
    getHabitacion();
  }, [fechaData, update]);

  const getHabitacion = async () => {
    try {
      const resutl = await HabitacionService.listReservasHabitacion({
        habitacion: fechaData.value,
        fecha: fechaData.date,
      });

      setHabitacion(resutl.habitacionResult);
      setReservas(resutl.habitacionResult.reservas);
      setAgenda(resutl.agenda);

      setFechaInicioAgenda(new Date(resutl.agenda.fechaInicio));
      setFechaFinalAgenda(new Date(resutl.agenda.fechaFinal));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = (removeId) => {
    let oldReservas = reservas.filter((r) => r.id !== removeId);
    setReservas(oldReservas);
    toast.info("Reserva eliminada");
  };

  const handleShowFormReserva = () => {
    setShowFormReserva((prev) => !prev);
  };

  const onSumitForm = () => {
    onSumit();
    setUpdate((prev) => !prev);
  };

  return (
    <Box sx={{ width: "450px" }} role="presentation">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div>
          <div style={{ backgroundColor: "#e9036e", height: "4rem" }}></div>
          <h2
            class="card-header text-uppercase text-center bg-smoke border-bottom"
            style={{ padding: "1.8rem" }}
          >
            Agenda fecha: {fechaInicioAgenda?.toLocaleDateString()} -{" "}
            {fechaFinalAgenda?.toLocaleDateString()}
          </h2>
          <div>
            <h3>{habitacion?.hotel?.nombre}</h3>
            <div
              style={{
                display: "flex",
                gap: "2rem",
                paddingInline: "1.8rem",
              }}
            >
              <div>
                {" "}
                <IconButton
                  aria-label="check"
                  onClick={() => {
                    handleShowFormReserva();
                  }}
                >
                  <PostAddIcon sx={{ height: 28, width: 28 }} />
                </IconButton>
              </div>
              <div>
                <h4>Habitacion tipo:</h4>
                {habitacion?.tipo}
              </div>
              <div>
                <h4>Precio:</h4> ${habitacion?.precio}
              </div>
              <div>
                {" "}
                <h4>Cupo:</h4>
                {habitacion?.cupo}
              </div>
            </div>
            {showFormReserva ? (
              <FormReservacion
                fechaData={fechaData}
                onSumit={onSumitForm}
                agenda={agenda}
                handleShowFormReserva={handleShowFormReserva}
              />
            ) : (
              <div style={{ display: "grid", gap: "1rem", padding: "1.8rem" }}>
                {reservas?.map((r, index) => (
                  <Reservacion
                    key={index}
                    reservacion={r}
                    onSumit={onSumit}
                    handleRemove={handleRemove}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </Box>
  );
};

export default ListaReservaciones;
