/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

import styles from "../layout.module.css";

/* Servicios */
import UbicacionService from "../../../services/UbicacionService";
import MapLibreUe from "./MapLibreUe";
import MapGoogle from "./MapGoogle/MapGoogle";

const EditarUbicacion = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ubicacion, setUbicacion] = useState({});
  const [loading, setLoading] = useState(true);
  const [mostrarI, setMostrarI] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await UbicacionService.getById(id);
      setUbicacion(data);
      setMostrarI(!data.mostrarI);
      console.log(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const actualizarUbicacion = async () => {
    if (!ubicacion.lat) {
      toast.info("Error, tienes que colocar una latitud");
    } else if (!ubicacion.lng) {
      toast.info("Error, tienes que colocar una longitud");
    } else if (!ubicacion.ciudad) {
      toast.info("Error, tienes que colocar una ciudad");
    } else if (!ubicacion.pais) {
      toast.info("Error, tienes que colocar un pais");
    } else if (!ubicacion.continente) {
      toast.info("Error, tienes que colocar un continente");
    } else {
      setLoading(true);
      try {
        let data = {};
        data = {
          id: ubicacion.id,
          lat: ubicacion.lat,
          lng: ubicacion.lng,
          ciudad: ubicacion.ciudad,
          pais: ubicacion.pais,
          continente: ubicacion.continente,
          mostrarI: !mostrarI,
        };

        const UbicacionActualizada = await UbicacionService.update(data);
        console.log(UbicacionActualizada);
        toast.success("Ubicacion actualizada con exito");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        navigate("/ubicacion");
      }
    }
  };

  const handleDirection = (data) => {
    data.id = ubicacion.id;
    setUbicacion(data);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          style={{
            boxShadow: "var(--box-shadow)",
            backgroundColor: "var(--color-white)",
          }}
        >
          <div style={{ boxShadow: "none" }}>
            <div className={styles.recentorders}>
              <div
                style={{ width: "100%", height: "23rem", position: "relative" }}
              >
                {/* <MapLibreUe
                  handleDirection={handleDirection}
                  positionAprox={[Number(ubicacion.lng), Number(ubicacion.lat)]}
                /> */}
                <MapGoogle
                  handleDirection={handleDirection}
                  positionApproximate={[
                    Number(ubicacion.lat),
                    Number(ubicacion.lng),
                  ]}
                />
              </div>
              <div style={{ overflowX: "auto" }}>
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <thead>
                    <tr>
                      <th>
                        <h1 style={{ marginBottom: "3%" }}>Editar ubicación</h1>
                      </th>
                    </tr>
                    <tr>
                      Mostrar en busqueda rápida
                      <Switch
                        checked={!mostrarI}
                        onClick={() => {
                          mostrarI ? setMostrarI(false) : setMostrarI(true);
                          console.log("estado:" + mostrarI);
                        }}
                      />
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>Ciudad</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <input
                          id="ciudad"
                          placeholder="Ciudad"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#dce1eb",
                            padding: "2%",
                            marginLeft: "-1%",
                            width: "100%",
                          }}
                          onChange={(e) =>
                            setUbicacion({
                              ...ubicacion,
                              ciudad: e.target.value,
                            })
                          }
                          value={ubicacion?.ciudad}
                        />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input
                          id="lat"
                          placeholder="Latitud"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#dce1eb",
                            padding: "6%",
                            marginLeft: "-1%",
                            width: "100%",
                          }}
                          onChange={(e) =>
                            setUbicacion({
                              ...ubicacion,
                              lat: e.target.value,
                            })
                          }
                          value={ubicacion?.lat}
                        />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input
                          id="lng"
                          placeholder="Longitud"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#dce1eb",
                            padding: "6%",
                            marginLeft: "-1%",
                            width: "100%",
                          }}
                          onChange={(e) =>
                            setUbicacion({
                              ...ubicacion,
                              lng: e.target.value,
                            })
                          }
                          value={ubicacion?.lng}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <thead>
                    <tr>
                      <th>País</th>
                      <th>Continente</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "50%" }}>
                        <div>
                          <input
                            id="pais"
                            placeholder="Pais"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "100%",
                            }}
                            onChange={(e) =>
                              setUbicacion({
                                ...ubicacion,
                                pais: e.target.value,
                              })
                            }
                            value={ubicacion?.pais}
                          />
                        </div>
                      </td>
                      <td style={{ width: "50%" }}>
                        <div>
                          <input
                            id="continente"
                            placeholder="Continente"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#dce1eb",
                              padding: "3%",
                              marginLeft: "-1%",
                              width: "100%",
                            }}
                            onChange={(e) =>
                              setUbicacion({
                                ...ubicacion,
                                continente: e.target.value,
                              })
                            }
                            value={ubicacion?.continente}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/*Boton*/}
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate("/ubicacion")}
                        style={{ margin: "5px" }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => actualizarUbicacion()}
                        style={{ margin: "5px" }}
                      >
                        Guardar
                      </Button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditarUbicacion;
