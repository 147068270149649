import { useState, forwardRef, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import PropTypes from "prop-types";
import { Box, styled } from "@mui/system";
import { Modal, TextField } from "@mui/material";
import Fade from "@mui/material/Fade";
import { Button } from "@mui/material";
import Carousel from "react-bootstrap/Carousel";
import FullPageLoading from "../../FullPageLoading";
import S3Service from "../../../services/S3.service";
import styles from "../layout.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";

const ImagenesModal = ({ habitacion, onSumit, fechas, hotelId }) => {
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery("(min-width:800px)");
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [imagenes, setImagenes] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [fecha, setFecha] = useState();
  const { currentUser } = useContext(AuthContext);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChangeCarouselIndex = (newIndex) => {
    setCarouselIndex(newIndex);
    setOpen(true);
  };

  let dias = 0;
  let cargaPrecio = 0;
  var today = new Date();
  let check = 0;
  let adultoPrecio = 0;
  let adultoQuantity = 0;

  const {
    items,
    addItem,
    removeItem,
    removeLineItem,
    clearCart,
    lineItemsCount,
  } = useCart();

  useEffect(() => {
    setImagenes([]);
    getImagenes();
    vaciarCart();
  }, [habitacion]);

  const vaciarCart = async () => {
    items.map((record, index) => {
      removeItem(record.id);
    });
  };

  const getImagenes = async () => {
    setLoading(true);
    try {
      for (const url of habitacion.imagenes) {
        const imagen = await S3Service.get(url);
        setImagenes((imagenes) => [...imagenes, imagen.result]);
      }

      console.log(imagenes);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const reservar = () => {
    if (currentUser == undefined) {
      toast.error("Por favor inicia sesión para comprar");
    } else {
      let fechaFinal = new Date(fecha);
      //let fechaFinal=f.getDate()+paquete.dias.length();
      fechaFinal.setDate(fechaFinal.getDate() + dias);

      const object = {
        id: idUnico(habitacion.id + 1),
        hotelId: hotelId,
        habitacionId: habitacion.id,
        tipo: habitacion.tipo,
        price: habitacion.precio,
        cupo: habitacion.cupo,
        fechaInicial: fechas.inicio,
        fechaFinal: fechas.final,
      };
      addItem(object);
      console.log("Item agregado Adulto:" + object.name);
      navigate(`/bookingHotelStepUno/${habitacion.id}`);
    }
  };

  const idUnico = (length) => {
    try {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    } catch (error) {
      CSSConditionRule.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          <div class="px-lg-2 pb-3">
            <div
              class="row"
              style={{
                gap: "1rem",
                padding: "1rem",
                backgroundColor: "whitesmoke",
              }}
            >
              {imagenes?.map((img, index) => (
                <div key={index}>
                  <div class="media img-overlay" style={{ width: "16.7rem" }}>
                    <div
                      class="media-content"
                      onClick={() => handleChangeCarouselIndex(index)}
                    >
                      <img
                        class="media-img rounded lazyestload"
                        src={img}
                        alt="Generic placeholder image"
                        style={{ width: "16.7rem", height: "11.7rem" }}
                      />
                      <a
                        href="javascript:void(0)"
                        class="hover-img-overlay-dark"
                      ></a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <StyledModal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: StyledBackdrop }}
            style={{ width: "100%", height: "100%" }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div
                  className="swiper-container swiper-btn-group swiper-btn-group-end text-white p-0 overflow-hidden"
                  style={{ width: "100%" }}
                >
                  <div className="swiper-wrapper h-100">
                    <Carousel
                      slide={false}
                      nextLabel={""}
                      prevLabel={""}
                      activeIndex={carouselIndex}
                      onSelect={handleChangeCarouselIndex}
                      indicators={true}
                      data-bs-theme="dark"
                      style={{
                        margin: "0.5rem",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {imagenes?.map((record, index) => {
                        return (
                          <Carousel.Item key={index}>
                            <div
                              style={{
                                height: "44.5rem",
                                width: "100%",
                                backgroundImage: `url(${record})`,
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            ></div>
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </div>
                  {/* Add Arrows */}
                </div>
              </Box>
            </Fade>
          </StyledModal>
        </>
      )}
    </>
  );
};

export default ImagenesModal;

const Backdrop = forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "16px 32px 24px 32px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "#0000005e",
  display: "flex",
  flexWrap: "wrap",
  minWidth: "30rem",
  overflowY: "auto",
  width: "96%",
});
