/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";

/* Components & dependencies */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import DropZone from "../../DropZone";
import DropComponent from "../imagesDrop";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import Button from "@mui/material/Button";
import { stateToHTML } from "draft-js-export-html";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import GoogleMaps from "simple-react-google-maps";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";

import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

/*Services */
import S3Service from "../../../services/S3.service";
import FullPageLoading from "../../FullPageLoading";
import CardDay from "./CardDay";
import CardPaquetePrecio from "./CardPaqutePrecio";
import PaqueteService from "../../../services/Paquete.service";
import UbicacionService from "../../../services/UbicacionService";

import styles from "../layout.module.css";

const CrearPaquete = () => {
  //#region variables
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [paquete, setPaquete] = useState(false);
  const [ubicacion, setUbicacion] = useState([]);
  const [file, setFile] = useState(undefined);
  const [mostrarI, setMostrarI] = useState(false);
  const [ubicacion_NEW, setUbicacion_NEW] = useState({});
  const [mostrarI_NEW, setMostrarI_NEW] = useState(false);
  const [ciudad, setCiudad] = useState("");

  const [dia, setDia] = useState({});
  const [diasArray, setDiasArray] = useState([]);
  const [diaContador, setDiaContador] = useState(0);

  const [paquetePrecioU, setpaquetePrecioU] = useState({});
  const [paquetePrecioArrayU, setpaquetePrecioArrayU] = useState([]);
  const [precioUContador, setPrecioUContador] = useState(0);

  const [paquetePrecioE, setpaquetePrecioE] = useState({});
  const [paquetePrecioArrayE, setpaquetePrecioArrayE] = useState([]);
  const [precioEContador, setPrecioEContador] = useState(0);
  const [ubicaciones, setUbicaciones] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [imagenesCarrusel, setImagenesCarrusel] = useState([]);
  const myTheme = createTheme({});
  //#endregion

  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyAkITgblqZ2pdFC9-JBv6T3fZogUc93TY8",
  //   libraries: ["places"],
  // });

  const PlacesAutocomplete = ({ setSelected }) => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();

      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setSelected({ lat, lng });
    };

    return (
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="combobox-input"
          placeholder="Search an address"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    );
  };

  const saveUbicacion = async () => {
    if (!ubicacion_NEW.lat) {
      toast.info("Error, tienes que colocar una latitud");
    } else if (!ubicacion_NEW.lng) {
      toast.info("Error, tienes que colocar una longitud");
    } else if (!ubicacion_NEW.ciudad) {
      toast.info("Error, tienes que colocar una ciudad");
    } else if (!ubicacion_NEW.pais) {
      toast.info("Error, tienes que colocar un pais");
    } else if (!ubicacion_NEW.continente) {
      toast.info("Error, tienes que colocar un continente");
    } else {
      setLoading(true);
      try {
        const datosUbicacion = ubicacion_NEW;
        console.log(datosUbicacion);
        await UbicacionService.create(datosUbicacion);
        toast.success("Ubicacion creada con exito");
        getUbicacion();
        setUbicacion_NEW(undefined);
      } catch (error) {
        console.log(error);
      } finally {
        handleClose();
      }
    }
  };

  function Map() {
    const center = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
    const [selected, setSelected] = useState(null);

    return (
      <>
        <div className="places-container">
          <PlacesAutocomplete setSelected={setSelected} />
        </div>

        <GoogleMap
          zoom={10}
          center={center}
          mapContainerClassName="map-container"
        >
          {selected && <Marker position={selected} />}
        </GoogleMap>
      </>
    );
  }

  useEffect(() => {
    getUbicacion();
    setPaquete({
      ...paquete,
      mostrarInicio: false,
    });
    setPaquete({
      ...paquete,
      descuento: 0,
    });
    setUbicacion({
      ...ubicacion,
      mostrarI: true,
    });
  }, []);

  const getUbicacion = async () => {
    setLoading(true);
    try {
      const data = await UbicacionService.getAll();
      setUbicacion(data);
    } catch (error) {
      console.log(error);
      //   navigate("/noticias");
    } finally {
      setLoading(false);
    }
  };

  const addCiudad = (id, ciudad) => {
    setPaquete({
      ...paquete,
      ubicacion: id,
    });
    setCiudad(ciudad);
  };

  const guardarPaquete = async () => {
    try {
      if (!paquete.titulo) {
        toast.info("Error, tienes que agregar un titulo");
      } else if (!paquete.subtitulo) {
        toast.info("Error, tienes que colocar un subtitulo");
      } else if (!paquete.descripcion) {
        toast.info("Error, tienes que colocar una descripción");
      } else if (!paquete.incluye) {
        toast.info("Error, tienes que colocar lo qu incluye el paquete");
      } else if (!file) {
        toast.info("Error, tienes que colocar una imagen para el paquete");
      } else if (!paquete.tituloItinerario) {
        toast.info("Error, tienes que colocar un titulo al itinerario");
      } else if (!diasArray) {
        toast.info("Error, tienes que colocar dias al itinerario");
      } else if (!paquete.fechaInicial) {
        toast.info("Error, tienes que colocar una fecha inicial");
      } else if (!paquete.fechaFinal) {
        toast.info("Error, tienes que colocar una fecha final");
      } else if (!paquetePrecioArrayU) {
        toast.info("Error, tienes que colocar precio para los usuarios");
      } else if (!paquetePrecioArrayE) {
        toast.info("Error, tienes que colocar precio para las empresas");
      } else if (!paquete.cupo) {
        toast.info("Error, tienes que colocar un cupo máximo");
      } else {
        setLoading(true);
        let imagenesArray = []; //Array para los id del s3
        let diasjson = []; //Array para almecenar los dias en formatos JSON y guardarlos en la BD

        let paquetePrecioU = [];
        let paquetePrecioE = [];
        /* Subida de imagenes del carrusel */
        for (const file of imagenesCarrusel) {
          const resultFile = await S3Service.upload(file);
          console.log("imagen carrusel guardad:" + resultFile.result.data);
          imagenesArray.push(resultFile.result.data);
        }

        /* Subida de imagenes de los dias */
        for (const dia of diasArray) {
          const resultImagenDia = await S3Service.upload(dia.imagen);
          const diaJSON = {
            titulo: dia.titulo,
            descripcion: dia.descripcion,
            imagen: resultImagenDia.result.data,
          };
          diasjson.push(diaJSON);
        }

        const {
          titulo,
          subtitulo,
          descripcion,
          incluye,
          tituloItinerario,
          descuento,
          ubicacion,
          fechaInicial,
          fechaFinal,
          cupo,
        } = paquete;

        const dataPaquete = {
          titulo,
          subtitulo,
          descripcion,
          incluye,
          tituloItinerario,
          ubicacion,
          fechaInicial,
          fechaFinal,
          cupo,
          ciudad: ciudad,
          descuento: parseInt(descuento),
          imagenes: imagenesArray,
          diasJson: diasjson,
          paquetesUsuario: paquetePrecioArrayU,
          paquetesEmpresa: paquetePrecioArrayE,
          mostrarInicio: mostrarI,
        };
        const paqueteSave = await PaqueteService.create(dataPaquete);
        console.log("--------------" + paqueteSave.response);
        navigate("/paquete");
        toast.success("Paquete creado con exito: " + dataPaquete.titulo);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      console.log(error);
      setLoading(true);
    }
  };

  const limpiarItinerario = () => {
    try {
      dia.titulo = "";
      dia.descripcion = "";
    } catch (error) {
    } finally {
    }
  };

  //#region Dias
  const saveDay = async () => {
    setDiaContador((prev) => prev + 1);
    console.log("id  del dia: " + diaContador);
    try {
      // const newDay = (
      //   <CardDay
      //     id={diaContador}
      //     titulo={dia.titulo}
      //     descripcion={dia.descripcion}
      //     imagen={file}
      //     url={URL.createObjectURL(file)}
      //     eliminarActual={(data) => {
      //       eliminarDay(data);
      //     }}
      //   />
      // );
      const newDay = {
        id: diaContador,
        titulo: dia.titulo,
        descripcion: dia.descripcion,
        imagen: file,
        url: URL.createObjectURL(file),
      };
      setDiasArray((diasArray) => [...diasArray, newDay]);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarDay = (id) => {
    console.log("Eliminar id:" + id);

    try {
      let arrayOriginal = diasArray;
      console.log("dias array eliminar, length: " + diasArray);
      console.log("****ELIMINAR: " + arrayOriginal.length);
      let nuevoArray = [];
      // arrayOriginal.map((record) => {
      //   if (record.props.id !== id) {
      //     console.log("****push ID: " + record.props.id);
      //     nuevoArray.push(record);
      //   }
      // });
      arrayOriginal.map((record) => {
        if (record.id !== id) {
          console.log("****push ID: " + record.id);
          nuevoArray.push(record);
        }
      });
      setDiasArray(nuevoArray);
      //arrayOriginalDiasJson=nuevoArray;
      //arrayDiasJSon = nuevoArray;
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region paqueteUsuario
  const savePaquetePrecioU = (userType) => {
    setPrecioUContador((prev) => prev + 1);
    try {
      const newPaquetePrecio = {
        cantidad: paquetePrecioU.cant,
        tipo: paquetePrecioU.tipo,
        precio: paquetePrecioU.precio,
        user: userType,
        id: precioUContador,
      };

      setpaquetePrecioArrayU((paquetesArray) => [
        ...paquetesArray,
        newPaquetePrecio,
      ]);
      setpaquetePrecioU({ ...paquetePrecioU, cant: "", precio: "" });
    } catch (error) {
      console.log(error);
    }
  };
  const eliminarPaqueteUsuario = (id) => {
    try {
      let arrayOriginal = paquetePrecioArrayU;
      let nuevoArray = [];
      arrayOriginal.map((record) => {
        if (record.id !== id) {
          nuevoArray.push(record);
        }
      });
      setpaquetePrecioArrayU(nuevoArray);
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region paqueteEmpresa
  const savePaquetePrecioE = (userType) => {
    setPrecioEContador(precioEContador + 1);
    try {
      const newPaquetePrecio = (
        <CardPaquetePrecio
          cantidad={paquetePrecioE.cant}
          tipo={paquetePrecioE.tipo}
          precio={paquetePrecioE.precio}
          user={userType}
          id={precioEContador}
          eliminarActual={(data) => {
            eliminarPaqueteEmpresa(data);
          }}
        />
      );
      setpaquetePrecioArrayE((paquetesArray) => [
        ...paquetesArray,
        newPaquetePrecio,
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarPaqueteEmpresa = (id) => {
    try {
      let arrayOriginal = paquetePrecioArrayE;
      let nuevoArray = [];
      arrayOriginal.map((record) => {
        if (record.props.id != id) {
          nuevoArray.push(record);
        }
      });
      setpaquetePrecioArrayE(nuevoArray);
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          <div
            style={
              matches
                ? {
                    boxShadow: "var(--box-shadow)",
                    backgroundColor: "var(--color-white)",
                  }
                : {
                    boxShadow: "var(--box-shadow)",
                    backgroundColor: "var(--color-white)",
                  }
            }
          >
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none", padding: "0" }}>
                <div
                  className={styles.recentorders}
                  style={{
                    width: "auto",
                    position: "inherit",
                    margin: "inherit",
                    transform: "unset",
                  }}
                >
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <th>
                            <h1 style={{ marginBottom: "3%" }}>
                              Crear paquete
                            </h1>
                          </th>
                        </tr>
                        <tr>
                          Mostrar al inicio
                          <Switch
                            checked={mostrarI}
                            onClick={() => {
                              console.log("el estado 1: " + mostrarI);
                              mostrarI ? setMostrarI(false) : setMostrarI(true);
                              console.log("el estado 2: " + mostrarI);
                            }}
                          />
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={styles.row}
              style={
                matches
                  ? {
                      display: "grid",
                      gridTemplateColumns: "50% 50%",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
                  : {
                      display: "grid",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
              }
            >
              <div style={{ width: "100%" }}>
                <h3>Titulo</h3>
                <input
                  id="titulo"
                  placeholder="Titulo"
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "#dce1eb",
                    padding: "3%",
                    marginLeft: "-1%",
                    width: "98%",
                  }}
                  value={paquete?.titulo}
                  onChange={(e) =>
                    setPaquete({
                      ...paquete,
                      titulo: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ width: "100%" }}>
                <h3>Subtitulo</h3>
                <input
                  id="subtitulo"
                  placeholder="Subtitulo"
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "#dce1eb",
                    padding: "3%",
                    marginLeft: "-1%",
                    width: "98%",
                  }}
                  value={paquete?.subtitulo}
                  onChange={(e) =>
                    setPaquete({
                      ...paquete,
                      subtitulo: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div
              className={styles.row}
              style={
                matches
                  ? {
                      display: "grid",
                      gridTemplateColumns: "50% 50%",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
                  : {
                      display: "grid",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
              }
            >
              <div style={{ width: "100%" }}>
                <h3>Fecha inicial</h3>
                <TextField
                  id="fechaInicial"
                  type="date"
                  InputLabelProps={{
                    style: { color: "#ff891e" },
                    shrink: true,
                  }}
                  style={{ width: "98%" }}
                  onChange={(e) =>
                    setPaquete({
                      ...paquete,
                      fechaInicial: e.target.value,
                    })
                  }
                  value={paquete?.fechaInicial}
                />
              </div>
              <div style={{ width: "100%" }}>
                <th>Fecha final</th>
                <TextField
                  id="fechaFinal"
                  type="date"
                  InputLabelProps={{
                    style: { color: "#ff891e" },
                    shrink: true,
                  }}
                  style={{ width: "98%" }}
                  onChange={(e) =>
                    setPaquete({
                      ...paquete,
                      fechaFinal: e.target.value,
                    })
                  }
                  value={paquete?.fechaFinal}
                />
              </div>
            </div>
            <div
              className={styles.row}
              style={
                matches
                  ? {
                      display: "grid",
                      gridTemplateColumns: "50% 50%",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
                  : {
                      display: "grid",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
              }
            >
              <div style={{ width: "100%" }}>
                <h3>Cupo ($)</h3>
                <input
                  id="cupo"
                  placeholder="Cupo"
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "#dce1eb",
                    padding: "3%",
                    marginLeft: "-1%",
                    width: "98%",
                  }}
                  value={paquete?.cupo}
                  onChange={(e) =>
                    setPaquete({
                      ...paquete,
                      cupo: e.target.value,
                    })
                  }
                  type="number"
                />
              </div>
              <div style={{ width: "100%" }}>
                <h3>Descuento (%)</h3>
                <input
                  id="descuento"
                  placeholder="Descuento"
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "#dce1eb",
                    padding: "3%",
                    marginLeft: "-1%",
                    width: "98%",
                  }}
                  value={paquete?.descuento}
                  onChange={(e) =>
                    setPaquete({
                      ...paquete,
                      descuento: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div
              className={styles.row}
              style={
                matches
                  ? {
                      display: "grid",
                      gridTemplateColumns: "50% 50%",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
                  : {
                      display: "grid",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
              }
            >
              <div style={{ width: "100%" }}></div>
              <div style={{ width: "100%" }}></div>
            </div>
            <h3 style={{ paddingInline: "1.8rem" }}>Descripción</h3>
            <div
              style={
                matches
                  ? { marginLeft: "6%", marginRight: "6%", minHeight: "7rem" }
                  : {
                      marginLeft: "6%",
                      marginRight: "6%",
                      padding: "2rem",
                      minHeight: "7rem",
                    }
              }
            >
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  id="descripcion"
                  label="Descripcion del paquete"
                  controls={[
                    "italic",
                    "underline",
                    "strikethrough",
                    "highlight",
                    "undo",
                    "redo",
                    "link",
                    "numberList",
                    "bulletList",
                    "quote",
                    "code",
                    "clear",
                  ]}
                  inlineToolbar={true}
                  onChange={(value) =>
                    setPaquete({
                      ...paquete,
                      descripcion: stateToHTML(value.getCurrentContent()),
                    })
                  }
                />
              </ThemeProvider>
              <br></br>
            </div>
            <br />
            <h3 style={{ paddingInline: "1.8rem" }}>Incluye</h3>
            <div
              style={
                matches
                  ? {
                      minHeight: "7rem",
                      marginLeft: "6%",
                      marginRight: "6%",
                    }
                  : {
                      marginLeft: "6%",
                      marginRight: "6%",
                      padding: "2rem",
                      minHeight: "7rem",
                    }
              }
            >
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  id="incluye"
                  label="Descripcion del paquete"
                  controls={[
                    "italic",
                    "underline",
                    "strikethrough",
                    "highlight",
                    "undo",
                    "redo",
                    "link",
                    "numberList",
                    "bulletList",
                    "quote",
                    "code",
                    "clear",
                  ]}
                  inlineToolbar={true}
                  onChange={(value) =>
                    setPaquete({
                      ...paquete,
                      incluye: stateToHTML(value.getCurrentContent()),
                    })
                  }
                />
              </ThemeProvider>
              <br></br>
            </div>

            <div style={{ width: "-webkit-fill-available", padding: "1.8rem" }}>
              <div
                style={{
                  width: "95%",
                  marginLeft: "1.5%",
                  overflow: "auto",
                }}
              >
                <DropComponent
                  label="Tamaño recomendado 900px * 600px hasta 2mb"
                  footer="Archivos aceptados png,jpeg,jpg"
                  subirImagen={(data) => setImagenesCarrusel(data)}
                />
              </div>
            </div>

            {/* ITINERARIO  */}
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none", paddingBottom: "0%" }}>
                <div
                  className={styles.recentorders}
                  style={{
                    width: "auto",
                    position: "inherit",
                    margin: "inherit",
                    transform: "unset",
                  }}
                >
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <h2>Itinerario</h2>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th>Titulo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              id="tituloItinerario"
                              placeholder="Titulo"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "1.5%",
                                marginLeft: "-1%",
                                width: "100%",
                              }}
                              value={paquete?.tituloItinerario}
                              onChange={(e) =>
                                setPaquete({
                                  ...paquete,
                                  tituloItinerario: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th>Titulo del día</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              id="tituloDia"
                              placeholder="Titulo del día"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "1.5%",
                                marginLeft: "-1%",
                                width: "100%",
                              }}
                              value={dia?.titulo}
                              onChange={(e) =>
                                setDia({
                                  ...dia,
                                  titulo: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Descripcion</th>
                        </tr>
                        <tr>
                          <td>
                            <textarea
                              id="descripcionDia"
                              placeholder="Descripcion del día"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "1.5%",
                                marginLeft: "-1%",
                                width: "100%",
                                minHeight: "10rem",
                              }}
                              value={dia?.descripcion}
                              onChange={(e) =>
                                setDia({
                                  ...dia,
                                  descripcion: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <br></br>

            <div style={{ width: "-webkit-fill-available" }}>
              <div
                style={{
                  width: "95%",
                  marginLeft: "1.5%",
                }}
              >
                <DropZone
                  label="Tamaño recomendado 900px * 600px"
                  saveFile={async (file) => {
                    setFile(file);
                    var reader = new FileReader();
                    var url = reader.readAsDataURL(file);
                  }}
                />
              </div>
            </div>
            {file ? (
              <>
                <div
                  style={
                    matches
                      ? { textAlign: "center" }
                      : { textAlign: "center", maxWidth: "23rem" }
                  }
                >
                  <img
                    src={URL.createObjectURL(file)}
                    style={{
                      height: 300,
                      objectFit: "contain",
                      width: "-webkit-fill-available",
                    }}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    style={{
                      width: "-webkit-fill-available",
                    }}
                  >
                    {file.name}
                  </Typography>
                </div>
              </>
            ) : null}
            <br />
            <div
              className={styles.recentorders}
              style={{
                width: "auto",
                position: "inherit",
                margin: "inherit",
                transform: "unset",
              }}
            >
              <div style={{ overflowX: "auto" }}>
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            limpiarItinerario();
                          }}
                          style={{ margin: "5px" }}
                        >
                          Limpiar
                        </Button>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => saveDay()}
                          style={{ margin: "5px" }}
                        >
                          Guardar
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/**Cards Dias json */}
            <div className={styles.fulldiv}>
              <div
                style={{
                  overflow: "auto",
                  display: "-webkit-box",
                  boxShadow: "none",
                  paddingBottom: "0%",
                }}
              >
                {diasArray.map((record, index) => {
                  return (
                    <>
                      <div
                        id="row__posterLarge"
                        style={{ padding: "10px", marginBottom: "10px" }}
                        key={index}
                      >
                        <CardDay
                          id={record.id}
                          titulo={record.titulo}
                          descripcion={record.descripcion}
                          imagen={record.file}
                          url={record.url}
                          eliminarActual={() => {
                            eliminarDay(record.id);
                          }}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {/* END itinerario  */}

            {/* PAQUETES USUARIO  */}
            <div className={styles.fulldiv}>
              <div
                style={{
                  boxShadow: "none",
                  paddingBottom: "0%",
                  marginRight: "0%",
                }}
              >
                <div
                  className={styles.recentorders}
                  style={{
                    width: "auto",
                    position: "inherit",
                    margin: "inherit",
                    transform: "unset",
                    display: "grid",
                  }}
                >
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <h2>Paquetes</h2>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th>Cantidad de personas</th>
                          <th>Tipo</th>
                          <th>Precio</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              id="cantidadPersonasU"
                              placeholder="Cantidad"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "3%",
                                marginLeft: "-1%",
                                width: "100%",
                              }}
                              value={paquetePrecioU.cant}
                              onChange={(e) =>
                                setpaquetePrecioU({
                                  ...paquetePrecioU,
                                  cant: e.target.value,
                                })
                              }
                              type="number"
                            />
                          </td>
                          <td>
                            <select
                              id="tipoPersonasU"
                              name="categoria"
                              onChange={(e) => {
                                setpaquetePrecioU({
                                  ...paquetePrecioU,
                                  tipo: e.target.value,
                                });
                              }}
                              value={paquetePrecioU.tipo}
                              style={{
                                color: "#7d8da1",
                                marginLeft: "2%",
                                borderWidth: "0px",
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "1.5%",
                                paddingTop: "5%",
                                paddingBottom: "5%",
                                marginLeft: "-1%",
                                width: "95%",
                                minWidth: "6rem",
                              }}
                            >
                              <option disabled="disabled" selected="selected">
                                Selecciona una
                              </option>
                              <option value="Adulto">Adulto</option>
                              <option value="Niño">Niño</option>
                              <option value="Extra">Extra</option>
                            </select>
                          </td>
                          <td>
                            <input
                              id="precioPersonasU"
                              placeholder="Precio"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "3%",
                                marginLeft: "-1%",
                                width: "100%",
                                minWidth: "6rem",
                              }}
                              value={paquetePrecioU.precio}
                              onChange={(e) =>
                                setpaquetePrecioU({
                                  ...paquetePrecioU,
                                  precio: e.target.value,
                                })
                              }
                              type="number"
                            />
                          </td>
                          <td>
                            {/* <Button
                              variant="outlined"
                              color="error"
                              onClick={() => console.log("Limpiando")}
                            >
                              Limpiar
                            </Button> */}
                          </td>
                          <td>
                            <Button
                              id="guardarUsuario"
                              variant="outlined"
                              color="success"
                              onClick={(userType) =>
                                savePaquetePrecioU("Usuario")
                              }
                            >
                              Guardar
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className={styles.fulldiv}>
              <div
                style={{
                  overflow: "auto",
                  display: "-webkit-box",
                  boxShadow: "none",
                  marginTop: "-5%",
                }}
              >
                {paquetePrecioArrayU.map((record, index) => {
                  return (
                    <>
                      <div
                        id="row__posterLarge"
                        style={{ padding: "10px", marginBottom: "10px" }}
                      >
                        <CardPaquetePrecio
                          cantidad={record.cantidad}
                          tipo={record.tipo}
                          precio={record.precio}
                          user={record.userType}
                          id={record.id}
                          eliminarActual={() => {
                            eliminarPaqueteUsuario(record.id);
                          }}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <br />
            {/* END paquetes usuario */}

            {/* PAQUETES EMPRESA 
            <div className={styles.fulldiv}>
              <div
                style={{
                  boxShadow: "none",
                  paddingBottom: "0%",
                  paddingTop: "0%",
                  marginTop: "-3%",
                }}
              >
                <div className={styles.recentorders}>
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <h3>Empresa</h3>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th>Cantidad de personas</th>
                          <th>Tipo</th>
                          <th>Precio</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              id="cantidadPersonasE"
                              placeholder="Cantidad"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "3%",
                                marginLeft: "-1%",
                                width: "100%",
                              }}
                              value={paquetePrecioE.cant}
                              onChange={(e) =>
                                setpaquetePrecioE({
                                  ...paquetePrecioE,
                                  cant: e.target.value,
                                })
                              }
                            />
                          </td>
                          <td>
                            <select
                              id="tipoPersonasE"
                              name="categoria"
                              onChange={(e) => {
                                setpaquetePrecioE({
                                  ...paquetePrecioE,
                                  tipo: e.target.value,
                                });
                              }}
                              value={paquetePrecioE.tipo}
                              style={{
                                color: "#7d8da1",
                                marginLeft: "2%",
                                borderWidth: "0px",
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "1.5%",
                                paddingTop: "5%",
                                paddingBottom: "5%",
                                marginLeft: "-1%",
                                width: "95%",
                              }}
                            >
                              <option disabled="disabled" selected="selected">
                                Selecciona una
                              </option>
                              <option value="Adulto">Adulto</option>
                              <option value="Niño">Niño</option>
                              <option value="Extra">Extra</option>
                            </select>
                          </td>
                          <td>
                            <input
                              id="precioPersonasE"
                              placeholder="Precio"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "3%",
                                marginLeft: "-1%",
                                width: "100%",
                              }}
                              value={paquetePrecioE.precio}
                              onChange={(e) =>
                                setpaquetePrecioE({
                                  ...paquetePrecioE,
                                  precio: e.target.value,
                                })
                              }
                            />
                          </td>
                          <td>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => console.log("Limpiando")}
                            >
                              Limpiar
                            </Button>
                          </td>
                          <td>
                            <Button
                              id="guardarEmpresa"
                              variant="outlined"
                              color="success"
                              onClick={(userType) =>
                                savePaquetePrecioE("Empresa")
                              }
                            >
                              Guardar
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.fulldiv}>
              <div
                style={{
                  overflow: "auto",
                  display: "-webkit-box",
                  boxShadow: "none",
                  marginTop: "-3%",
                }}
              >
                {paquetePrecioArrayE.map((record, index) => {
                  return (
                    <>
                      <div
                        id="row__posterLarge"
                        style={{ padding: "10px", marginBottom: "10px" }}
                      >
                        {record}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <br />*/}
            {/* END paquetes empresa */}
            {/* UBICACION */}
            <div>
              <div
                style={{
                  boxShadow: "none",
                  paddingBottom: "0%",
                  marginTop: "-5%",
                  marginRight: "0%",
                  marginLeft: "2%",
                }}
              >
                <div
                  className={styles.recentorders}
                  style={{
                    width: "auto",
                    position: "inherit",
                    margin: "inherit",
                    transform: "unset",
                  }}
                >
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <td>
                            <h2>Ubicación</h2>
                            <h3>Selecionar</h3>
                          </td>
                          <td>{""}</td>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td style={{ width: "40%" }}>
                            <Autocomplete
                              value={ubicacion?.ciudad}
                              id="country-select-demo"
                              options={ubicacion}
                              autoHighlight
                              getOptionLabel={(option) => option.ciudad}
                              onChange={(e, v) => {
                                addCiudad(v.id, v.ciudad);
                              }}
                              style={{
                                color: "#7d8da1",
                                borderWidth: "0px",
                                backgroundColor: "#dce1eb",
                                width: "100%",
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.ciudad}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  style={{
                                    color: "#7d8da1",
                                    backgroundColor: "yellow !important",
                                  }}
                                  {...params}
                                  label=""
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* END paquetes  */}
            <br />
            <div
              className={styles.recentorders}
              style={{
                width: "auto",
                position: "inherit",
                margin: "inherit",
                transform: "unset",
              }}
            >
              <div style={{ overflowX: "auto" }}>
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => navigate("/paquete")}
                          style={{ margin: "5px" }}
                        >
                          Regresar
                        </Button>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => guardarPaquete()}
                          style={{ margin: "5px" }}
                        >
                          GUARDAR PAQUETE
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <br />
        </>
      )}
    </>
  );
};

export default CrearPaquete;
