import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TablePagination } from "@mui/material";

/*Servicios*/
import S3Service from "../../../services/S3.service";
import NoticiaService from "../../../services/Noticia.service";

/*Componentes*/
import FullPageLoading from "../../../utils/FullPageLoading";
import ItemNoticia from "./ItemNoticia";
const Noticias = ({ ultimasNoticias }) => {
  const [noticias, setNoticias] = useState([]);
  const navegar = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getNoticias();
  }, []);

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(2, 0);
      setNoticias(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* ====================================
  ———	TRAVEL TIPS SECTION
  ===================================== */}
      <section className="py-10" style={{ backgroundColor: "white" }}>
        <div className="containerPublic">
          <div className="text-center section-title2 mb-7">
            <h2 className="text-uppercase font-weight-bold position-relative">
              <span className="bg-white">ULTMAS NOTICIAS</span>
            </h2>
            <p className="text-center">
              <p
                dangerouslySetInnerHTML={{
                  __html: ultimasNoticias,
                }}
              ></p>
            </p>
          </div>
          <div className="row">
            {noticias.map((record, index) => {
              return <ItemNoticia key={index} data={record} />;
            })}
          </div>
          <div className="text-center mt-5 mt-lg-7">
            <a
              href="javascript:void(0)"
              onClick={() => navegar(`/blog`)}
              className="btn btn-hover btn-outline-secondary text-uppercase"
            >
              Leer todo
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Noticias;
