/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
/* Components & dependencies */
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import useMediaQuery from "@mui/material/useMediaQuery";
import FullPageLoading from "../../FullPageLoading";
import styles from "../layout.module.css";
import { Button } from "@mui/material";
import DropComponent from "../imagesDrop";

/*Services */
import S3Service from "../../../services/S3.service";
import HabitacionService from "../../../services/Habitacion.service";
import Camas from "./Camas";
import Amenidades from "./Amenidades";

const EditarHabitacion = () => {
  const { id } = useParams();
  const matches = useMediaQuery("(min-width:800px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [habitacion, setHabitacion] = useState({});
  const [camas, setCamas] = useState([{ cama: "individual", ocupantes: 1 }]);
  const [amenidades, setAmenidades] = useState([]);
  const [file, setFile] = useState(undefined);
  const [imagenesCarrusel, setImagenesCarrusel] = useState([]);
  const [disponible, setDisponible] = useState(false);

  useEffect(() => {
    setHabitacion({
      ...habitacion,
      disponible: false,
    });
    getHabitacion();
  }, []);

  const handleAmenidades = (newAmenidades) => {
    setAmenidades(newAmenidades);
  };

  const handleDisponible = () => {
    setDisponible(!disponible);
  };

  const handleCamas = (newCamas) => {
    setCamas(newCamas);
  };

  const guardarHabitacion = async () => {
    try {
      if (!habitacion.tipo) {
        toast.info("Error, tienes que agregar un tipo");
      } else if (!habitacion.precio) {
        toast.info("Error, tienes que colocar una precio");
      } else if (!camas.lenght < 1) {
        toast.info(
          "Error, tienes que registrar información sobre la habitación"
        );
      } else if (!habitacion.cupo) {
        toast.info("Error, tienes que registrar el cupo de la habitación");
        // } else if (!file) {
        //   toast.info("Error, tienes que colocar una imagen para el hotel");
      } else {
        setLoading(true);
        let imagenesArray = []; //Array para los id del s3

        /* Subida de imagenes del carrusel */
        for (const file of imagenesCarrusel) {
          if (!file.urlImaen) {
            const resultFile = await S3Service.upload(file);
            imagenesArray.push(resultFile.result.data);
          } else {
            const idImagen = file.urlImaen.split("/")[3].split("?")[0];
            imagenesArray.push(idImagen);
          }
        }

        const { tipo, precio, cupo, habitaciones, id } = habitacion;

        const dataHabitacion = {
          tipo,
          precio,
          cupo,
          cama: camas,
          habitaciones,
          amenidades: amenidades,
          imagenes: imagenesArray,
          disponible: disponible,
          id: id,
        };
        const hotelSave = await HabitacionService.update(dataHabitacion);
        console.log("--------------" + hotelSave.response);
        navigate(`/hoteles/${id}/habitaciones`);
        console.log(dataHabitacion);
        toast.success("Habitación creada con exito " + dataHabitacion.tipo);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
      setLoading(true);
    }
  };

  const getHabitacion = async () => {
    setLoading(true);
    try {
      const data = await HabitacionService.getId(id);
      setHabitacion(data);
      console.log(data);

      if (data.imagenes) {
        for (const imagen of data.imagenes) {
          console.log(imagen);
          const result = await S3Service.get(imagen);
          setImagenesCarrusel((imagenesProducto) => [
            ...imagenesProducto,
            result.result,
          ]);
          console.log(result.result);
        }
      }

      setCamas(data.cama);
      setAmenidades(data.amenidades);
      setDisponible(data.disponible);
      setLoading(false);
    } catch (error) {}
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          <div
            style={{
              boxShadow: "var(--box-shadow)",
              backgroundColor: "var(--color-white)",
            }}
          >
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none" }}>
                <div style={{ overflowX: "auto" }}>
                  <table style={{ boxShadow: "initial", textAlign: "justify" }}>
                    <thead>
                      <tr>
                        <th>
                          <h1 style={{ marginBottom: "3%" }}>
                            Editar Habitación
                          </h1>
                        </th>
                      </tr>
                      <tr>
                        Disponible
                        <Switch
                          checked={disponible}
                          onClick={handleDisponible}
                        />
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "-webkit-fill-available",
                minHeight: "69vh",
                padding: "1.8rem",
              }}
            >
              <div
                style={
                  matches
                    ? {
                        display: "grid",
                        gridTemplateColumns: "50% 50%",
                        height: "46rem",
                      }
                    : { display: "flex", flexDirection: "column" }
                }
              >
                <div style={{ overflow: "auto" }}>
                  <div style={{ padding: "0.5rem" }}>
                    <TextField
                      id="filled-basic"
                      label="Tipo"
                      variant="filled"
                      fullWidth
                      value={habitacion?.tipo}
                      onChange={(event) =>
                        setHabitacion({
                          ...habitacion,
                          tipo: event.target.value,
                        })
                      }
                    />
                  </div>
                  <Camas camas={camas} handleCamas={handleCamas} />
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "33% 33% 33%",
                    }}
                  >
                    <div
                      style={{
                        padding: "0.5rem",
                      }}
                    >
                      <TextField
                        id="filled-basic"
                        label="Precio"
                        variant="filled"
                        value={habitacion?.precio}
                        onChange={(event) =>
                          setHabitacion({
                            ...habitacion,
                            precio: event.target.value,
                          })
                        }
                      />
                      <div>MXN/c.Noche</div>
                    </div>
                    <div style={{ padding: "0.5rem" }}>
                      <TextField
                        id="filled-basic"
                        label="Habitaciones"
                        variant="filled"
                        value={habitacion?.habitaciones}
                        onChange={(event) =>
                          setHabitacion({
                            ...habitacion,
                            habitaciones: event.target.value,
                          })
                        }
                      />
                      <div>Habitaciones</div>
                    </div>
                    <div style={{ padding: "0.5rem" }}>
                      <TextField
                        id="filled-basic"
                        label="Cupo"
                        variant="filled"
                        value={habitacion?.cupo}
                        onChange={(event) =>
                          setHabitacion({
                            ...habitacion,
                            cupo: event.target.value,
                          })
                        }
                      />
                      <div>Personas/Habitacion</div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    padding: "0.5rem",
                    overflow: "auto",
                  }}
                >
                  <div
                    style={{
                      width: "-webkit-fill-available",
                    }}
                  >
                    <div
                      style={{
                        width: "95%",
                        marginLeft: "1.5%",
                        overflow: "auto",
                      }}
                    >
                      <DropComponent
                        label="Tamaño recomendado 900px * 600px"
                        footer="Archivos aceptados png,jpeg,jpg"
                        subirImagen={(data) => setImagenesCarrusel(data)}
                        imagenesDefault={imagenesCarrusel}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <p
                    style={{
                      width: "100%",
                      borderBottom: "1px solid #8b8b8b",
                      textAlign: "center",
                      fontSize: "1.3rem",
                    }}
                  >
                    Información
                  </p>
                  <Amenidades
                    amenidades={amenidades}
                    handleAmenidades={handleAmenidades}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() =>
                    navigate(`/hoteles/${habitacion?.hotel?.id}/habitaciones`)
                  }
                  style={{ margin: "5px" }}
                >
                  Regresar
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => guardarHabitacion()}
                  style={{ margin: "5px" }}
                >
                  GUARDAR HABITACIÓN
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditarHabitacion;
