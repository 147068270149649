import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import MyGreatPlaceWithControllableHover from "./MyGreatPlaceWithControllableHover ";
import { K_SIZE } from "./my_great_place_with_controllable_hover_styles.js";

export default function MapGoogle({ positionApproximate, text }) {
  const google_apiKey = process.env.REACT_APP_GOOGLE_KEY;
  const [ubicacion, setUbicacion] = useState(
    positionApproximate && positionApproximate[0] !== 0
      ? positionApproximate
      : [17.052747, -96.72233]
  );
  const [zoom, setZoom] = useState(11);
  const [hoverItem, setHoverItem] = useState(null);
  const [greatPlaces, setGreatPlaces] = useState(
    positionApproximate && positionApproximate[0] !== 0
      ? [
          {
            id: "ubicacion",
            lat: positionApproximate[0],
            lng: positionApproximate[1],
            text: `🚩 ${text}`,
          },
          // {
          //   id: "ubicacion",
          //   geometry: {
          //     location: {
          //       lat: positionApproximate[0],
          //       lng: positionApproximate[1],
          //     },
          //   },
          //   name: "🚩",
          // },
        ]
      : [
          // { id: "A", lat: 17.052747, lng: -96.72233, text: "A" },
          // { id: "B", lat: 17.0532571, lng: -96.7214101, text: "B" },
        ]
  );
  const defaultProps = {
    defaultCenter: {
      lat: 17.052747,
      lng: -96.72233,
    },
    zoom: 11,
  };

  const places = greatPlaces.map((place) => {
    return (
      <MyGreatPlaceWithControllableHover
        key={place.id}
        lat={place.lat}
        lng={place.lng}
        text={place.text}
        // use your hover state (from store, react-controllables etc...)
        hover={hoverItem === place.id ? true : false}
      />
    );
  });

  const _onChildClick = (key, childProps) => {
    // this.props.onCenterChange([childProps.lat, childProps.lng]);
    setUbicacion([childProps.lat, childProps.lng]);
    setZoom(15);
  };

  const _onChildMouseEnter = (key /*, childProps */) => {
    // this.props.onHoverKeyChange(key);
    // console.log(key);
    setHoverItem(key);
  };

  const _onChildMouseLeave = (/* key, childProps */) => {
    // this.props.onHoverKeyChange(null);
    setHoverItem(null);
  };

  const _onChange = ({ center, zoom, bounds, marginBounds }) => {
    // this.props.onCenterChange(center);
    // this.props.onZoomChange(zoom);
    setUbicacion([center.lat, center.lng]);
    setZoom(zoom);
    // console.log(center);
    // console.log(zoom);
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: google_apiKey }}
        defaultCenter={defaultProps.defaultCenter}
        defaultZoom={defaultProps.zoom}
        center={ubicacion}
        zoom={zoom}
        hoverDistance={K_SIZE / 2}
        onChange={_onChange}
        onChildClick={_onChildClick}
        onChildMouseEnter={_onChildMouseEnter}
        onChildMouseLeave={_onChildMouseLeave}
      >
        {places}
      </GoogleMapReact>
    </div>
  );
}
