import { useEffect, useState, useCallback } from "react";
import { ReservationGrid } from "@jylopez/reservation-grid";
import { useNavigate, useParams } from "react-router-dom";
/* Components & dependencies */
import { Button, TablePagination, TextField } from "@mui/material";
import FullPageLoading from "../../FullPageLoading";
import { toast } from "react-toastify";
import styles from "../layout.module.css";
import HabitacionService from "../../../services/Habitacion.service";
import AgendaService from "../../../services/Agenda.service";
import ReservacionModal from "./ReservacionModal";

const Reservaciones = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [reservaciones, setReservaciones] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [statusReservacionModal, setStatusReservacionModal] = useState(false);
  const [update, setUpdate] = useState(false);
  /* */
  const statusFormat = {
    EnProceso: "confirmed",
    Cancelado: "awaiting",
    Confirmado: "paid",
  };
  let date = new Date();
  let primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
  let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [fechaData, setFechaData] = useState(null);
  const [verLimiteInicio, setVerLimiteInicio] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(primerDia)
  );
  const [verLimiteFinal, setVerLimiteFinal] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(ultimoDia)
  );
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);

  /************************ */
  const onUpdate = () => {
    setUpdate(!update);
  };
  const handleStatusReservacionModal = (newStatus) => {
    setStatusReservacionModal(newStatus);
  };
  const onClickTitle = useCallback((value) => {
    if (!value) {
      return;
    }
    console.log(`Selected row with # ${value}`);
    setSelectedRows([value]);
  }, []);

  const onClickCell = useCallback(({ value, date, dayType }) => {
    if (!value) {
      return;
    }
    console.log("Selected cell:", { value, date, dayType });
    setFechaData({ value, date, dayType });
    setSelectedRows([value]);
    setSelectedColumns([date]);
    handleStatusReservacionModal(true);
  }, []);

  const theme = {
    title: "ID",
    info: "Habitación",
    highlightToday: true,
    showInfo: true,
    theme: {
      "font.face": "sans-serif",
      "font.size": "14px",
      "color.text": "#30424F",
      "color.background": "#FFFFFF",
      "color.border": "#DDEBF3",
      "color.free": "transparent",
      "color.awaiting": "#DDEBF3",
      "color.confirmed": "#e9036e",
      "color.inaccessible": "#759AB5",
      "color.today": "#E4FFE6",
      "color.selected": "#FFF2F2",
      "color.weekend": "#F8FAFB",
      "width.title": "50%",
      "width.info": "50%",
    },
    locale: "en",
  };

  /************************* */
  useEffect(() => {
    getPagos();
  }, [page, limit, update]);

  const getPagos = async () => {
    setLoading(true);
    try {
      const data = await AgendaService.listAgenda({
        hotelId: id,
        fechaLimitInferior: verLimiteInicio,
        fechaLimitSuperior: verLimiteFinal,
      });
      setReservaciones(data.data);
      console.log(data);

      let newData = [];
      for (const h of data.data) {
        let range = {
          value: `${h.id}`,
          info: h.tipo,
        };
        let periods = [];
        for (const r of h.agendas) {
          periods.push({
            start: r.fechaInicio.split("T")[0],
            end: r.fechaFinal.split("T")[0],
            status: statusFormat["EnProceso"],
            displayText: `${r.habitaciones}`,
            onHoverToolTip: `${r.habitaciones - r.reservas} disponibles`,
          });
        }

        range.periods = periods;
        newData.push(range);
      }
      newData.push({ value: "", periods: [] });
      setData(newData);
      setTotal(data.total);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const corte = async () => {
    setLoading(true);
    try {
      // const data = await PagoService.getAll();
      // setPagosTodos(data);
      // console.log("-------------" + data);
      // data.forEach((pago) => {
      //   const newPago = {
      //     nombre: pago.usuario.nombre,
      //     correo: pago.usuario.email,
      //     direccion: pago.usuario.direccion,
      //     precio: pago.precio,
      //     status: pago.status,
      //   };
      //   setPagosArray((pagosArray) => [...pagosArray, newPago]);
      // });
      // console.log("----0" + pagosArray);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <div
          className={styles.recentorders}
          style={{ display: "grid", backgroundColor: "white" }}
        >
          <div style={{ overflowX: "auto" }}>
            <div>
              <table>
                <thead>
                  <tr>
                    <th style={{ textAlign: "left", width: "35%" }}>
                      <h1 style={{ marginRight: "20px", marginBottom: "20px" }}>
                        Habitaciones
                      </h1>
                    </th>

                    <th style={{ textAlign: "right" }}></th>
                    <th style={{ textAlign: "right" }}></th>

                    <th style={{ textAlign: "center", width: "25%" }}>
                      Habitaciones disponibles
                    </th>
                    <th style={{ textAlign: "center", width: "25%" }}>
                      Habitaciones habilitadas
                    </th>
                    <th style={{ textAlign: "center", width: "25%" }}>
                      Fecha Habilitada
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th></th>
                    <th>
                      {" "}
                      <TextField
                        id="date"
                        label="Fecha de inicio"
                        type="date"
                        value={verLimiteInicio}
                        InputLabelProps={{
                          style: { color: "#e9036e" },
                          shrink: true,
                        }}
                        style={{
                          width: "100%",
                          color: "#6c757d !important",
                          paddingInline: "1rem",
                        }}
                        sx={{
                          color: "#6c757d !important",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#e9036e !important",
                          },
                        }}
                        onChange={(e) => {
                          setVerLimiteInicio(e.target.value);
                        }}
                      />
                    </th>
                    <th>
                      {" "}
                      <TextField
                        id="date"
                        label="Fecha de limite"
                        type="date"
                        value={verLimiteFinal}
                        InputLabelProps={{
                          style: { color: "#e9036e" },
                          shrink: true,
                        }}
                        style={{
                          width: "100%",
                          color: "#6c757d !important",
                          paddingInline: "1rem",
                        }}
                        sx={{
                          color: "#6c757d !important",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#e9036e !important",
                          },
                        }}
                        onChange={(e) => {
                          setVerLimiteFinal(e.target.value);
                        }}
                      />
                    </th>
                    <th style={{ textAlign: "-webkit-center", width: "25%" }}>
                      <div
                        style={{
                          height: "2rem",
                          width: "2rem",
                          backgroundColor: "#666666",
                        }}
                      ></div>
                    </th>

                    <th style={{ textAlign: "-webkit-center", width: "25%" }}>
                      <div
                        style={{
                          height: "2rem",
                          width: "2rem",
                          border: "1px solid #ddebf3",
                          lineHeight: "2rem",
                        }}
                      >
                        #
                      </div>
                    </th>
                    <th style={{ textAlign: "-webkit-center", width: "25%" }}>
                      <div
                        style={{
                          height: "2rem",
                          width: "2rem",
                          backgroundColor: "#e9036e",
                        }}
                      ></div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="layoutContainer reservationWrapper"
            style={{
              backgroundColor: "white",
              overflow: "auto",
              display: "grid",
              justifyItems: "start",
              margin: "1.8rem",
            }}
          >
            {" "}
            <ReservationGrid
              highlightToday
              showInfo={false}
              showColumn3={false}
              start={verLimiteInicio}
              end={verLimiteFinal}
              selectedColumns={selectedColumns}
              selectedRows={selectedRows}
              // title="Habitaciones"
              // info="Tipo"
              locale="en"
              data={data}
              onClickTitle={onClickTitle}
              onClickCell={onClickCell}
              {...theme}
            />
          </div>
          {/* <TablePagination
            style={{ color: "var(--color-dark-variant)" }}
            component="div"
            classes="recent-orders"
            labelRowsPerPage="Items por pagina"
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          <ReservacionModal
            status={statusReservacionModal}
            handleStatus={handleStatusReservacionModal}
            fechaData={fechaData}
            onSumit={onUpdate}
          />
        </div>
      )}
    </>
  );
};

export default Reservaciones;
