import React, { useEffect, useState } from "react";
import Slide from "./Slide";
import Search from "./Search";
import MejoresOfertas from "./MejoresOfertas";
import Noticias from "./Noticias";
import Counter from "./Counter";
import NuestrosPaquetes from "./NuestrosPaquetes";

import IndexService from "../../../services/Index.service";
import NuestrosHoteles from "./NuestrosHoteles";

const Index = () => {
  const [informacion, setInformacion] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    getInformacion();
  }, []);

  const getInformacion = async () => {
    let data = await IndexService.get();
    setInformacion(data[0]);
  };

  return (
    <>
      <div className="main-wrapper home-main">
        <Slide />
        <Search />
        <MejoresOfertas mejoresOfertas={informacion?.mejoresOfertas} />
        <NuestrosHoteles nuestrosHoteles={informacion?.nuestrosHoteles} />
        <NuestrosPaquetes nuestrosPaquetes={informacion?.nuestrosPaquetes} />
        <Counter clientesFelices={informacion?.clientesFelices} />
        <Noticias ultimasNoticias={informacion?.ultimasNoticias} />
      </div>
    </>
  );
};

export default Index;
