/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor from "mui-rte";
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";

/* Servicios */
import PagoService from "../../../services/Pago.service";
import PaqueteService from "../../../services/Paquete.service";

import styles from "../layout.module.css";

const EliminarPago = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pago, setPago] = useState({});
  const [paquete, setPaquete] = useState({});
  const [loading, setLoading] = useState(true);
  const [imagen, setImagen] = useState("");
  useEffect(() => {
    getPago();
  }, []);

  const getPago = async () => {
    setLoading(true);
    try {
      const data = await PagoService.getById(parseInt(id));
      setPago(data);
      console.log(data);
      const infoPaquete = await PaqueteService.getById(data.paqueteId);
      setPaquete(infoPaquete);
      console.log("paquete"+infoPaquete.titulo);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deletePago = async () => {
    setLoading(true);
    try {
      console.log("ELIMINAR");
      /*if (noticia.imagen) {
        const imagen = await S3Service.delete(noticia.imagen);
        console.log("---------se borro" + imagen.result);
      }*/
      const pagoBorrado = await PagoService.Corte(id);

      toast.info("Pago eliminada");
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setLoading(false);
      navigate("/pagos");
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          style={{
            boxShadow: "var(--box-shadow)",
            backgroundColor: "var(--color-white)",
          }}
        >
          <div className={styles.fulldiv}>
            <div style={{ boxShadow: "none" }}>
              <h1>ELIMINAR RESERVA</h1>
              <div className={styles.recentorders}>
                <div style={{ overflowX: "auto" }}>
                  <table
                    id="recent-orders--table"
                    style={{ boxShadow: "initial", textAlign: "justify" }}
                  >
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Paquete</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{pago.id}</td>
                        <td>{paquete.titulo}</td>
                      </tr>
                      <tr>
                        <th>Información</th>
                        <th>Detalles de la reserva</th>
                      </tr>
                      <tr>
                        <td>Nombre: {pago.usuario.nombre} {pago.usuario.apellido} Correo{pago.usuario.email}</td>
                        <td>Adultos: {pago.paquete.adulto} Niños: {pago.paquete.nino} Extras: {pago.paquete.extra}</td>
                      </tr>
                      <tr>
                        <th>Pago</th>
                        <th>Estatus</th>
                      </tr>
                      <tr>
                        <td>{pago.precio}</td>
                        <td>{pago.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className={styles.recentorders}>
            <div style={{ overflowX: "auto" }}>
              <table
                id="recent-orders--table"
                style={{ boxShadow: "initial", textAlign: "justify" }}
              >
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => navigate("/pagos")}
                      style={{ margin: "5px" }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => deletePago()}
                      style={{ margin: "5px" }}
                    >
                      ELIMINAR
                    </Button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EliminarPago;
