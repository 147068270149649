import { TextField } from "@mui/material";
import { useState } from "react";

const Busqueda = ({
  total,
  filtroFechaInicio,
  handleFiltroFechaInicio,
  filtroFechaFin,
  handleFiltroFechaFin,
  huespedSearch,
  search,
  addHuesped,
  subHuesped,
}) => {
  return (
    <>
      <div class="col-md-12 col-lg-4 order-2 pt-10 pt-lg-0">
        <form
          class=""
          action="index.html"
          method="GET"
          style={{ position: "sticky", top: "11rem" }}
        >
          <div class="card border">
            <h2 class="card-header text-uppercase text-center bg-smoke border-bottom">
              Revisar disponibilidad
            </h2>

            <div class="card-body px-3 py-4">
              <div class="border-bottom mb-5">
                <div class="form-group mb-5">
                  <div class="row">
                    <label
                      for="inputTime"
                      class="col-xl-5 col-form-label text-center text-xl-right px-2"
                    >
                      Llegada:
                    </label>

                    <div class="col-xl-7">
                      <div class="form-group form-group-icon form-group-icon-category-2 mb-0">
                        <TextField
                          id="date"
                          label="Fecha de inicio"
                          type="date"
                          value={filtroFechaInicio}
                          InputLabelProps={{
                            style: { color: "#e9036e" },
                            shrink: true,
                          }}
                          style={{ width: "100%", color: "#6c757d !important" }}
                          sx={{
                            color: "#6c757d !important",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#e9036e !important",
                            },
                          }}
                          onChange={(e) => {
                            handleFiltroFechaInicio(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-5">
                  <div class="row">
                    <label
                      for="inputTime"
                      class="col-xl-5 col-form-label text-center text-xl-right px-2"
                    >
                      Salida:
                    </label>

                    <div class="col-xl-7">
                      <div class="form-group form-group-icon form-group-icon-category-2 mb-0">
                        <TextField
                          id="date"
                          label="Fecha de inicio"
                          value={filtroFechaFin}
                          type="date"
                          InputLabelProps={{
                            style: { color: "#e9036e" },
                            shrink: true,
                          }}
                          style={{ width: "100%", color: "#6c757d !important" }}
                          sx={{
                            color: "#6c757d !important",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#e9036e !important",
                            },
                          }}
                          onChange={(e) => {
                            handleFiltroFechaFin(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-5">
                  <div class="row align-items-center">
                    <label class="control-label col-xl-5 text-center text-xl-right">
                      Huésped:
                    </label>

                    <div class="col-xl-5">
                      <div class="count-input mx-auto">
                        <a
                          class="incr-btn"
                          data-action="decrease"
                          href="javascript:void(0)"
                          onClick={() => subHuesped()}
                        >
                          –
                        </a>
                        <input
                          class="quantity"
                          type="number"
                          readOnly
                          value={huespedSearch}
                        />
                        <a
                          class="incr-btn"
                          data-action="increase"
                          href="javascript:void(0)"
                          onClick={() => addHuesped()}
                        >
                          +
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-center px-4">
                <button
                  type="button"
                  onClick={() => {
                    console.log("search asd");
                    search();
                  }}
                  class="btn btn-hover btn-lg btn-block btn-outline-secondary text-uppercase"
                >
                  Consultar{" "}
                  <span class="ml-4">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Busqueda;
