import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

import HotelesService from "../../../services/Hoteles.service";
import ItemHotel from "./ItemHotel";

const NuestrosHoteles = ({ nuestrosHoteles }) => {
  const [paquetes, setPaquetes] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [tipo, setTipo] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getPaquetes();
    window.scrollTo(0, 0);
  }, []);

  const getPaquetes = async () => {
    setLoading(true);
    try {
      let ofertasList = [];
      const hoteles = await HotelesService.mejoresOfertas(3);
      console.log(hoteles);
      ofertasList = [...hoteles.data];
      console.log(ofertasList);
      setPaquetes(ofertasList);
      if (currentUser != undefined && currentUser.tipo == "Empresa") {
        setTipo("Empresa");
      } else {
        setTipo("Usuario");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section class="py-9 py-md-10 bg-smoke">
      <div class="containerPublic">
        <div class="text-center section-title2">
          <h2 class="text-uppercase font-weight-bold position-relative">
            <span>Nuestros Hoteles</span>
          </h2>
          <p
            class="text-center"
            dangerouslySetInnerHTML={{
              __html: nuestrosHoteles,
            }}
          ></p>
        </div>

        <div class="row justify-content-center" style={{ padding: "1rem" }}>
          {paquetes.map((record, index) => {
            return (
              <ItemHotel
                key={index}
                id={record.id}
                titulo={record.nombre}
                precioU={record.paquetesUsuario}
                precioE={record.paquetesEmpresa}
                descuento={record.descuento}
                imagenes={record.imagenes}
                descripcion={record.descripcion}
                dias={record.diasJson}
                tipo={tipo}
                ciudad={record.ciudad}
                direccion={record.direccion}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default NuestrosHoteles;
