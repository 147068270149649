import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

const ItemBlog = ({
  id,
  titulo,
  idAutor,
  fecha,
  categoria,
  imagenes,
  cuerpo,
}) => {
  const navigate = useNavigate();
  const [imag, setImag] = useState([]);
  const [autor, setAutor] = useState([]);
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  let autorNombre;

  useEffect(() => {
    getImagen();
    getAutor();
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imag);
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  const getAutor = async () => {
    try {
      const result = await UserService.getById(idAutor);
      setAutor(result.nombre);
      autorNombre = result.nombre;
      console.log("Nombre autor: " + autorNombre);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="card rounded-0 card-transparent border-bottom mb-7 pb-7">
      <div className="row align-items-xl-center">
        <div
          className="col-md-6"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <a
            href="javascript:void(0)"
            onClick={() => navigate(`/blog/${id}`)}
            className="position-relative"
          >
            <img
              className="card-img-top rounded lazyestload"
              data-src="assets/img/blog/blog-01.jpg"
              src={imag}
              alt="Imagen de la noticia"
              height="310px"
              width="330px"
            />
            <div className="card-img-overlay card-hover-overlay rounded" />
          </a>
        </div>
        <div className="col-md-6">
          <div
            className="card-body px-md-0 py-6 pt-md-0"
            style={{ marginLeft: "10%" }}
          >
            <h3>
              <a
                href="javascript:void(0)"
                className="text-capitalize text-dark hover-text-primary"
                style={{
                  textAlign: "justify",
                  width: "112%",
                  marginLeft: "-6%",
                }}
                onClick={() => navigate(`/blog/${id}`)}
              >
                {titulo}
              </a>
            </h3>
            <div className="meta-post-sm mb-4">
              <ul
                className="list-unstyled d-flex flex-wrap mb-0"
                style={{
                  textAlign: "justify",
                  width: "112%",
                  marginLeft: "-6%",
                }}
              >
                <li className="meta-tag mr-4 mb-1">
                  {/* <i
                    className="fa fa-user text-gray-color"
                    aria-hidden="true"
                  />
                  <a
                    className="text-gray-color hover-text-primary"
                    href="javascript:void(0)"
                  >
                    <span className="ml-1 text-capitalize">{autor}</span>
                  </a> */}
                </li>
                <li className="meta-tag text-gray-color mr-4 mb-1">
                  <i className="fa fa-calendar" aria-hidden="true" />
                  <span className="ml-1 text-capitalize">
                    {fecha.slice(0, 10)}
                  </span>
                </li>
                <li className="meta-tag text-gray-color mr-4 mb-1">
                  <i className="fa fa fa-tags" aria-hidden="true" />
                  <span className="ml-1 text-capitalize">{categoria}</span>
                </li>
              </ul>
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: cuerpo.slice(0, 280),
              }}
              style={{ textAlign: "justify", minHeight: "146px" }}
            ></p>
          </div>

          <div
            className="card-footer px-5"
            style={{ textAlign: "justify", width: "112%", marginLeft: "-6%" }}
          >
            <a
              href="javascript:void(0)"
              onClick={() => navigate(`/blog/${id}`)}
              className="btn btn-sm btn-outline-secondary text-uppercase"
            >
              Leer mas
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemBlog;
