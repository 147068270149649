/* React */
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Components & dependencies */
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";
import Carousel from "react-bootstrap/Carousel";
import TextField from "@mui/material/TextField";

/* services */
import S3Service from "../../../services/S3.service";
import ReservaService from "../../../services/Reserva.service";
import HotelService from "../../../services/Hoteles.service";

const BookingHotelStep3 = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [orden, setOrden] = useState({});
  let paqueteArray = [];
  const [nino, setNino] = useState(0);
  let ninos = 0;
  const [extra, setExtra] = useState(0);
  let extras = 0;
  let check = 0;
  const [fecha, setFecha] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [imagenes, setImagenes] = useState([]);
  const [paquete, setPaquete] = useState();
  const [pago, setPago] = useState();
  const [checked, setChecked] = useState(false);
  const [total, setTotal] = useState(0);
  const { currentUser } = useContext(AuthContext);
  let tipo = "";
  let titulo = "";
  const [bannerActivacion, setBannerActivacion] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    getInformacion();
  }, []);

  const getInformacion = async () => {
    console.log("GET INFORMACION" + id);
    tipo = currentUser.tipo;
    const pagoResult = await ReservaService.getById(parseInt(id));
    setPago(pagoResult);
    const result = await HotelService.getByHabitacion(
      parseInt(pagoResult.habitacion.id)
    );
    setPaquete(result[0][0]);
    titulo = result.titulo;
    const imagen = await S3Service.get(result[0][0].imagenes[0]);
    setImagenes(imagen.result);
  };

  const banner = () => {
    if (bannerActivacion) {
      return (
        <div
          className="alert alert-success alert-dismissible alert-confirm fade show mb-7"
          role="alert"
        >
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => {
              setBannerActivacion(false);
            }}
          >
            <span aria-hidden="true">Cerrar</span>
          </button>
          <i className="fa fa-check" aria-hidden="true" />
          <span>
            <strong>Listo!</strong> Tu Habitacion a sido reservada
          </span>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const formatDate = (newDate) => {
    try {
      let fecha = new Date(newDate);
      return fecha.toLocaleDateString();
    } catch (error) {
      return "";
    }
  };

  return (
    <div className="main-wrapper booking-step-1">
      {/* ====================================
———	PAGE TITLE
===================================== */}
      <section className="page-title">
        <div
          className="page-title-img bg-img bg-overlay-darken"
          style={{
            backgroundImage: "url(assets/img/pages/page-title-bg7.jpg)",
          }}
        >
          <div className="containerPublic">
            <div
              className="row align-items-center justify-content-center"
              style={{ height: 200 }}
            >
              <div className="col-lg-6">
                <div className="page-title-content">
                  <div className="title-border">
                    <h2 className="text-uppercase text-white font-weight-bold">
                      Reservar - paso 3
                    </h2>
                  </div>
                  <p className="text-white mb-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ====================================
———	BOOKING SECTION
===================================== */}
      <section className="py-8 py-md-10">
        <div className="containerPublic">
          <div className="mb-8">
            <div className="row progress-wizard">
              <div className="col-4 progress-wizard-step complete">
                <div className="progress">
                  <div className="progress-bar" />
                </div>
                <a className="progress-wizard-dot">
                  <div className="progress-wizard-content">
                    <i className="fa fa-user" aria-hidden="true" />
                    <span className="d-block">1. Informacion personal</span>
                  </div>
                </a>
              </div>
              <div className="col-4 progress-wizard-step complete">
                <div className="progress">
                  <div className="progress-bar" />
                </div>
                <a className="progress-wizard-dot">
                  <div className="progress-wizard-content">
                    <i className="fa fa-usd" aria-hidden="true" />
                    <span className="d-block">2. Metodo de pago</span>
                  </div>
                </a>
              </div>
              <div className="col-4 progress-wizard-step active">
                <div className="progress">
                  <div className="progress-bar" />
                </div>
                <a className="progress-wizard-dot">
                  <div className="progress-wizard-content">
                    <i className="fa fa-check" aria-hidden="true" />
                    <span className="d-block">3. Confirmacion</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          {banner()}
          <h2 class="text-capitalize mb-5">Detalles de tu reserva</h2>

          <div className="card mb-6 mb-md-0">
            <img
              className="card-img-top rounded lazyestload"
              data-src="assets/img/booking/booking-complete.jpg"
              src={imagenes}
              alt="Card image cap"
            />
            <div className="card-body px-6">
              <h3 className="text-capitalize mb-5">{paquete?.nombre}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: paquete?.descripcion,
                }}
                style={{ textAlign: "justify" }}
              ></p>
              <p>
                La clave de tu reserva es un identificador único que te liga a
                ella como propietario.
                <dt>
                  <span>Clave de tu reserva: </span>
                </dt>
                {"  "}
                <span className="text-gray-color">{pago?.paypal_id}</span>
              </p>
            </div>
            <div className="card-footer bg-transparent">
              <div className="row">
                <div className="col-md-4">
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <div className="row">
                        <div className="col-5 col-md-6 col-lg-5 col-xl-4">
                          <dt>
                            <span className="mr-1">
                              <i
                                className="fa fa-calendar"
                                aria-hidden="true"
                              />
                            </span>
                            <span>Empieza:</span>
                          </dt>
                        </div>
                        <div className="col-7 col-md-6 pl-0">
                          <span className="text-gray-color">
                            {formatDate(pago?.fechaInicio)}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="mb-2">
                      <div className="row">
                        <div className="col-5 col-md-6 col-lg-5 col-xl-4">
                          <dt>
                            <span className="mr-1">
                              <i
                                className="fa fa-calendar"
                                aria-hidden="true"
                              />
                            </span>
                            <span>Termina:</span>
                          </dt>
                        </div>
                        <div className="col-7 col-md-6 pl-0">
                          <span className="text-gray-color">
                            {formatDate(pago?.fechaFinal)}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-5 col-md-6 col-lg-5 col-xl-4">
                          <dt>
                            <span className="mr-1">
                              <i className="fa fa-user" aria-hidden="true" />
                            </span>
                            <span>Huesped:</span>
                          </dt>
                        </div>
                        <div className="col-7 col-md-6 pl-0">
                          <span className="text-gray-color">
                            {pago?.habitacion?.cupo}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <div className="row">
                        <div className="col-5 col-md-6 col-lg-5 col-xl-4">
                          <dt>
                            <span className="mr-1">
                              <i
                                className="fa fa-map-marker"
                                aria-hidden="true"
                              />
                            </span>
                            <span>Ubicacion:</span>
                          </dt>
                        </div>
                        <div className="col-7 col-md-6 col-lg-7 pl-0 pr-md-0 pr-xl-5">
                          <span className="text-gray-color">
                            {paquete?.direccion}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="mb-2">
                      <div className="row">
                        <div className="col-5 col-md-6 col-lg-5 col-xl-4">
                          <dt>
                            <span className="mr-1"></span>
                            <span>Habitacion::</span>
                          </dt>
                        </div>
                        <div className="col-7 col-md-6 col-lg-7 pl-0 pr-md-0 pr-xl-5">
                          <span className="text-gray-color">
                            {pago?.habitacion?.tipo}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <h2 className="text-center text-xl-left mb-0">
                    <span>Total:</span>
                    <span className="text-primary font-weight-bold">
                      {"  "}${pago?.precio}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BookingHotelStep3;
