import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";
import NoticiaService from "../../../services/Noticia.service";
import { getAutoHeightDuration } from "@mui/material/styles/createTransitions";
import BlogCard from "./BlogCard";
import CategoriaBlogService from "../../../services/CategoriaBlog.service";
import ReCAPTCHA from "react-google-recaptcha";
import MensajeService from "../../../services/Mensaje.service";
import BlogMensaje from "./BlogMensaje";
import BlogTabs from "./BlogTabs";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TumblrShareButton,
} from "react-share";

const BlogIndividual = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [noticia, setNoticia] = useState({});
  const [categorias, setCategorias] = useState([]);
  const [noticias, setNoticias] = useState([]);
  const [autor, setAutor] = useState({});
  const [imag, setImagen] = useState(null);
  const [loading, setLoading] = useState(true);
  const [aleatorio, setAleatorio] = useState(true);
  const [nuevo, setNuevo] = useState(false);
  const [popular, setPopular] = useState(false);
  const [tag, setTag] = useState(false);
  const [filtro, setFiltro] = useState(undefined);
  const [tabUno, setTabUno] = useState("active");
  const [tabDos, setTabDos] = useState("");
  const [tabUnoCuerpo, setTabUnoCuerpo] = useState("show active");
  const [tabDosCuerpo, setTabDosCuerpo] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [valido, setValido] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [mensajes, setMensajes] = useState([]);
  const [totalMensajes, setTotalMensajes] = useState([]);
  const [sendMsg, setSendMsg] = useState(false);
  const matchesmd = useMediaQuery("(max-width:1000px)");

  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";

  const scroolUp = () => {
    const titleElement = document.getElementById("page-title");
    if (titleElement) {
      titleElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getNoticia();
    getNoticias();
    getMensajes();
  }, [id, sendMsg]);

  const onSendMsg = () => {
    setSendMsg(!sendMsg);
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setValido(false);
  }

  const getNoticia = async () => {
    try {
      const data = await NoticiaService.getById(id);
      S3Service.get(data[0].imagen).then((dataimagen) => {
        document.getElementById(`imagen${data[0].imagen}`).src =
          dataimagen.result;
        setImagen(dataimagen.result);
      });
      setNoticia(data[0]);
      console.log("titulo" + data[0].titulo);
      const aut = await UserService.getById(data[0].usuarioId);
      setAutor(aut);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      scroolUp();
    }
  };

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      console.log("NOTICIIAAS" + data.data);
      setNoticias(data.data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getMensajes = async () => {
    try {
      const data = await MensajeService.getByIdBlog(id);
      console.log("Mensajes" + data.total);
      setTotalMensajes(data.total);
      setMensajes(data.data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const enviarMensaje = async () => {
    if (!mensaje.MensajeCuerpo) {
      toast.info("Error, tienes que colocar un texto");
    } else {
      setLoading(true);
      try {
        let idUser = currentUser.id;
        let nombreUser = currentUser.nombre;
        const datosMensaje = {
          idBlog: id,
          IdUsuario: currentUser.id,
          nombre: nombreUser,
          MensajeCuerpo: mensaje.MensajeCuerpo,
          imagenes: currentUser.imagen,
        };
        await MensajeService.create(datosMensaje);
        const data = await MensajeService.getByIdBlog(id);
        console.log("Mensajes" + data.total);
        setTotalMensajes(data.total);
        setMensajes(data.data);
        onSendMsg();
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        const limpiar = {
          Nombre: "",
          MensajeCuerpo: "",
          idBlog: "",
        };
        setMensaje(limpiar);
        setValido(true);
        toast.info("Gracias, mensaje enviado");
        const data = await MensajeService.getByIdBlog(id);
        console.log("Mensajes" + data.total);
        setTotalMensajes(data.total);
        setMensajes(data.data);
        getMensajes();
      }
    }
  };

  const activarMensaje = () => {
    if (currentUser == undefined) {
      return (
        <div
          className="alert alert-warning alert-dismissible alert-confirm fade show mb-7"
          role="alert"
        >
          <i className="fa fa-exclamation" aria-hidden="true" />
          <span>
            <strong>Deja un comentario!</strong> Inicia sesión para dejar
            mensajes.
          </span>
        </div>
      );
    } else {
      return (
        <>
          <div className="form-group">
            <textarea
              className="form-control border-0 bg-smoke"
              rows={7}
              placeholder="Tu mensaje"
              defaultValue={""}
              onChange={(e) =>
                setMensaje({
                  ...mensaje,
                  MensajeCuerpo: e.target.value,
                })
              }
              value={mensaje.MensajeCuerpo}
            />
          </div>
          <div className="row" style={{ marginLeft: "45%" }}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={onChange}
            />
            <button
              className="btn btn-sm btn-outline-secondary text-uppercase py-2 font-weight-medium"
              disabled={valido}
              onClick={() => enviarMensaje()}
              style={{ marginLeft: "10%" }}
            >
              Enviar
            </button>
          </div>
        </>
      );
    }
  };

  const borrarComentario = async (idBlog) => {
    try {
      const dataBorrar = await MensajeService.remove(idBlog);
      const data = await MensajeService.getByIdBlog(id);
      console.log("Mensajes" + data.total);
      setTotalMensajes(data.total);
      setMensajes(data.data);
      onSendMsg();
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <>
      <section
        id="page-title"
        className="page-title"
        style={matchesmd ? { paddingTop: "100px" } : { paddingTop: "130px" }}
      >
        <div
          className="page-title-img bg-img bg-overlay-darken"
          style={{
            backgroundImage: "url(/assets/img/pages/page-title-bg13.jpg)",
          }}
        >
          <div className="containerPublic">
            <div
              className="row align-items-center justify-content-center"
              style={{ height: "200px" }}
            >
              <div className="col-lg-6">
                <div className="page-title-content">
                  <div className="">
                    <h2 className="text-uppercase text-white font-weight-bold">
                      {noticia?.titulo}
                    </h2>
                  </div>
                  <p className="text-white mb-0">{noticia?.categoria}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-top">
        <div className="py-8 py-md-9 py-lg-10">
          <div className="containerPublic">
            <div className="row">
              <div className="col-lg-8 col-xl-9 order-1 order-md-0">
                {/* Noticia */}
                <div className="card card-lg card-transparent mb-8">
                  {/* Cuerpo */}
                  <div className="position-relative">
                    <img
                      className="card-img-top rounded-lg lazyestload"
                      data-src="assets/img/blog/blog-single-01.jpg"
                      id={`imagen${noticia.imagen}`}
                      alt="Card image cap"
                    />
                  </div>
                  <div className="card-body px-2 py-6">
                    <h1>{noticia.titulo}</h1>
                    <div className="meta-post-sm mb-4">
                      <ul className="list-unstyled d-flex flex-wrap mb-0">
                        <li className="meta-tag mr-4 mb-1">
                          {/* <i
                          className="fa fa-user text-gray-color"
                          aria-hidden="true"
                        />
                        <a
                          className="text-gray-color hover-text-primary"
                          href="javascript:void(0)"
                        >
                          <span className="ml-1 text-capitalize">
                            {autor.nombre}
                          </span>
                        </a> */}
                        </li>
                        <li className="meta-tag text-gray-color mr-4 mb-1">
                          <i className="fa fa-calendar" aria-hidden="true" />
                          <span className="ml-1 text-capitalize">
                            {String(noticia.updateAt).slice(0, 10)}
                          </span>
                        </li>
                        <li className="meta-tag text-gray-color mr-4 mb-1">
                          <i className="fa fa fa-tags" aria-hidden="true" />
                          <span className="ml-1 text-capitalize">
                            {noticia.categoria}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: noticia.cuerpoNoticia,
                      }}
                      style={{ textAlign: "justify" }}
                    ></p>
                    <p>
                      "{noticia.fraseCelebre}" <br></br>-
                      {noticia.autorFraseCelebre}
                    </p>
                  </div>
                  {/* Comparte */}
                  <div className="card-footer d-flex align-items-center bg-smoke rounded p-3 p-md-4">
                    <span className="font-weight-bold font-size-15">
                      Comparte en
                    </span>
                    <ul className="list-unstyled d-flex ml-auto mb-0">
                      {/* facebook */}
                      <li className="mr-3">
                        <a
                          className="text-gray-color hover-text-primary"
                          href="javascript:void(0)"
                        >
                          <FacebookShareButton
                            quote={noticia.titulo + " leer más en: "}
                            id="btn-fb"
                            url={window.location.href}
                          >
                            <a href="javascript:void(0)">
                              <i
                                className="fa fa-facebook icon-medium"
                                aria-hidden="true"
                              />
                            </a>
                          </FacebookShareButton>
                        </a>
                      </li>
                      {/* twitter */}
                      <li className="mr-3">
                        <a
                          className="text-gray-color hover-text-primary"
                          href="javascript:void(0)"
                        >
                          <TwitterShareButton
                            quote={noticia.titulo + " leer más en: "}
                            id="btn-fb"
                            url={window.location.href}
                          >
                            <i
                              className="fa fa-twitter icon-medium"
                              aria-hidden="true"
                            />
                          </TwitterShareButton>
                        </a>
                      </li>
                      {/* linkedin */}
                      <li className="mr-3">
                        <a
                          className="text-gray-color hover-text-primary"
                          href="javascript:void(0)"
                        >
                          <LinkedinShareButton
                            quote={noticia.titulo + " leer más en: "}
                            id="btn-fb"
                            url={window.location.href}
                          >
                            <i
                              className="fa fa-linkedin icon-medium"
                              aria-hidden="true"
                            />
                          </LinkedinShareButton>
                        </a>
                      </li>
                      {/* pinterest */}
                      <li className="mr-3">
                        <a
                          className="text-gray-color hover-text-primary"
                          href="javascript:void(0)"
                        >
                          <PinterestShareButton
                            quote={noticia.titulo + " leer más en: "}
                            id="btn-fb"
                            url={window.location.href}
                            media={imag}
                          >
                            <i
                              className="fa fa-pinterest-p icon-medium"
                              aria-hidden="true"
                            />
                          </PinterestShareButton>
                        </a>
                      </li>

                      {/* google 
                    <li className="mr-3">
                      <a
                        href="avascript:void(0)"
                        className="text-gray-color hover-text-primary"
                      >
                        <i
                          className="fa fa-google-plus icon-medium"
                          aria-hidden="true"
                        />
                      </a>
                    </li>*/}

                      {/* tumblr */}
                      <li className="mr-3">
                        <a
                          className="text-gray-color hover-text-primary"
                          href="javascript:void(0)"
                        >
                          <TumblrShareButton
                            quote={noticia.titulo + " leer más en: "}
                            id="btn-tb"
                            url={window.location.href}
                          >
                            <i
                              className="fa fa-tumblr icon-medium"
                              aria-hidden="true"
                            />
                          </TumblrShareButton>
                        </a>
                      </li>
                      {/* instagram
                    <li>
                      <a
                        href="avascript:void(0)"
                        className="text-gray-color hover-text-primary"
                      >
                        <i
                          className="fa fa-instagram icon-medium"
                          aria-hidden="true"
                        />
                      </a>
                    </li> */}
                    </ul>
                  </div>
                </div>
                {/* Comentarios */}
                <div className="mb-7">
                  <h3 className="mb-6">Comentarios ({totalMensajes})</h3>
                  {/*Comentario */}
                  {mensajes.map((record) => {
                    return (
                      <BlogMensaje
                        id={record.id}
                        nombre={record.nombre}
                        createdAt={record.createdAt}
                        imagenes={record.imagenes}
                        borrarComentario={(data) => borrarComentario(data)}
                        borrar={
                          currentUser?.id == record.IdUsuario ? true : false
                        }
                        MensajeCuerpo={record.MensajeCuerpo}
                      />
                    );
                  })}
                </div>
                {/*Deja un comentario */}
                <div className="mb-7 mb-lg-0">
                  <h3 className="mb-6">Deja un comentario</h3>
                  {activarMensaje()}
                </div>
              </div>
              {/* Derecha */}
              <div className="col-lg-4 col-xl-3">
                <div className="">
                  <div className="row">
                    <div className="col-md-6 col-lg-12">
                      {/*PESTAÑAS*/}
                      {/*<div className="d-none d-md-block">
                      <div className="mb-4">
                        <ul
                          className="nav nav-tabs nav-justified blog-tabs"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className={"nav-link " + tabUno}
                              id="recent-tab"
                              data-toggle="tab"
                              role="tab"
                              aria-controls="recent"
                              aria-selected="true"
                              onClick={() => {
                                setTabUno("active");
                                setTabDos("");
                                setTabUnoCuerpo("show active");
                                setTabDosCuerpo("");
                              }}
                            >
                              Recientes
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={"nav-link " + tabDos}
                              id="popular-tab"
                              data-toggle="tab"
                              role="tab"
                              aria-controls="popular"
                              aria-selected="false"
                              onClick={() => {
                                setTabUno("");
                                setTabDos("active");
                                setTabUnoCuerpo("");
                                setTabDosCuerpo("show active");
                              }}
                            >
                              Destacados
                            </a>
                          </li>
                        </ul>
                        <div
                          className="tab-content bg-smoke border border-light-gray border-top-0 rounded-bottom px-3 py-5"
                          id="myTabContent"
                        >
                          <div
                            className={"tab-pane fade " + tabUnoCuerpo}
                            id="recent"
                            role="tabpanel"
                            aria-labelledby="recent-tab"
                          >
                            {noticias.map((record, index) => {
                              return (
                                <BlogCard
                                  id={record.id}
                                  titulo={record.titulo}
                                  imagenes={record.imagen}
                                  fecha={record.updateAt}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>*/}
                      {/*PESTAÑAS*/}
                      <BlogTabs />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-md-none"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogIndividual;
