import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import styles from "../layout.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import ApartmentIcon from "@mui/icons-material/Apartment";

const Slide = () => {
  const { logout } = useContext(AuthContext);
  const [active, setActive] = useState("");
  let navigate = useNavigate();
  const matches = useMediaQuery("(min-width:769px)");
  const styleNavLink = {
    display: " flex",
    color: "var(--color-info-dark)",
    marginLeft: " 2rem",
    gap: "1rem",
    alignItems: "center",
    position: " relative",
    height: "3.7rem",
    transition: "all 300ms ease",
  };

  const closeMenu = () => {
    let element;
    element = document.querySelector("#aside_responsive");
    element.style.display = "none";
  };

  const salir = () => {
    logout();
    navigate("/");
  };

  const navegar = (direccion) => {
    if (direccion === "Dashboard") {
      navigate("/");
      setActive(direccion);
      return;
    }
    setActive(direccion);
    navigate(direccion);
    !matches && closeMenu();
  };

  useEffect(() => {
    let url = window.location.href.split("/");

    if (url.includes("carousel")) {
      setActive("carousel");
    } else if (url.includes("blog")) {
      setActive("blog");
    } else if (url.includes("categoriaBlog")) {
      setActive("categoriaBlog");
    } else if (url.includes("paquete")) {
      setActive("paquete");
    } else if (url.includes("hoteles")) {
      setActive("hoteles");
    } else if (url.includes("ubicacion")) {
      setActive("ubicacion");
    } else if (url.includes("pagos")) {
      setActive("pagos");
    } else if (url.includes("usuario")) {
      setActive("usuario");
    } else if (url.includes("index")) {
      setActive("index");
    } else if (url.includes("aviso")) {
      setActive("aviso");
    } else if (url.includes("terminos")) {
      setActive("terminos");
    } else if (url.includes("footer")) {
      setActive("footer");
    } else {
      setActive("Dashboard");
    }
  }, []);
  return (
    <aside
      id="aside_responsive"
      style={
        matches
          ? {
              backgroundColor: "white",
            }
          : {
              backgroundColor: "white",
              paddingInlineStart: "8rem",
              width: "27rem",
            }
      }
    >
      <div className={styles.top}>
        <div className={styles.logo}>
          {/*<img src="" alt="Logo" />*/}
          <h2 onClick={() => navegar("Dashboard")}>
            CASA{"  "} <span className={styles.danger}>ADOBE</span>
          </h2>
          <br />
        </div>
        <div className={styles.close} id="close-btn">
          <span className={"material-icons-sharp"} onClick={() => closeMenu()}>
            {" "}
            close{" "}
          </span>
        </div>
      </div>
      <div className={styles.sidebar} style={{ backgroundColor: "white" }}>
        <button
          onClick={() => {
            navegar("Dashboard");
          }}
          className={`${styles.navLinkButton} ${
            (active === "Dashboard" || active === "") && styles.active
          }`}
          style={{ border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp"> dashboard </span>
          <h3>Dashboard</h3>
        </button>
        <p>
          <h3>Administración</h3>
        </p>
        <button
          onClick={() => navegar("carousel")}
          className={`${styles.navLinkButton} ${
            active === "carousel" && styles.active
          }`}
          style={{ border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp"> perm_media </span>
          <h3>Carrusel</h3>
        </button>
        <button
          onClick={() => navegar("blog")}
          className={`${styles.navLinkButton} ${
            active === "blog" && styles.active
          }`}
          style={{ border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp"> menu_book </span>
          <h3>Blog</h3>
        </button>
        <button
          onClick={() => navegar("categoriaBlog")}
          className={`${styles.navLinkButton} ${
            active === "categoriaBlog" && styles.active
          }`}
          style={{ border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp"> format_list_bulleted </span>
          <h3>Categoria Blog</h3>
        </button>
        <button
          onClick={() => navegar("paquete")}
          className={`${styles.navLinkButton} ${
            active === "paquete" && styles.active
          }`}
          style={{ border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp"> public </span>
          <h3>Paquetes</h3>
        </button>
        <button
          onClick={() => navegar("hoteles")}
          className={`${styles.navLinkButton} ${
            active === "hoteles" && styles.active
          }`}
          style={{ border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp">
            {" "}
            <ApartmentIcon />{" "}
          </span>
          <h3>Hoteles</h3>
        </button>
        {/*<a
          href=""
          onClick={() => navegar("places")}
          className={`${active == "places" && "active"}`}
        >
          <span className="material-icons-sharp"> add_location_alt </span>
          <h3>Places</h3>
        </a>*/}
        <button
          onClick={() => navegar("ubicacion")}
          className={`${styles.navLinkButton} ${
            active === "ubicacion" && styles.active
          }`}
          style={{ border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp"> add_location_alt </span>
          <h3>Ubicacion</h3>
        </button>

        <button
          onClick={() => navegar("pagos")}
          className={`${styles.navLinkButton} ${
            active === "pagos" && styles.active
          }`}
          style={{ border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp"> event_seat </span>
          <h3>Reservas</h3>
        </button>

        <button
          onClick={() => navegar("usuario")}
          className={`${styles.navLinkButton} ${
            active === "usuario" && styles.active
          }`}
          style={{ border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp"> group </span>
          <h3>Usuarios</h3>
        </button>

        <p>
          <h3>Edición</h3>
        </p>
        <button
          onClick={() => navegar("index")}
          className={`${styles.navLinkButton} ${
            active === "index" && styles.active
          }`}
          style={{ border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp"> border_color </span>
          <h3>Página de inicio</h3>
        </button>
        <button
          onClick={() => navegar("aviso")}
          className={`${styles.navLinkButton} ${
            active === "aviso" && styles.active
          }`}
          style={{ border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp"> border_color </span>
          <h3>Aviso de privacidad</h3>
        </button>
        <button
          onClick={() => navegar("terminos")}
          className={`${styles.navLinkButton} ${
            active === "terminos" && styles.active
          }`}
          style={{ ...styleNavLink, border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp"> border_color </span>
          <h3>Terminos y condiciones</h3>
        </button>
        <button
          onClick={() => navegar("footer")}
          className={`${styles.navLinkButton} ${
            active == "footer" && styles.active
          }`}
          style={{ border: "none", backgroundColor: "unset" }}
        >
          <span className="material-icons-sharp"> border_color </span>
          <h3>Footer</h3>
        </button>
        <button
          className={`${styles.navLinkButton}`}
          style={{ border: "none", backgroundColor: "unset" }}
          onClick={() => salir()}
        >
          <span className="material-icons-sharp"> logout </span>
          <h3>Logout</h3>
        </button>
      </div>
    </aside>
  );
};
export default Slide;
