import httpClient from "./HttpClient";
const prefix = "/pago";

export default class PagoService {
  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}-id/${id}`)).data;
  }

  static async getStatus(id) {
    return (await httpClient.get(`${prefix}-status/${id}`)).data;
  }

  static async cancel(pago) {
    return (await httpClient.put(`${prefix}-cancel/${pago.id}`, pago)).data;
  }

  static async cancela(pago) {
    return (await httpClient.put(`${prefix}-cancela/${pago.id}`, pago)).data;
  }

  static async getAll() {
    return (await httpClient.get(`${prefix}/`)).data;
  }

  static async update(pago) {
    return (await httpClient.put(`${prefix}/${pago.data.id}`, pago)).data;
  }
  // static async getAllPaid() {
  //   return (await httpClient.get(`${prefix}s-pagados`)).data;
  // }

  static async list(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }

  static async totalPagos() {
    return (await httpClient.get(`${prefix}/totalPagos`)).data;
  }

  static async countPagosByStatus(status) {
    return (await httpClient.get(`${prefix}/${status}`)).data;
  }

  static async deletePagosByStatus(status) {
    return (await httpClient.delete(`${prefix}/${status}`)).data;
  }

  static async Corte(id) {
    return (await httpClient.delete(`${prefix}_c/${id}`)).data;
  }

  static async findPagosByPaquete(id) {
    return (await httpClient.get(`${prefix}-paquete/${id}`)).data;
  }
}
