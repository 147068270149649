import httpClient from "./HttpClient";
const prefix = "/hotel";

export default class HotelService {
  static async create(paquete) {
    return (await httpClient.post(`${prefix}/`, paquete)).data;
  }

  static async update(paquete) {
    return (await httpClient.put(`${prefix}/${paquete.id}`, paquete)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getByHabitacion(id) {
    return (await httpClient.get(`${prefix}-habitacion/${id}`)).data;
  }

  static async getId(id) {
    return (await httpClient.get(`${prefix}-get/${id}`)).data;
  }

  static async getTotal() {
    return (await httpClient.get(`${prefix}-total/`)).data;
  }

  static async list(limit, offset) {
    return (await httpClient.get(`${prefix}/${limit}/${offset}`)).data;
  }

  static async listHoteles(limit, offset) {
    return (await httpClient.get(`${prefix}es/${limit}/${offset}`)).data;
  }

  static async mostrarInicio(limit) {
    return (await httpClient.get(`${prefix}-mostrarInicio/${limit}`)).data;
  }

  static async filtroCiudad(limit, filtros) {
    return (await httpClient.post(`${prefix}-filtroCiudad/${limit}`, filtros))
      .data;
  }

  static async mejoresOfertas(limit) {
    return (await httpClient.get(`${prefix}-oferta/${limit}`)).data;
  }

  //   static async filtroFecha(limit, filtro) {
  //     return (await httpClient.post(`${prefix}-filtroFecha/${limit}`, filtro))
  //       .data;
  //   }

  //   static async filtroCiudadFecha(limit, filtro) {
  //     return (
  //       await httpClient.post(`${prefix}-filtroCiudadFecha/${limit}`, filtro)
  //     ).data;
  //   }

  //   static async reservasAltas(limit) {
  //     return (await httpClient.get(`${prefix}-reservas/${limit}`)).data;
  //   }
}
