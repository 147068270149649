import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

//servicio
import S3Service from "../../../services/S3.service";

const NuestroPaqueteItem = ({
  id,
  titulo,
  precioU,
  precioE,
  descuento,
  imagenes,
  fecha,
  tipo,
}) => {
  const navigate = useNavigate();
  let precioFinal = 0;
  const [imag, setImag] = useState([]);
  const [precio, setPrecio] = useState(0);
  let precios = 0;
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  if (descuento > 0) {
    const descuentoProps = descuento;
    const precioOriginal = precio;
    precioFinal = precioOriginal - 100 / (precioOriginal / descuentoProps);
  }

  if (tipo == "Empresa") {
    if (precioE == null) {
      precios = 0;
    } else {
      for (const pre of precioE) {
        if (pre.tipo == "Adulto") {
          precios = pre.precio;
          //setPrecio(pre.props.precio);
        }
      }
    }
  } else {
    if (precioU == null) {
      precios = 0;
    } else {
      for (const pre of precioU) {
        if (pre.tipo == "Adulto") {
          precios = pre.precio;
          //setPrecio(pre.props.precio);
        }
      }
    }
  }

  useEffect(() => {
    if (imagenes?.length > 0) {
      getImagen();
    }
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes);
      setImag(result.result);
      imagen = result.result;
      console.log("Imagen " + imagen);
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="col-md-6 col-lg-4 element-item america">
      <div className="media media-hoverable justify-content-center mb-2 mb-md-4 mb-lg-6">
        <a onClick={() => navigate(`/paquete/${id}`)} className="media-content">
          <img
            className="media-img lazyestload"
            data-src="assets/img/home/packages/packages-1.jpg"
            src={imag}
            alt="Generic placeholder image"
            height="230"
            width="250"
          />
          <div className="media-object" style={{ textAlign: "center" }}>
            <h3 className="text-uppercase mb-0">{titulo}</h3>
          </div>
          <div className="media-img-overlay">
            <div className="overlay-content">
              <h3 className="">
                Desde <span className="text-primary">${precios}</span>
              </h3>
              <p className="text-white mb-0">
                <span className="mr-1">
                  <i className="fa fa-calendar" aria-hidden="true" />
                </span>
                {String(fecha).slice(0, 10)}
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default NuestroPaqueteItem;
