/* React */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Components & dependencies */
import { Button } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DropComponent from "../imagesDrop";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";

import styles from "../layout.module.css";
import TextField from "@mui/material/TextField";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import CardPaquetePrecio from "./CardPaqutePrecio";
import CardDay from "./CardDay";
import DropZone from "../../DropZone";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";

/* Services */
import S3Service from "../../../services/S3.service";
import PaqueteService from "../../../services/Paquete.service";
import UbicacionService from "../../../services/UbicacionService";

const CrearPaquete = () => {
  //#region variables
  const matches = useMediaQuery("(min-width:600px)");
  const { id } = useParams();
  const navigate = useNavigate();
  const [paquete, setPaquete] = useState({});
  const [loading, setLoading] = useState(true);

  const [imagenesProducto, setImagenesProducto] = useState([]);
  const [imagenesOriginal, setImagenesOriginal] = useState([]);
  const [datos, setDatos] = useState({});
  const [talla, setTalla] = useState({});
  const [tallasArray, setTallasArray] = useState([]);
  const [etiquetas, setEtiquetas] = useState("");
  const [etiquetasArray, setEtiquetasArray] = useState([]);
  let arrayOriginal = [];
  const [contador, setContador] = useState(1);

  const [mostrarI, setMostrarI] = useState(false);
  const myTheme = createTheme({});
  const [file, setFile] = useState(undefined);

  const [dia, setDia] = useState({});
  const [diasArray, setDiasArray] = useState([]);
  const [diaContador, setDiaContador] = useState(0);

  const [paquetePrecioU, setpaquetePrecioU] = useState({});
  const [paquetePrecioArrayU, setpaquetePrecioArrayU] = useState([]);
  const [precioUContador, setPrecioUContador] = useState(0);

  const [paquetePrecioE, setpaquetePrecioE] = useState({});
  const [paquetePrecioArrayE, setpaquetePrecioArrayE] = useState([]);
  const [precioEContador, setPrecioEContador] = useState(0);
  const [ubicaciones, setUbicaciones] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [ubicacion, setUbicacion] = useState([]);
  const [ubicacion_NEW, setUbicacion_NEW] = useState({});
  const [mostrarI_NEW, setMostrarI_NEW] = useState(false);
  const [ciudad, setCiudad] = useState("");
  const [contenido, setContenido] = useState({});
  const [contenidoestado, setContenidoestado] = useState(0);

  const [incluye, setIncluye] = useState({});
  const [incluyeEstado, setIncluyeEstado] = useState(0);

  let pasoUsuario = 0;
  let pasoEmpresa = 0;
  let pasoDia = 0;

  let arrayOriginalU = [];
  let arrayOriginalE = [];
  let arrayPaqEmpresa = [];
  let arrayPaqUser = [];

  let arrayDiasJSon = [];
  let arrayOriginalDiasJson = [];
  //#endregion

  useEffect(() => {
    getPaquete();
    getUbicacion();
  }, []);

  //#region ubicacion
  const getUbicacion = async () => {
    try {
      const data = await UbicacionService.getAll();
      setUbicacion(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const addCiudad = (id, ciudad) => {
    setPaquete({
      ...paquete,
      ubicacion: id,
    });
    setCiudad(ciudad);
    toast.success(ciudad);
  };
  //#endregion

  const getPaquete = async () => {
    try {
      const data = await PaqueteService.getId(id);
      setPaquete(data);
      console.log(data);
      setMostrarI(data.mostrarInicio);
      let imagenes = [];
      data.links = [];
      if (data.imagenes) {
        for (const imagen of data.imagenes) {
          console.log(imagen);
          const result = await S3Service.get(imagen);
          imagenes.push(result.result);
          data.links.push(result.result);
          // setImagenesProducto((imagenesProducto) => [
          //   ...imagenesProducto,
          //   result.result,
          // ]);
          console.log(result.result);
        }
        console.log(imagenes);
        setImagenesProducto(imagenes);
        setImagenesOriginal(data.imagenes);
      }

      if (data.descripcion) {
        const contentHTML = convertFromHTML(data.descripcion);
        const state = ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        );
        const content = JSON.stringify(convertToRaw(state));
        setContenido(content);
        setContenidoestado(1);
      }

      if (data.incluye) {
        const contentHTML = convertFromHTML(data.incluye);
        const state = ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        );
        const content = JSON.stringify(convertToRaw(state));
        setIncluye(content);
        setIncluyeEstado(1);
      }

      if (data.paquetesUsuario && pasoUsuario == 0) {
        console.log(data.paquetesUsuario);
        let contadorPaquetesUsuario = 1;
        pasoUsuario = 1;
        let contadorTemp = 1;
        for (const record of data.paquetesUsuario) {
          const newPaquetePrecio = {
            // <CardPaquetePrecio
            //   id={contadorPaquetesUsuario}
            //   cantidad={record.props.cantidad}
            //   tipo={record.props.tipo}
            //   precio={record.props.precio}
            //   user={"Usuario"}
            //   eliminarActual={(data) => eliminarPaqueteUsuario(data)}
            // />
            id: record.id,
            cantidad: record.cantidad,
            tipo: record.tipo,
            precio: record.precio,
            user: "Usuario",
          };
          arrayOriginalU.push(newPaquetePrecio);
          contadorPaquetesUsuario += 1;
        }
        setPrecioUContador(contadorPaquetesUsuario);
        setpaquetePrecioArrayU(arrayOriginalU);
        arrayPaqUser = arrayOriginalU;
        setContador(contadorTemp);
      }

      if (data.paquetesEmpresa && pasoEmpresa == 0) {
        let contadorPaquetesEmpresa = 1;
        pasoEmpresa = 1;
        for (const record of data.paquetesEmpresa) {
          const newPaquetePrecio = (
            <CardPaquetePrecio
              id={contadorPaquetesEmpresa}
              cantidad={record.props.cant}
              tipo={record.props.tipo}
              precio={record.props.precio}
              user={"Empresa"}
              eliminarActual={(data) => eliminarPaqueteEmpresa(data)}
            />
          );
          arrayOriginalE.push(newPaquetePrecio);
          contadorPaquetesEmpresa += 1;
        }
        setpaquetePrecioArrayE(arrayOriginalE);
        arrayPaqEmpresa = arrayOriginalE;
      }

      if (data.diasJson) {
        let contadorDiasJ = 1;
        pasoDia = 1;
        for (const record of data.diasJson) {
          const imagen = await S3Service.get(record.imagen);
          const newDay = {
            id: contadorDiasJ,
            titulo: record.titulo,
            descripcion: record.descripcion,
            url: imagen.result,
            imagen: record.imagen,
          };
          arrayOriginalDiasJson.push(newDay);
          contadorDiasJ += 1;
        }
        setDiasArray(arrayOriginalDiasJson);
        setContador(contadorDiasJ);
      }

      setCiudad(data.ciudad);
    } catch (error) {
      toast.error(error);
    }
  };

  const limpiarItinerario = () => {
    try {
      dia.titulo = "";
      dia.descripcion = "";
    } catch (error) {
    } finally {
    }
  };

  //#region Dias
  const saveDay = async () => {
    setDiaContador(diaContador + 1);
    console.log("id  del dia: " + diaContador);
    try {
      // const newDay = (
      //   <CardDay
      //     id={diaContador}
      //     titulo={dia.titulo}
      //     descripcion={dia.descripcion}
      //     imagen={file}
      //     url={URL.createObjectURL(file)}
      //     eliminarActual={(data) => {
      //       eliminarDay(data);
      //     }}
      //   />
      // );
      const newDay = {
        id: diaContador,
        titulo: dia.titulo,
        descripcion: dia.descripcion,
        imagen: file,
        url: URL.createObjectURL(file),
      };
      setDiasArray((diasArray) => [...diasArray, newDay]);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarDay = (id) => {
    console.log("Eliminar id:" + id);

    try {
      let arrayOriginal = diasArray;
      console.log("dias array eliminar, length: " + diasArray);
      console.log("****ELIMINAR: " + arrayOriginal.length);
      let nuevoArray = [];
      // arrayOriginal.map((record) => {
      //   if (record.props.id != id) {
      //     console.log("****push ID: " + record.props.id);
      //     nuevoArray.push(record);
      //   }
      // });
      arrayOriginal.map((record) => {
        if (record.id !== id) {
          console.log("****push ID: " + record.id);
          nuevoArray.push(record);
        }
      });
      setDiasArray(nuevoArray);
      //arrayOriginalDiasJson=nuevoArray;
      //arrayDiasJSon = nuevoArray;
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region paqueteUsuario
  const savePaquetePrecioU = (userType) => {
    setPrecioUContador((prev) => prev + 1);
    try {
      const newPaquetePrecio = {
        cantidad: paquetePrecioU.cant,
        tipo: paquetePrecioU.tipo,
        precio: paquetePrecioU.precio,
        user: userType,
        id: precioUContador,
      };

      setpaquetePrecioArrayU((paquetesArray) => [
        ...paquetesArray,
        newPaquetePrecio,
      ]);
      setpaquetePrecioU({ ...paquetePrecioU, cant: "", precio: "" });
    } catch (error) {
      console.log(error);
    }
  };
  const eliminarPaqueteUsuario = (id) => {
    try {
      let arrayOriginal = paquetePrecioArrayU;
      let nuevoArray = [];
      arrayOriginal.map((record) => {
        if (record.id !== id) {
          nuevoArray.push(record);
        }
      });
      setpaquetePrecioArrayU(nuevoArray);
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region paqueteEmpresa
  const savePaquetePrecioE = (userType) => {
    setPrecioEContador(precioEContador + 1);
    try {
      const newPaquetePrecio = (
        <CardPaquetePrecio
          cantidad={paquetePrecioE.cant}
          tipo={paquetePrecioE.tipo}
          precio={paquetePrecioE.precio}
          user={userType}
          id={precioEContador}
          eliminarActual={(data) => {
            eliminarPaqueteEmpresa(data);
          }}
        />
      );
      setpaquetePrecioArrayE((paquetesArray) => [
        ...paquetesArray,
        newPaquetePrecio,
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarPaqueteEmpresa = (id) => {
    try {
      let arrayOriginal = paquetePrecioArrayE;
      let nuevoArray = [];
      arrayOriginal.map((record) => {
        if (record.props.id != id) {
          nuevoArray.push(record);
        }
      });
      setpaquetePrecioArrayE(nuevoArray);
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  const updatePaquete = async () => {
    if (!paquete.titulo) {
      toast.info("Error, tienes que colocar un titulo");
    } else if (!paquete.subtitulo) {
      toast.info("Error, tienes que agregar un subtitulo");
    } else if (!paquete.descripcion) {
      toast.info("Error, tienes que colocar una descripcion");
    } else if (imagenesProducto.length <= 0) {
      toast.info("Error, tienes que agregar imagenes");
    } else if (!paquete.fechaFinal) {
      toast.info("Error, tienes especificar la fecha final");
    } else if (!paquete.fechaInicial) {
      toast.info("Error, tienes especificar la fecha inicial");
    } else if (!paquete.cupo) {
      toast.info("Error, tienes que agregar un cupo");
    } else if (paquetePrecioArrayU.length <= 0) {
      toast.info("Error, tienes que agregar los precios para los usuario");
    } else {
      setLoading(true);
      try {
        const informacion = { ...datos, ...paquete };

        let imagenesArray = []; //Array para los id del s3
        let diasjson = []; //Array para almecenar los dias en formatos JSON y guardarlos en la BD
        /* borrar imagenes del carrusel */
        for (const file of imagenesOriginal) {
          await S3Service.delete(file);
          console.log("---borrada:" + file);
        }

        /* Subida de imagenes del carrusel */
        for (const file of imagenesProducto) {
          if (!file.urlImaen) {
            const resultFile = await S3Service.upload(file);
            imagenesArray.push(resultFile.result.data);
          } else {
            const idImagen = file.urlImaen.split("/")[3].split("?")[0];
            imagenesArray.push(idImagen);
          }
        }

        console.log(diasArray);
        /* Subida de imagenes de los dias */
        for (const dia of diasArray) {
          console.log("hay un dia: ");
          let img;
          if (typeof dia.imagen !== "string") {
            const resultImagenDia = await S3Service.upload(dia.imagen);
            img = resultImagenDia.result.data;
          } else {
            img = dia.imagen;
          }

          const diaJSON = {
            titulo: dia.titulo,
            descripcion: dia.descripcion,
            imagen: img,
          };
          diasjson.push(diaJSON);
          console.log("el dia es:" + diaJSON);
        }

        const {
          titulo,
          subtitulo,
          descripcion,
          incluye,
          tituloItinerario,
          descuento,
          ubicacion,
          fechaInicial,
          fechaFinal,
          cupo,
        } = paquete;

        toast.info("ciudad" + ciudad);
        const dataPaquete = {
          id: id,
          titulo,
          subtitulo,
          descripcion,
          incluye,
          tituloItinerario,
          ubicacion,
          fechaInicial,
          fechaFinal,
          cupo,
          ciudad: ciudad,
          descuento: parseInt(descuento),
          imagenes: imagenesArray,
          diasJson: diasjson,
          paquetesUsuario: paquetePrecioArrayU,
          paquetesEmpresa: paquetePrecioArrayE,
          mostrarInicio: mostrarI,
        };
        console.log("***" + dataPaquete.id);
        console.log("***" + dataPaquete.titulo);
        console.log("***" + dataPaquete.subtitulo);
        console.log("***" + dataPaquete.descripcion);
        console.log("***" + dataPaquete.tituloItinerario);
        console.log("***" + dataPaquete.descuento);
        console.log("***" + dataPaquete.ubicacion);
        console.log("***" + dataPaquete.fechaInicial);
        console.log("***" + dataPaquete.fechaFinal);
        console.log("***" + dataPaquete.cupo);
        console.log("***" + dataPaquete.imagenes);
        console.log("***" + dataPaquete.paquetesUsuario);
        console.log("***" + dataPaquete.paquetesEmpresa);
        console.log("***" + dataPaquete.mostrarInicio);

        const actualizacionProducto = await PaqueteService.update(dataPaquete);

        toast.success("Paquete editado con exito");
        setLoading(false);
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        navigate("/paquete");
      }
    }
  };

  const saveUbicacion = async () => {
    if (!ubicacion_NEW.lat) {
      toast.info("Error, tienes que colocar una latitud");
    } else if (!ubicacion_NEW.lng) {
      toast.info("Error, tienes que colocar una longitud");
    } else if (!ubicacion_NEW.ciudad) {
      toast.info("Error, tienes que colocar una ciudad");
    } else if (!ubicacion_NEW.pais) {
      toast.info("Error, tienes que colocar un pais");
    } else if (!ubicacion_NEW.continente) {
      toast.info("Error, tienes que colocar un continente");
    } else {
      setLoading(true);
      try {
        const datosUbicacion = ubicacion_NEW;
        console.log(datosUbicacion);
        await UbicacionService.create(datosUbicacion);
        toast.success("Ubicacion creada con exito!");
        getUbicacion();
        setUbicacion_NEW(undefined);
      } catch (error) {
        toast.error(error);
      } finally {
        handleClose();
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <div
            style={
              matches
                ? {
                    boxShadow: "var(--box-shadow)",
                    backgroundColor: "var(--color-white)",
                  }
                : {
                    boxShadow: "var(--box-shadow)",
                    backgroundColor: "var(--color-white)",
                    width: "100%",
                  }
            }
          >
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none", padding: "0" }}>
                <div
                  className={styles.recentorders}
                  style={{
                    width: "auto",
                    position: "inherit",
                    margin: "inherit",
                    transform: "unset",
                  }}
                >
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <th>
                            <h1 style={{ marginBottom: "3%" }}>
                              EDITAR PAQUETE
                            </h1>
                          </th>
                        </tr>
                        <tr>
                          Mostrar al inicio
                          <Switch
                            checked={mostrarI}
                            onClick={() => {
                              console.log("el estado 1: " + mostrarI);
                              mostrarI ? setMostrarI(false) : setMostrarI(true);
                              console.log("el estado 2: " + mostrarI);
                            }}
                          />
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={styles.row}
              style={
                matches
                  ? {
                      display: "grid",
                      gridTemplateColumns: "50% 50%",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
                  : {
                      display: "grid",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
              }
            >
              <div style={{ width: "100%" }}>
                <h3>Titulo</h3>
                <input
                  id="titulo"
                  placeholder="Titulo"
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "#dce1eb",
                    padding: "3%",
                    marginLeft: "-1%",
                    width: "98%",
                  }}
                  value={paquete?.titulo}
                  onChange={(e) =>
                    setPaquete({
                      ...paquete,
                      titulo: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ width: "100%" }}>
                <h3>Subtitulo</h3>
                <input
                  id="subtitulo"
                  placeholder="Subtitulo"
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "#dce1eb",
                    padding: "3%",
                    marginLeft: "-1%",
                    width: "98%",
                  }}
                  value={paquete?.subtitulo}
                  onChange={(e) =>
                    setPaquete({
                      ...paquete,
                      subtitulo: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div
              className={styles.row}
              style={
                matches
                  ? {
                      display: "grid",
                      gridTemplateColumns: "50% 50%",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
                  : {
                      display: "grid",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
              }
            >
              <div style={{ width: "100%" }}>
                <h3>Fecha inicial</h3>
                <TextField
                  id="fechaInicial"
                  type="date"
                  InputLabelProps={{
                    style: { color: "#ff891e" },
                    shrink: true,
                  }}
                  style={{ width: "98%" }}
                  onChange={(e) =>
                    setPaquete({
                      ...paquete,
                      fechaInicial: e.target.value,
                    })
                  }
                  value={paquete?.fechaInicial}
                />
              </div>
              <div style={{ width: "100%" }}>
                <th>Fecha final</th>
                <TextField
                  id="fechaFinal"
                  type="date"
                  InputLabelProps={{
                    style: { color: "#ff891e" },
                    shrink: true,
                  }}
                  style={{ width: "98%" }}
                  onChange={(e) =>
                    setPaquete({
                      ...paquete,
                      fechaFinal: e.target.value,
                    })
                  }
                  value={paquete?.fechaFinal}
                />
              </div>
            </div>
            <div
              className={styles.row}
              style={
                matches
                  ? {
                      display: "grid",
                      gridTemplateColumns: "50% 50%",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
                  : {
                      display: "grid",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
              }
            >
              <div style={{ width: "100%" }}>
                <h3>Cupo ($)</h3>
                <input
                  id="cupo"
                  placeholder="Cupo"
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "#dce1eb",
                    padding: "3%",
                    marginLeft: "-1%",
                    width: "98%",
                  }}
                  value={paquete?.cupo}
                  onChange={(e) =>
                    setPaquete({
                      ...paquete,
                      cupo: e.target.value,
                    })
                  }
                  type="number"
                />
              </div>
              <div style={{ width: "100%" }}>
                <h3>Descuento (%)</h3>
                <input
                  id="descuento"
                  placeholder="Descuento"
                  style={{
                    width: "-webkit-fill-available",
                    backgroundColor: "#dce1eb",
                    padding: "3%",
                    marginLeft: "-1%",
                    width: "98%",
                  }}
                  value={paquete?.descuento}
                  onChange={(e) =>
                    setPaquete({
                      ...paquete,
                      descuento: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div
              className={styles.row}
              style={
                matches
                  ? {
                      display: "grid",
                      gridTemplateColumns: "50% 50%",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
                  : {
                      display: "grid",
                      justifyItems: "center",
                      padding: "1.8rem",
                    }
              }
            >
              <div style={{ width: "100%" }}></div>
              <div style={{ width: "100%" }}></div>
            </div>
            <h3 style={{ paddingInline: "1.8rem" }}>Descripción</h3>
            <div
              style={
                matches
                  ? { marginLeft: "6%", marginRight: "6%", minHeight: "7rem" }
                  : {
                      marginLeft: "6%",
                      marginRight: "6%",
                      padding: "2rem",
                      minHeight: "7rem",
                    }
              }
            >
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  id="descripcion"
                  label="Descripcion del paquete"
                  controls={[
                    "italic",
                    "underline",
                    "strikethrough",
                    "highlight",
                    "undo",
                    "redo",
                    "link",
                    "numberList",
                    "bulletList",
                    "quote",
                    "code",
                    "clear",
                  ]}
                  defaultValue={contenido}
                  inlineToolbar={true}
                  onChange={(value) =>
                    setPaquete({
                      ...paquete,
                      descripcion: stateToHTML(value.getCurrentContent()),
                    })
                  }
                />
              </ThemeProvider>
              <br></br>
            </div>
            <br />
            <h3 style={{ paddingInline: "1.8rem" }}>Incluye</h3>
            <div
              style={
                matches
                  ? {
                      minHeight: "7rem",
                      marginLeft: "6%",
                      marginRight: "6%",
                    }
                  : {
                      marginLeft: "6%",
                      marginRight: "6%",
                      padding: "2rem",
                      minHeight: "7rem",
                    }
              }
            >
              <ThemeProvider theme={myTheme}>
                <MUIRichTextEditor
                  id="incluye"
                  label="Descripcion del paquete"
                  controls={[
                    "italic",
                    "underline",
                    "strikethrough",
                    "highlight",
                    "undo",
                    "redo",
                    "link",
                    "numberList",
                    "bulletList",
                    "quote",
                    "code",
                    "clear",
                  ]}
                  inlineToolbar={true}
                  defaultValue={incluye}
                  onChange={(value) =>
                    setPaquete({
                      ...paquete,
                      incluye: stateToHTML(value.getCurrentContent()),
                    })
                  }
                />
              </ThemeProvider>
              <br></br>
            </div>

            <div className="row">
              <div
                className="col-lg-12"
                style={{ overflow: "auto", padding: "1.8rem" }}
              >
                <DropComponent
                  label="Tamaño recomendado 900px * 600px"
                  footer="Archivos aceptados png,jpeg,jpg"
                  subirImagen={(data) => setImagenesProducto(data)}
                  imagenesDefault={paquete.links}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              boxShadow: "var(--box-shadow)",
              backgroundColor: "var(--color-white)",
              marginBottom: "5%",
            }}
          >
            {/* ITINERARIO  */}
            <div className={styles.fulldiv}>
              <div style={{ boxShadow: "none", paddingBottom: "0%" }}>
                <div
                  className={styles.recentorders}
                  style={{
                    width: "auto",
                    position: "inherit",
                    margin: "inherit",
                    transform: "unset",
                  }}
                >
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <h2>Itinerario</h2>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th>Titulo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              id="tituloItinerario"
                              placeholder="Titulo"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "1.5%",
                                marginLeft: "-1%",
                                width: "100%",
                              }}
                              value={paquete?.tituloItinerario}
                              onChange={(e) =>
                                setPaquete({
                                  ...paquete,
                                  tituloItinerario: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th>Titulo del día</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              id="tituloDia"
                              placeholder="Titulo del día"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "1.5%",
                                marginLeft: "-1%",
                                width: "100%",
                              }}
                              value={dia?.titulo}
                              onChange={(e) =>
                                setDia({
                                  ...dia,
                                  titulo: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Descripcion</th>
                        </tr>
                        <tr>
                          <td>
                            <textarea
                              id="descripcionDia"
                              placeholder="Descripcion del día"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "1.5%",
                                marginLeft: "-1%",
                                width: "100%",
                                minHeight: "10rem",
                              }}
                              value={dia?.descripcion}
                              onChange={(e) =>
                                setDia({
                                  ...dia,
                                  descripcion: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <br></br>

            <div style={{ width: "-webkit-fill-available" }}>
              <div
                style={{
                  width: "95%",
                  marginLeft: "1.5%",
                }}
              >
                <DropZone
                  label="Tamaño recomendado 900px * 600px"
                  saveFile={async (file) => {
                    setFile(file);
                    var reader = new FileReader();
                    var url = reader.readAsDataURL(file);
                  }}
                />
              </div>
            </div>
            {file ? (
              <>
                <div
                  style={
                    matches
                      ? { textAlign: "center" }
                      : { textAlign: "center", maxWidth: "23rem" }
                  }
                >
                  <img
                    src={URL.createObjectURL(file)}
                    style={{
                      height: 300,
                      objectFit: "contain",
                      width: "-webkit-fill-available",
                    }}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    style={{
                      width: "-webkit-fill-available",
                    }}
                  >
                    {file.name}
                  </Typography>
                </div>
              </>
            ) : null}
            <br />
            <div
              className={styles.recentorders}
              style={{
                width: "auto",
                position: "inherit",
                margin: "inherit",
                transform: "unset",
              }}
            >
              <div style={{ overflowX: "auto" }}>
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            limpiarItinerario();
                          }}
                          style={{ margin: "5px" }}
                        >
                          Limpiar
                        </Button>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => saveDay()}
                          style={{ margin: "5px" }}
                        >
                          Guardar
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/**Cards Dias json */}
            <div className={styles.fulldiv}>
              <div
                style={{
                  overflow: "auto",
                  display: "-webkit-box",
                  boxShadow: "none",
                  paddingBottom: "0%",
                }}
              >
                {diasArray.map((record, index) => {
                  return (
                    <>
                      <div
                        id="row__posterLarge"
                        style={{ padding: "10px", marginBottom: "10px" }}
                      >
                        <CardDay
                          id={record.id}
                          titulo={record.titulo}
                          descripcion={record.descripcion}
                          url={record.url}
                          existe={record.imagen}
                          eliminarActual={() => eliminarDay(record.id)}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {/* END itinerario  */}

            {/* PAQUETES USUARIO  */}
            <div className={styles.fulldiv}>
              <div
                style={{
                  boxShadow: "none",
                  paddingBottom: "0%",
                  marginRight: "0%",
                }}
              >
                <div
                  className={styles.recentorders}
                  style={{
                    width: "auto",
                    position: "inherit",
                    margin: "inherit",
                    transform: "unset",
                    display: "grid",
                  }}
                >
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <h2>Paquetes</h2>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th>Cantidad de personas</th>
                          <th>Tipo</th>
                          <th>Precio</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              id="cantidadPersonasU"
                              placeholder="Cantidad"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "3%",
                                marginLeft: "-1%",
                                width: "100%",
                              }}
                              value={paquetePrecioU.cant}
                              onChange={(e) =>
                                setpaquetePrecioU({
                                  ...paquetePrecioU,
                                  cant: e.target.value,
                                })
                              }
                              type="number"
                            />
                          </td>
                          <td>
                            <select
                              id="tipoPersonasU"
                              name="categoria"
                              onChange={(e) => {
                                setpaquetePrecioU({
                                  ...paquetePrecioU,
                                  tipo: e.target.value,
                                });
                              }}
                              value={paquetePrecioU.tipo}
                              style={{
                                color: "#7d8da1",
                                marginLeft: "2%",
                                borderWidth: "0px",
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "1.5%",
                                paddingTop: "5%",
                                paddingBottom: "5%",
                                marginLeft: "-1%",
                                width: "95%",
                                minWidth: "6rem",
                              }}
                            >
                              <option disabled="disabled" selected="selected">
                                Selecciona una
                              </option>
                              <option value="Adulto">Adulto</option>
                              <option value="Niño">Niño</option>
                              <option value="Extra">Extra</option>
                            </select>
                          </td>
                          <td>
                            <input
                              id="precioPersonasU"
                              placeholder="Precio"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "3%",
                                marginLeft: "-1%",
                                width: "100%",
                                minWidth: "6rem",
                              }}
                              value={paquetePrecioU.precio}
                              onChange={(e) =>
                                setpaquetePrecioU({
                                  ...paquetePrecioU,
                                  precio: e.target.value,
                                })
                              }
                              type="number"
                            />
                          </td>
                          <td>
                            {/* <Button
                              variant="outlined"
                              color="error"
                              onClick={() => console.log("Limpiando")}
                            >
                              Limpiar
                            </Button> */}
                          </td>
                          <td>
                            <Button
                              id="guardarUsuario"
                              variant="outlined"
                              color="success"
                              onClick={(userType) =>
                                savePaquetePrecioU("Usuario")
                              }
                            >
                              Guardar
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className={styles.fulldiv}>
              <div
                style={{
                  overflow: "auto",
                  display: "-webkit-box",
                  boxShadow: "none",
                  marginTop: "-5%",
                }}
              >
                {paquetePrecioArrayU.map((record, index) => {
                  return (
                    <>
                      <div
                        id="row__posterLarge"
                        style={{ padding: "10px", marginBottom: "10px" }}
                      >
                        {/* {record} */}
                        <CardPaquetePrecio
                          id={record.id}
                          cantidad={record.cantidad}
                          tipo={record.tipo}
                          precio={record.precio}
                          user={"Usuario"}
                          eliminarActual={() =>
                            eliminarPaqueteUsuario(record.id)
                          }
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <br />
            {/* END paquetes usuario */}

            {/* PAQUETES EMPRESA 
            <div className={styles.fulldiv}>
              <div
                style={{
                  boxShadow: "none",
                  paddingBottom: "0%",
                  paddingTop: "0%",
                  marginTop: "-3%",
                }}
              >
                <div className={styles.recentorders}>
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <h3>Empresa</h3>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th>Cantidad de personas</th>
                          <th>Tipo</th>
                          <th>Precio</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              id="cantidadPersonasE"
                              placeholder="Cantidad"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "3%",
                                marginLeft: "-1%",
                                width: "100%",
                              }}
                              value={paquetePrecioE.cant}
                              onChange={(e) =>
                                setpaquetePrecioE({
                                  ...paquetePrecioE,
                                  cant: e.target.value,
                                })
                              }
                            />
                          </td>
                          <td>
                            <select
                              id="tipoPersonasE"
                              name="categoria"
                              onChange={(e) => {
                                setpaquetePrecioE({
                                  ...paquetePrecioE,
                                  tipo: e.target.value,
                                });
                              }}
                              value={paquetePrecioE.tipo}
                              style={{
                                color: "#7d8da1",
                                marginLeft: "2%",
                                borderWidth: "0px",
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "1.5%",
                                paddingTop: "5%",
                                paddingBottom: "5%",
                                marginLeft: "-1%",
                                width: "95%",
                              }}
                            >
                              <option disabled="disabled" selected="selected">
                                Selecciona una
                              </option>
                              <option value="Adulto">Adulto</option>
                              <option value="Niño">Niño</option>
                              <option value="Extra">Extra</option>
                            </select>
                          </td>
                          <td>
                            <input
                              id="precioPersonasE"
                              placeholder="Precio"
                              style={{
                                width: "-webkit-fill-available",
                                backgroundColor: "#dce1eb",
                                padding: "3%",
                                marginLeft: "-1%",
                                width: "100%",
                              }}
                              value={paquetePrecioE.precio}
                              onChange={(e) =>
                                setpaquetePrecioE({
                                  ...paquetePrecioE,
                                  precio: e.target.value,
                                })
                              }
                            />
                          </td>
                          <td>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => console.log("Limpiando")}
                            >
                              Limpiar
                            </Button>
                          </td>
                          <td>
                            <Button
                              id="guardarEmpresa"
                              variant="outlined"
                              color="success"
                              onClick={(userType) =>
                                savePaquetePrecioE("Empresa")
                              }
                            >
                              Guardar
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.fulldiv}>
              <div
                style={{
                  overflow: "auto",
                  display: "-webkit-box",
                  boxShadow: "none",
                  marginTop: "-3%",
                }}
              >
                {paquetePrecioArrayE.map((record, index) => {
                  return (
                    <>
                      <div
                        id="row__posterLarge"
                        style={{ padding: "10px", marginBottom: "10px" }}
                      >
                        {record}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <br />*/}
            {/* END paquetes empresa */}
            {/* UBICACION */}
            <div>
              <div
                style={{
                  boxShadow: "none",
                  paddingBottom: "0%",
                  marginTop: "-5%",
                  marginRight: "0%",
                  marginLeft: "2%",
                }}
              >
                <div
                  className={styles.recentorders}
                  style={{
                    width: "auto",
                    position: "inherit",
                    margin: "inherit",
                    transform: "unset",
                  }}
                >
                  <div style={{ overflowX: "auto" }}>
                    <table
                      id="recent-orders--table"
                      style={{ boxShadow: "initial", textAlign: "justify" }}
                    >
                      <thead>
                        <tr>
                          <td>
                            <h2>Ubicación</h2>
                            <h3>Selecionar</h3>
                          </td>
                          <td>{""}</td>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td style={{ width: "40%" }}>
                            <Autocomplete
                              value={ubicacion.ciudad}
                              id="country-select-demo"
                              options={ubicacion}
                              autoHighlight
                              getOptionLabel={(option) => option.ciudad}
                              onChange={(e, v) => {
                                addCiudad(v.id, v.ciudad);
                              }}
                              style={{
                                color: "#7d8da1",
                                borderWidth: "0px",
                                backgroundColor: "#dce1eb",
                                width: "100%",
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.ciudad}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  style={{
                                    color: "#7d8da1",
                                    backgroundColor: "yellow !important",
                                  }}
                                  {...params}
                                  label={paquete.ciudad}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* END paquetes  */}
            <br />
            <div
              className={styles.recentorders}
              style={{
                width: "auto",
                position: "inherit",
                margin: "inherit",
                transform: "unset",
              }}
            >
              <div style={{ overflowX: "auto" }}>
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => navigate("/paquete")}
                          style={{ margin: "5px" }}
                        >
                          Regresar
                        </Button>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => updatePaquete()}
                          style={{ margin: "5px" }}
                        >
                          EDITAR PAQUETE
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CrearPaquete;
