import React, { useEffect, useState } from "react";
import Titulo from "./Titulo";
import Pagination from "./Pagination";
import ItemBlog from "./ItemBlog";
import BlogCard from "./BlogCard";

import CategoriaBlogService from "../../../services/CategoriaBlog.service";
import NoticiaService from "../../../services/Blog.service";
import BlogTabs from "./BlogTabs";
import BlogCategorias from "./BlogCategorias";
import BlogTabsMovil from "./BlogTabsMovil";
import { TablePagination } from "@mui/material";

const BlogGrid = () => {
  const [loading, setLoading] = useState(true);
  const [Entradas, setEntradas] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(0);
  const [precio, setPrecio] = useState("normal");
  const [categorias, setCategorias] = useState([]);
  const [tag, setTag] = useState(false);
  const [noticias, setNoticias] = useState([]);
  const [tabUno, setTabUno] = useState("active");
  const [tabDos, setTabDos] = useState("");
  const [tabUnoCuerpo, setTabUnoCuerpo] = useState("show active");
  const [tabDosCuerpo, setTabDosCuerpo] = useState("");
  const [buscar, setBuscar] = useState("");

  useEffect(() => {
    getEntradas();
    getCategorias();
    getNoticias();
  }, [page, limit]);

  const getEntradas = async () => {
    setLoading(true);
    setEntradas([]);
    try {
      const data = await NoticiaService.list(limit, page * limit);
      setEntradas(data.data);
      setTotal(data.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getEntradasFiltroCategoria = async (filtro) => {
    setLoading(true);
    setEntradas([]);
    try {
      console.log("el filtro es:" + filtro);
      const data = await NoticiaService.filtroCategoria(
        filtro,
        limit,
        page * limit
      );
      console.log("total: " + data.total);
      setEntradas(data.data);
      setTotal(data.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      console.log(Entradas);
    }
  };

  const getEntradasFiltroBuscar = async () => {
    setLoading(true);
    setEntradas([]);
    try {
      if (buscar == "") {
        const data = await NoticiaService.list(limit, page * limit);
        setEntradas(data.data);
        setTotal(data.total);
      } else {
        console.log("el texto del filtro es:" + buscar);
        const data = await NoticiaService.filtroBuscar(buscar);
        console.log("total: " + data.total);
        setEntradas(data.data);
        setTotal(data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      console.log(Entradas);
    }
  };

  const getCategorias = async () => {
    setLoading(true);
    try {
      const data = await CategoriaBlogService.getAll();
      console.log("get");
      console.log(data);
      setCategorias(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      console.log("NOTICIIAAS" + data.data);
      setNoticias(data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div className="main-wrapper blog-grid-three-col">
        <Titulo />
        <section className="py-9 py-md-10">
          <div className="containerPublic">
            <div className="row">
              {/* Izquierda */}
              <>
                {/* Escritorio */}
                <div className="col-lg-4 col-xl-3">
                  <div className="mb-md-6 mb-lg-0">
                    <div className="row">
                      <div className="col-md-6 col-lg-12">
                        {/*BUSCAR*/}
                        <div className="bg-smoke border border-light-gray rounded p-3 mb-4">
                          <h3 className="mb-4">Buscar</h3>
                          <div className="input-group input-group-sm">
                            <input
                              type="text"
                              className="form-control form-control-sm border-0"
                              required=""
                              placeholder=""
                              value={buscar}
                              onChange={(e) => {
                                setBuscar(e.target.value.toUpperCase());
                              }}
                              onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                  getEntradasFiltroBuscar();
                                }
                              }}
                            />
                            <div className="input-group-append">
                              <button
                                className="input-group-text border-0 btn bg-primary rounded"
                                onClick={() => {
                                  getEntradasFiltroBuscar();
                                }}
                              >
                                <i
                                  className="fa fa-search text-white"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        {/*PESTAÑAS*/}
                        <BlogTabs />
                      </div>
                      {/*Categorias*/}
                      <div className="col-md-6 col-lg-12 d-none d-md-block">
                        <div className="bg-smoke border border-light-gray rounded p-3 mb-4">
                          <h3 className="mb-4">Categorias</h3>
                          <ul className="list-unstyled list-group list-group-flush">
                            <li className="list-group-item bg-transparent border-off-white border-top-0 p-0">
                              <a
                                className="d-flex text-dark hover-text-primary py-3"
                                href="javascript:void(0)"
                                onClick={() => {
                                  getEntradas();
                                  setTag(true);
                                }}
                              >
                                <span>Todo</span>
                              </a>
                            </li>
                            {categorias.map((record, index) => {
                              return (
                                <li className="list-group-item bg-transparent border-off-white border-top-0 p-0">
                                  <a
                                    className="d-flex text-dark hover-text-primary py-3"
                                    href="javascript:void(0)"
                                    onClick={() => {
                                      setTag(true);
                                      getEntradasFiltroCategoria(record.tipo);
                                    }}
                                  >
                                    <span>{record.tipo}</span>
                                    <small className="ml-auto font-weight-bold">
                                      {"(" + record.cantidad + ")"}
                                    </small>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      {/* Categorias movil 
                  <div className="col-md-6 col-lg-12 d-none d-md-block bg-smoke border border-light-gray rounded p-3 mb-4">
                    <h3 className="mb-4">Categorias</h3>
                    <ul className="list-unstyled list-group list-group-flush">
                    <a
                                className="d-flex text-dark hover-text-primary py-3"
                                onClick={() => {
                                  getEntradas();
                                  setTag(true);
                                }}
                              >
                                <span>Todo</span>
                              </a>
                      {categorias.map((record, index) => {
                        return (
                          <li className="list-group-item bg-transparent border-off-white border-top-0 p-0">
                            <a
                              href=""
                              className="d-flex text-dark hover-text-primary py-3"
                              onClick={() => {
                                setTag(true);
                                getEntradasFiltroCategoria(record.tipo);
                              }}
                            >
                              <span>{record.tipo}</span>
                              <small className="ml-auto font-weight-bold">
                                {"(" + record.cantidad + ")"}
                              </small>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>*/}
                    </div>
                  </div>
                </div>

                {/* Movil */}
                <div className="d-md-none">
                  {/*  Tabs movil */}
                  <div className="mb-4">
                    <BlogTabsMovil />
                  </div>

                  {/* Categorias movil */}
                  <div className="bg-smoke border border-light-gray rounded p-3 mb-4">
                    <h3 className="mb-4">Categorias</h3>
                    <ul className="list-unstyled list-group list-group-flush">
                      <a
                        className="d-flex text-dark hover-text-primary py-3"
                        href="javascript:void(0)"
                        onClick={() => {
                          getEntradas();
                          setTag(true);
                        }}
                      >
                        <span>Todo</span>
                      </a>
                      {categorias.map((record, index) => {
                        return (
                          <li className="list-group-item bg-transparent border-off-white border-top-0 p-0">
                            <a
                              href="javascript:void(0)"
                              className="d-flex text-dark hover-text-primary py-3"
                              onClick={() => {
                                setTag(true);
                                getEntradasFiltroCategoria(record.tipo);
                              }}
                            >
                              <span>{record.tipo}</span>
                              <small className="ml-auto font-weight-bold">
                                {"(" + record.cantidad + ")"}
                              </small>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </>
              {/* Derecha */}
              <>
                <div className="col-lg-8 col-xl-9 order-1">
                  {Entradas.map((record, index) => {
                    return (
                      <ItemBlog
                        id={record.id}
                        titulo={record.titulo}
                        idAutor={record.usuarioId}
                        fecha={record.createdAt}
                        categoria={record.categoria}
                        imagenes={record.imagen}
                        cuerpo={record.cuerpoNoticia}
                      />
                    );
                  })}

                  {/**Pagination */}
                  <>
                    {/**Escritorio */}
                    <div className="d-none d-md-block">
                      <div style={{ width: "100%" }}>
                        <div>
                          <TablePagination
                            style={{
                              color: "var(--color-dark-variant)",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                            component="div"
                            classes="recent-orders"
                            labelRowsPerPage="Items por página"
                            //  rowsPerPageOptions={[]}
                            count={total}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={limit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      </div>
                    </div>

                    {/**Movil */}
                    <div className="d-md-none">
                      <div className="mb-4">
                        <TablePagination
                          style={{
                            color: "var(--color-dark-variant)",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                          component="div"
                          classes="recent-orders"
                          labelRowsPerPage="Items por página"
                          //  rowsPerPageOptions={[]}
                          count={total}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={limit}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    </div>
                  </>
                </div>
              </>
            </div>
          </div>
        </section>
        <br />
        <br />
      </div>
    </>
  );
};

export default BlogGrid;
