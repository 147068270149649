import Drawer from "@mui/material/Drawer";
import { Fragment, useState } from "react";
import { Button } from "@mui/material";
import FormAgenda from "./FormAgenda";
import ListaReservaciones from "./ListaReservaciones";
import useMediaQuery from "@mui/material/useMediaQuery";

const ReservacionModal = ({ status, handleStatus, fechaData, onSumit }) => {
  const [changes, setChanges] = useState(0);
  const matches = useMediaQuery("(max-width:1000px)");
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (changes > 0) {
      onSumit();
    }

    handleStatus(open);
  };

  const onChange = () => {
    setChanges((prev) => prev + 1);
  };

  const onClose = (newState) => {
    if (changes > 0 || newState) {
      onSumit();
    }
    handleStatus(false);
  };

  return (
    <div>
      <Fragment key={"right"}>
        <Drawer anchor={"right"} open={status} onClose={toggleDrawer(false)}>
          {matches && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => onClose()}
              style={{
                margin: "5px",
                position: "absolute",
                right: "0",
                backgroundColor: "white",
              }}
            >
              Regresar
            </Button>
          )}
          {status ? (
            fechaData.dayType === "single.free" ? (
              <FormAgenda
                handleStatus={onClose}
                fechaData={fechaData}
                onSumit={onChange}
              />
            ) : (
              <ListaReservaciones
                handleStatus={onClose}
                fechaData={fechaData}
                onSumit={onChange}
              />
            )
          ) : null}
        </Drawer>
      </Fragment>
    </div>
  );
};

export default ReservacionModal;
