import Switch from "@mui/material/Switch";
import PaqueteService from "../../../services/Hoteles.service";

const FormDisponible = ({ paquete, onSumit }) => {
  const handleChange = async () => {
    try {
      const dataPaquete = {
        id: paquete.id,
        disponible: !paquete.disponible,
      };
      const actualizacionProducto = await PaqueteService.update(dataPaquete);
      console.log(actualizacionProducto);
      paquete.disponible = !paquete.disponible;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Switch
        checked={paquete.disponible}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </div>
  );
};

export default FormDisponible;
