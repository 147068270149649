import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CrearListaModal from "./CrearListaModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useState } from "react";

const Amenidades = ({ amenidades, handleAmenidades }) => {
  const [editAmenidad, setEditAmenidad] = useState(null);
  const addAmenidad = (newAmenidad) => {
    let oldAmenidades = amenidades.slice();
    oldAmenidades.push(newAmenidad);
    handleAmenidades(oldAmenidades);
  };
  const updateAmenidad = (newAmenidad) => {
    if (!newAmenidad) {
      setEditAmenidad(null);
      return;
    }
    let oldAmenidades = amenidades.map((a, indexc) => {
      if (indexc !== newAmenidad.index) {
        return a;
      }
      let oldAmenidad = { ...a };
      oldAmenidad.icon = newAmenidad.icon;
      oldAmenidad.label = newAmenidad.label;
      oldAmenidad.options = newAmenidad.options;
      return oldAmenidad;
    });

    handleAmenidades(oldAmenidades);
    setEditAmenidad(null);
  };

  const deleteAmenidad = (index) => {
    let oldAmenidades = amenidades.filter((c, indexc) => indexc !== index);

    handleAmenidades(oldAmenidades);
  };

  const onEditAmenidad = (index) => {
    setEditAmenidad({ ...amenidades[index], index: index });
  };

  return (
    <>
      {amenidades.map((amenidad, index) => (
        <div key={index} style={{ minWidth: "20rem", padding: "0.5rem" }}>
          <h3 style={{ display: "flex", alignItems: "center" }}>
            {amenidad.label}
          </h3>
          <ul
            id="transition-modal-description"
            style={{ marginTop: 16, paddingLeft: "0.6em" }}
          >
            {amenidad.options.map((option, index) => (
              <li key={index} style={{ display: "flex", alignItems: "center" }}>
                <FiberManualRecordIcon sx={{ fontSize: 6 }} />{" "}
                <span style={{ paddingInline: "0.5rem" }}>{option.text}</span>
              </li>
            ))}
            <li>
              <IconButton
                aria-label="delete"
                onClick={() => deleteAmenidad(index)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => onEditAmenidad(index)}
              >
                <EditIcon />
              </IconButton>
            </li>
          </ul>
        </div>
      ))}
      <CrearListaModal
        amenidad={editAmenidad}
        crearAmenidad={addAmenidad}
        updateAmenidad={updateAmenidad}
      />
    </>
  );
};

export default Amenidades;
