import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

import PaqueteService from "../../../services/Paquete.service";
import HotelesService from "../../../services/Hoteles.service";

import ItemPaquete from "./ItemPaquete";

const MejoresOfertas = ({ mejoresOfertas }) => {
  const [paquetes, setPaquetes] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [tipo, setTipo] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getPaquetes();
    window.scrollTo(0, 0);
  }, []);

  const getPaquetes = async () => {
    setLoading(true);
    try {
      let ofertasList = [];
      const paquetes = await PaqueteService.mejoresOfertas(3);
      const hoteles = await HotelesService.mejoresOfertas(3);
      console.log(paquetes);
      ofertasList = unirArrays(paquetes.data, hoteles.data);
      console.log(ofertasList);
      setPaquetes(ofertasList);
      if (currentUser != undefined && currentUser.tipo == "Empresa") {
        setTipo("Empresa");
      } else {
        setTipo("Usuario");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const unirArrays = (arr1, arr2) => {
    // Obtenemos el tamaño del array más pequeño
    let min = Math.min(arr1.length, arr2.length);
    // Creamos un array vacío para guardar el resultado
    let resultado = [];
    // Recorremos los arrays hasta el tamaño mínimo
    for (let i = 0; i < min; i++) {
      // Obtenemos un subarray de cada array con el elemento en la posición i
      let subarr1 = arr1.slice(i, i + 1);
      let subarr2 = arr2.slice(i, i + 1);
      // Usamos el operador spread para combinar los subarrays en el resultado
      resultado.push(...subarr1, ...subarr2);
    }
    // Si alguno de los arrays tiene elementos sobrantes, los añadimos al final del resultado
    if (arr1.length > min) {
      resultado.push(...arr1.slice(min));
    }
    if (arr2.length > min) {
      resultado.push(...arr2.slice(min));
    }
    // Devolvemos el array resultante
    return resultado;
  };

  return (
    <section class="py-9 py-md-10 bg-white">
      <div class="containerPublic">
        <div class="text-center section-title2">
          <h2 class="text-uppercase font-weight-bold position-relative">
            <span class="bg-white">Mejores ofertas</span>
          </h2>
          <p
            class="text-center"
            dangerouslySetInnerHTML={{
              __html: mejoresOfertas,
            }}
          ></p>
        </div>

        <div class="row justify-content-center" style={{ padding: "1rem" }}>
          {paquetes.map((record, index) => {
            return <ItemPaquete key={index} item={record} tipo={tipo} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default MejoresOfertas;
