import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import MyGreatPlaceWithControllableHover from "./MyGreatPlaceWithControllableHover ";
import { K_SIZE } from "./my_great_place_with_controllable_hover_styles.js";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function MapGoogle({ handleDirection, positionApproximate }) {
  const google_apiKey = process.env.REACT_APP_GOOGLE_KEY;
  const geoapify_apiKey = process.env.REACT_APP_GEOAPIFY_KEY;
  const [ubicacion, setUbicacion] = useState(
    positionApproximate && positionApproximate[0] !== 0
      ? positionApproximate
      : [17.052747, -96.72233]
  );
  const [zoom, setZoom] = useState(11);
  const [hoverItem, setHoverItem] = useState(null);
  const [greatPlaces, setGreatPlaces] = useState(
    positionApproximate && positionApproximate[0] !== 0
      ? [
          {
            id: "ubicacion",
            lat: positionApproximate[0],
            lng: positionApproximate[1],
            text: "🚩",
          },
          // {
          //   id: "ubicacion",
          //   geometry: {
          //     location: {
          //       lat: positionApproximate[0],
          //       lng: positionApproximate[1],
          //     },
          //   },
          //   name: "🚩",
          // },
        ]
      : [
          // { id: "A", lat: 17.052747, lng: -96.72233, text: "A" },
          // { id: "B", lat: 17.0532571, lng: -96.7214101, text: "B" },
        ]
  );
  const defaultProps = {
    defaultCenter: {
      lat: 17.052747,
      lng: -96.72233,
    },
    zoom: 11,
  };

  const places = greatPlaces.map((place) => {
    return (
      <MyGreatPlaceWithControllableHover
        key={place.id}
        lat={place.lat}
        lng={place.lng}
        text={place.text}
        // use your hover state (from store, react-controllables etc...)
        hover={hoverItem === place.id ? true : false}
      />
    );
  });

  const _onChange = ({ center, zoom, bounds, marginBounds }) => {
    // this.props.onCenterChange(center);
    // this.props.onZoomChange(zoom);
    // console.log(center);
    // console.log(zoom);
  };

  const _onChildClick = (key, childProps) => {
    // this.props.onCenterChange([childProps.lat, childProps.lng]);
    setUbicacion([childProps.lat, childProps.lng]);
    setZoom(15);
  };

  const _onClick = async ({ x, y, lat, lng, event }) => {
    // this.props.onCenterChange([childProps.lat, childProps.lng]);
    setUbicacion([lat, lng]);
    setZoom(15);
    // setGreatPlaces([
    //   ...greatPlaces,
    //   { id: `${lat},${lng}`, lat: lat, lng: lng, text: `xy` },
    // ]);
    setGreatPlaces([{ id: `${lat},${lng}`, lat: lat, lng: lng, text: "🚩" }]);
    // geocode google
    // let requestOptions = {
    //   method: "GET",
    // };

    // const results = await fetch(
    //   `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${google_apiKey}`,
    //   requestOptions
    // ).then((response) => response.json());
    // console.log(results);
    //end geocode  google

    //geocode geoapify
    let requestOptions = {
      method: "GET",
    };

    const results = await fetch(
      `https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${lng}&apiKey=${geoapify_apiKey}`,
      requestOptions
    ).then((response) => response.json());

    console.log(results.features[0]);
    handleDirection({
      ciudad: `${
        results.features[0].properties.city
          ? `${results.features[0].properties.city}, `
          : ""
      }${
        results.features[0].properties.county
          ? `${results.features[0].properties.county}, `
          : ""
      }${
        results.features[0].properties.state
          ? results.features[0].properties.state
          : ""
      }`,
      pais: results.features[0].properties.country,
      continente: results.features[0].properties.timezone.name.split("/")[0],
      lat: lat,
      lng: lng,
    });
    //end geocoade geoapify
  };

  const _onChildMouseEnter = (key /*, childProps */) => {
    // this.props.onHoverKeyChange(key);
    // console.log(key);
    setHoverItem(key);
  };

  const _onChildMouseLeave = (/* key, childProps */) => {
    // this.props.onHoverKeyChange(null);
    setHoverItem(null);
  };

  // Return map bounds based on list of places
  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
      bounds.extend(
        new maps.LatLng(
          place.geometry.location.lat,
          place.geometry.location.lng
        )
      );
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, places) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: google_apiKey }}
        defaultCenter={defaultProps.defaultCenter}
        defaultZoom={defaultProps.zoom}
        center={ubicacion}
        zoom={zoom}
        hoverDistance={K_SIZE / 2}
        onChange={_onChange}
        onChildClick={_onChildClick}
        onChildMouseEnter={_onChildMouseEnter}
        onChildMouseLeave={_onChildMouseLeave}
        onClick={_onClick}
        // yesIWantToUseGoogleMapApiInternals
        // onGoogleApiLoaded={({ map, maps }) =>
        //   apiIsLoaded(map, maps, greatPlaces)
        // }
      >
        {places}
      </GoogleMapReact>
    </div>
  );
}
