import React, { useEffect, useState, useContext } from "react";

//servicios
import PaqueteService from "../../../services/Paquete.service";
import { AuthContext } from "../../../context/AuthContext";

//componentes
import NuestroPaqueteItem from "./NuestroPaqueteItem";

const NuestrosPaquetes = ({ nuestrosPaquetes }) => {
  const [paquetes, setPaquetes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(12);
  const [tipo, setTipo] = useState(0);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    getPaquetes();
    window.scrollTo(0, 0);
  }, []);

  const getPaquetes = async () => {
    setLoading(true);
    try {
      const data = await PaqueteService.mostrarInicio(6);
      console.log("/////00" + data.data);
      setPaquetes(data.data);
      if (data.data.total < 6) {
        let limite = 6 - data.total;
        console.log("----" + limite);
        const dataDESC = await PaqueteService.list(limite, 0);
        setPaquetes({ ...paquetes, dataDESC });
      }
      if (currentUser != undefined && currentUser.tipo == "Empresa") {
        setTipo("Empresa");
      } else {
        setTipo("Usuario");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="py-10" style={{ backgroundColor: "white" }}>
      <div className="containerPublic">
        <div className="text-center section-title">
          <h2 className="text-uppercase font-weight-bold position-relative">
            <span className="bg-white"> Nuestros paquetes </span>
          </h2>
          <p className="text-center">
            <p
              dangerouslySetInnerHTML={{
                __html: nuestrosPaquetes,
              }}
            ></p>
          </p>
        </div>

        <div className="row grid">
          {paquetes.map((record, index) => {
            return (
              <NuestroPaqueteItem
                key={index}
                id={record.id}
                titulo={record.titulo}
                precioU={record.paquetesUsuario}
                precioE={record.paquetesEmpresa}
                descuento={record.descuento}
                imagenes={record.imagenes[0]}
                fecha={record.updateAt}
                tipo={tipo}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default NuestrosPaquetes;
