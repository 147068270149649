/* React */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DropZone from "../../utils/DropZone";

/* Servicios */
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

import styles from "../layout.module.css"

const EliminarUsuario = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState({});
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await UserService.getById(id);
      if (data.imagen) {
        const imagen = await S3Service.get(data.imagen);
        setImagen(imagen.result);
      }
      setUsuario(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteUsuario = async () => {
    setLoading(true);
    try {
      const noticiaBorrada = await UserService.remove(id);
      toast.info("Usuario eliminado");
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      navigate("/usuario");
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.fulldiv}>
          <div>
            <h1>Eliminar usuario</h1>
            <div className={styles.recentorders}>
              <table
                id="recent-orders--table"
                style={{ boxShadow: "initial", textAlign: "justify" }}
              >
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Apellido paterno</th>
                    <th>Apellido materno</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        placeholder="Nombre"
                        style={{ width: "-webkit-fill-available" }}
                        value={usuario?.nombre}
                        onChange={(e) =>
                          setUsuario({ ...usuario, nombre: e.target.value })
                        }
                      />
                    </td>
                    <td>
                      <input
                        placeholder="Apellido paterno"
                        style={{ width: "-webkit-fill-available" }}
                        value={usuario?.apellidoPaterno}
                        onChange={(e) =>
                          setUsuario({
                            ...usuario,
                            apellidoPaterno: e.target.value,
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        placeholder="Apellido materno"
                        style={{ width: "-webkit-fill-available" }}
                        value={usuario?.apellidoMaterno}
                        onChange={(e) =>
                          setUsuario({
                            ...usuario,
                            apellidoMaterno: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
                <tr>
                  <th>Número telefónico</th>
                  <th>Correo</th>
                  <th>Contraseña</th>
                </tr>
                <tbody>
                  <tr>
                    <td>
                      <input
                        onChange={(e) =>
                          setUsuario({
                            ...usuario,
                            telefono: e.target.value,
                          })
                        }
                        placeholder="Usuario"
                        value={usuario.telefono}
                        style={{ width: "-webkit-fill-available" }}
                      />
                    </td>
                    <td>
                      <input
                        onChange={(e) =>
                          setUsuario({ ...usuario, correo: e.target.value })
                        }
                        value={usuario.correo}
                        placeholder="Correo"
                        style={{ width: "-webkit-fill-available" }}
                      />
                    </td>
                    <td>
                      <input
                        onChange={(e) =>
                          setUsuario({
                            ...usuario,
                            contraseña: e.target.value,
                          })
                        }
                        placeholder="Contraseña"
                        value={usuario.contraseña}
                        type="password"
                        style={{ width: "-webkit-fill-available" }}
                      />
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th>Edad</th>
                    <th>Tipo</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        onChange={(e) =>
                          setUsuario({
                            ...usuario,
                            edad: e.target.value,
                          })
                        }
                        value={usuario.edad}
                        placeholder="Edad"
                        type="number"
                      />
                    </td>
                    <td>
                      <select
                        id="inlineFormCustomSelect"
                        name="categoria"
                        style={{ width: "-webkit-fill-available" }}
                        onChange={(e) => {
                          setUsuario({
                            ...usuario,
                            tipo: e.target.value,
                          });
                        }}
                      >
                        <option disabled="disabled" selected="selected">
                          Seleccione uno
                        </option>
                        <option value="Usuario">Usuario</option>
                        <option value="Empresa">Empresa</option>
                        <option value="Administrador">Administrador</option>
                      </select>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              {/* Botones */}
              <table
                id="recent-orders--table"
                style={{ boxShadow: "initial", textAlign: "justify" }}
              >
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <Button color="error" onClick={() => navigate("/usuario")}>
                      Cancelar
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="outlined"
                      color="error"
                      form="form1"
                      onClick={() => deleteUsuario()}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EliminarUsuario;
