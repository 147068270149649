/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import TextField from "@mui/material/TextField";

/* Servicios */
import FooterService from "../../../services/Footer.service";

import styles from "../layout.module.css";
const TablaFooter = () => {
  const [footer, setFooter] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [contenido, setContenido] = useState({});
  const myTheme = createTheme({});

  useEffect(() => {
    getFooter();
  }, []);

  const getFooter = async () => {
    setLoading(true);
    try {
      const cont = await FooterService.getAll();
      setFooter(cont[0]);
      const contentHTML = convertFromHTML(cont[0].Texto);

      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      const content = JSON.stringify(convertToRaw(state));
      setContenido(content);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateFooter = async () => {
    if (!footer.Texto) {
      toast.info("Error, tienes que agregar texto");
    } else if (!footer.Contactanos) {
      toast.info("Error, tienes que colocar un texto en contactanos");
    } else if (!footer.Telefono) {
      toast.info("Error, tienes que colocar un número de telefono");
    } else if (!footer.Direccion) {
      toast.info("Error, tienes que colocar una dirección");
    } else if (!footer.Correo) {
      toast.info("Error, tienes que colocar un correo");
    } else if (!footer.RedesSocialesTexto) {
      toast.info("Error, tienes que colocar un texto en Redes Sociales");
    } else {
      setLoading(true);
      try {
        const dataFooter = footer;
        const updateFooter = await FooterService.update(dataFooter);
        toast.success("Contactanos actualizado con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getFooter();
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className={styles.recentorders}
          style={{
            width: "auto",
            position: "inherit",
            margin: "inherit",
            transform: "unset",
            display: "grid",
            marginTop: "2rem",
          }}
        >
          <div
            style={{
              boxShadow: "var(--box-shadow)",
              backgroundColor: "var(--color-white)",
              overflow: "auto",
            }}
          >
            <div className={styles.fulldiv}>
              <div
                className={styles.recentorders}
                style={{
                  width: "auto",
                  position: "inherit",
                  margin: "inherit",
                  transform: "unset",
                }}
              >
                <table
                  id="recent-orders--table"
                  style={{ boxShadow: "initial", textAlign: "justify" }}
                >
                  <thead>
                    <tr>
                      <h1 style={{ marginRight: "20px", marginBottom: "20px" }}>
                        FOOTER
                      </h1>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>STAR TRAVEL</td>
                      <td style={{ fontWeight: "bold" }}>CONTACTANOS</td>
                      <td style={{ fontWeight: "bold" }}>REDES SOCIALES</td>
                    </tr>
                  </thead>
                  <tbody>
                    <th style={{ width: "350px" }}>
                      <tr style={{ width: "350px" }}>
                        <TextField
                          id="filled-multiline-static"
                          multiline
                          rows={20}
                          defaultValue={footer.Texto}
                          variant="filled"
                          onChange={(e) =>
                            setFooter({
                              ...footer,
                              Texto: e.target.value,
                            })
                          }
                          style={{ width: "300px" }}
                        />
                      </tr>
                    </th>
                    <th style={{ width: "350px" }}>
                      <tr>
                        <TextField
                          id="filled-multiline-static"
                          multiline
                          rows={8}
                          defaultValue={footer.Contactanos}
                          variant="filled"
                          onChange={(e) =>
                            setFooter({
                              ...footer,
                              Contactanos: e.target.value,
                            })
                          }
                          style={{ width: "300px" }}
                        />
                      </tr>
                      <tr scope="col">
                        <input
                          placeholder="Número telefonico"
                          value={footer.Direccion}
                          onChange={(e) =>
                            setFooter({
                              ...footer,
                              Direccion: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </tr>
                      <tr scope="col">
                        <input
                          placeholder="Horario de atención. Ej: Lunes a Viernes 9am a 6pm"
                          value={footer.Telefono}
                          onChange={(e) =>
                            setFooter({
                              ...footer,
                              Telefono: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </tr>
                      <tr scope="col">
                        <input
                          placeholder="Horario de atención. Ej: Lunes a Viernes 9am a 6pm"
                          value={footer.Correo}
                          onChange={(e) =>
                            setFooter({
                              ...footer,
                              Correo: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </tr>
                      <tr scope="col">
                        <input placeholder="" className="form-control" />
                      </tr>
                      <tr scope="col"></tr>
                    </th>
                    <th style={{ width: "350px" }}>
                      <tr>
                        <TextField
                          id="filled-multiline-static"
                          multiline
                          rows={8}
                          defaultValue={footer.RedesSocialesTexto}
                          variant="filled"
                          onChange={(e) =>
                            setFooter({
                              ...footer,
                              RedesSocialesTexto: e.target.value,
                            })
                          }
                          style={{ width: "300px" }}
                        />
                      </tr>
                      <tr scope="col">
                        <input
                          placeholder="Facebook"
                          value={footer.fb}
                          onChange={(e) =>
                            setFooter({
                              ...footer,
                              fb: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </tr>
                      <tr scope="col">
                        <input
                          placeholder="Twitter"
                          value={footer.tw}
                          onChange={(e) =>
                            setFooter({
                              ...footer,
                              tw: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </tr>
                      <tr scope="col">
                        <input
                          placeholder="Instagram"
                          value={footer.insta}
                          onChange={(e) =>
                            setFooter({
                              ...footer,
                              insta: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </tr>
                      <tr scope="col">
                        <input
                          placeholder="Youtube"
                          value={footer.yt}
                          onChange={(e) =>
                            setFooter({
                              ...footer,
                              yt: e.target.value,
                            })
                          }
                          className="form-control"
                        />
                      </tr>
                    </th>
                  </tbody>
                </table>
                <div
                  className="col-lg-12"
                  style={{ textAlign: "center", marginTop: "3%" }}
                >
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={() => updateFooter()}
                  >
                    Guardar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TablaFooter;
