import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function DropZone({ saveFile, label }) {
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:600px)");
  const onDrop = useCallback((acceptedFiles) => {
    saveFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: [".jpg", ".png", ".jpeg", ".JPG", ".PNG", ".JPEG"],
    maxSize: 5242880,
  });

  return (
    <div
      {...getRootProps()}
      style={{
        height: "100px",
        borderRadius: 10,
        borderColor: theme.palette.primary.main,
        justifyContent: "center",
        alignItems: "center",
        width: "-webkit-fill-available",
        border: "dashed",
        padding: "5px",
        margin: "30px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#E9036E",
        backgroundImage: "url(/upload.png)",
        backgroundSize: "contain",
        position: "relative",
      }}
    >
      <label style={{ position: "absolute", top: "-1.5rem" }}>
        {label && matches ? `${label} de  hasta 5mb` : label}
      </label>
      <input {...getInputProps()} />
      <Typography
        variant="subtitle2"
        textAlign="center"
        style={{ padding: 20 }}
      >
        {/* {isDragActive
          ? "Arroja aqui tus archivos"
          : "Arrastra aqui o click para agregar una foto"} */}
      </Typography>
      <label style={{ position: "absolute", bottom: "-1.5rem" }}>
        {label && matches
          ? `${label} - Archivos aceptados ".jpg", ".png", ".jpeg",
        ".JPG", ".PNG", ".JPEG"`
          : "Archivos de hasta 5 MB"}
      </label>
    </div>
  );
}
