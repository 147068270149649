import React, { useEffect, useState } from "react";
import Titulo from "./Titulo";
import Pagination from "./Pagination";
import ItemBlog from "./ItemBlog";
import BlogCard from "./BlogCard";

import CategoriaBlogService from "../../../services/CategoriaBlog.service";
import NoticiaService from "../../../services/Blog.service";

const BlogTabs = () => {
  const [noticias, setNoticias] = useState([]);
  const [destacados, setDestacados] = useState([]);
  const [tabUno, setTabUno] = useState("active");
  const [tabDos, setTabDos] = useState("");
  const [tabUnoCuerpo, setTabUnoCuerpo] = useState("show active");
  const [tabDosCuerpo, setTabDosCuerpo] = useState("");
  useEffect(() => {
    getNoticias();
    getDestacados();
  }, []);

  const getNoticias = async () => {
    try {
      const data = await NoticiaService.list(4, 0);
      console.log("NOTICIIAAS" + data.data);
      setNoticias(data.data);
    } catch (error) {
    } finally {
    }
  };

  const getDestacados = async (filtro) => {
    try {
      console.log("el filtro es:" + filtro);
      const data = await NoticiaService.filtroCategoria("Destacados", 5, 0);
      console.log("total: " + data.total);
      setDestacados(data.data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <>
      {/*PESTAÑAS*/}
      <div className="d-none d-md-block">
        <div className="mb-4">
          <ul
            className="nav nav-tabs nav-justified blog-tabs"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className={"nav-link " + tabUno}
                id="recent-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="recent"
                aria-selected="true"
                href="javascript:void(0)"
                onClick={() => {
                  setTabUno("active");
                  setTabDos("");
                  setTabUnoCuerpo("show active");
                  setTabDosCuerpo("");
                }}
              >
                Recientes
              </a>
            </li>
            <li className="nav-item">
              <a
                className={"nav-link " + tabDos}
                id="popular-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="popular"
                aria-selected="false"
                href="javascript:void(0)"
                onClick={() => {
                  setTabUno("");
                  setTabDos("active");
                  setTabUnoCuerpo("");
                  setTabDosCuerpo("show active");
                }}
              >
                Destacados
              </a>
            </li>
          </ul>
          <div
            className="tab-content bg-smoke border border-light-gray border-top-0 rounded-bottom px-3 py-5"
            id="myTabContent"
          >
            <div
              className={"tab-pane fade " + tabUnoCuerpo}
              id="recent"
              role="tabpanel"
              aria-labelledby="recent-tab"
            >
              {noticias.map((record, index) => {
                return (
                  <BlogCard
                    id={record.id}
                    titulo={record.titulo}
                    imagenes={record.imagen}
                    fecha={record.updateAt}
                  />
                );
              })}
            </div>
            <div
              className={"tab-pane fade" + tabDosCuerpo}
              id="popular"
              role="tabpanel"
              aria-labelledby="popular-tab"
            >
              {destacados.map((record, index) => {
                return (
                  <BlogCard
                    id={record.id}
                    titulo={record.titulo}
                    imagenes={record.imagen}
                    fecha={record.updateAt}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogTabs;
