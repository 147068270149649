/* Dependencies */
import { useContext, useEffect, useState } from "react";
import { AuthContext, IAuthContext } from "./context/AuthContext";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import FullPageLoading from "./utils/FullPageLoading";
/* Public components*/
import PublicLayout from "./components/public/layout/PublicLayout";
import Index from "./components/public/portada/Index";
import Login from "./components/public/login/Login";
import ForgotPassword from "./components/public/login/ForgotPassword";
import ResetPassword from "./components/public/login/ResetPassword";

import PaqueteIndividual from "./components/public/paquetes/PaqueteIndividual";
import BookingStep1 from "./components/public/booking/BookinStep1";
import BookingStep3 from "./components/public/booking/BookinStep3";
import BookingHotelStep1 from "./components/public/bookingHotel/BookinHotelStep1";
import BookingHotelStep3 from "./components/public/bookingHotel/BookinHotelStep3";
import PaquetesGrid from "./components/public/paquetes/PaquetesGrid";
import BlogGrid from "./components/public/blog/BlogGrid";
import BlogIndividual from "./components/public/blog/BlogIndividual";
import DashboardUser from "./components/public/dashboard/DashboardUser";

import HotelesGrid from "./components/public/hoteles/HotelesGrid";
import HotelIndividual from "./components/public/hoteles/HotelIndividual";

/* Private components */
import PrivateLayout from "./components/private/layout";
//Carrusel
import CarruselTable from "./components/private/carrusel/CarruselTable";
import CrearCarrusel from "./components/private/carrusel/CrearCarrusel";
import EditarCarrusel from "./components/private/carrusel/EditarCarrusel";
import EliminarCarrusel from "./components/private/carrusel/EliminarCarrusel";
//Blog
import TablaBlog from "./components/private/blog/TablaBlog";
import CrearBlog from "./components/private/blog/CrearBlog";
import EditarBlog from "./components/private/blog/EditarBlog";
import EliminarBlog from "./components/private/blog/EliminarBlog";
//Categoria Blog
import TablaCategoriaBlog from "./components/private/categoria/TablaCategoriaBlog";
//Paquetes
import TablaPaquetes from "./components/private/paquetes/TablaPaquetes";
import CrearPaquete from "./components/private/paquetes/CrearPaquete";
import EliminarPaquete from "./components/private/paquetes/EliminarPaquete";
import EditarPaquet from "./components/private/paquetes/EditarPaquet";
//Usuarios
import TablaUsuarios from "./components/private/usuarios/TablaUsuario";
import NuevoUsuario from "./components/private/usuarios/NuevoUsuario";
import EditarUsuario from "./components/private/usuarios/EditarUsuario";
import EliminarUsuario from "./components/private/usuarios/EliminarUsuario";
//Ubicacion
import TablaUbicacion from "./components/private/ubicacion/TablaUbicacion";
import NuevaUbicacion from "./components/private/ubicacion/NuevaUbicacion";
import EditarUbicacion from "./components/private/ubicacion/EditarUbicacion";
import EliminarUbicacion from "./components/private/ubicacion/EliminarUbicacion";
import AvisoPrivacidad from "./components/public/layout/AvisoPrivacidad";
//aviso
import TablaAviso from "./components/private/aviso/TablaAviso";
//footer
import TablaFooter from "./components/private/footer/TablaFooter";
//terminos
import TablaTerminos from "./components/private/terminos/TablaTerminos";
//index
import TablaIndex from "./components/private/index/TablaIndex";
//dashboard
import Dashboard from "./components/private/dashboard/dashboard";
import Places from "./components/private/paquetes/Places";
import TablaHoteles from "./components/private/hotel/TablaHoteles";
//Pagos
import TablaPagos from "./components/private/pagos/TablaPagos";
import Corte from "./components/private/pagos/Corte";
import EliminarPago from "./components/private/pagos/EliminarPago";
import CrearHotel from "./components/private/hotel/CrearHotel";
import EditarHotel from "./components/private/hotel/EditarHotel";
import HabitacionesGrid from "./components/private/habitaciones/HabitacionesGrid";
import CrearHabitacion from "./components/private/habitaciones/CrearHabitacion";
import EditarHabitacion from "./components/private/habitaciones/EditarHabitacion";
import EliminarHabitacion from "./components/private/habitaciones/EliminarHabitacion";
import EliminarHotel from "./components/private/hotel/EliminarHotel";
import Reservaciones from "./components/private/reservacion/Reservaciones";

function App() {
  const { currentUser, checkUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
      checkUser();
      setLoading(false);
    }, 500);
  });

  const publicRoutes = (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<Index />} />
        <Route path="paquetes" element={<PaquetesGrid />} />
        <Route path="paquete/:id" element={<PaqueteIndividual />} />
        <Route path="paquetes/:filtroI" element={<PaquetesGrid />} />
        <Route path="hoteles" element={<HotelesGrid />} />
        <Route path="hoteles/:id" element={<HotelIndividual />} />
        <Route path="bookingStepUno/:id" element={<BookingStep1 />} />
        <Route path="bookingStepTres/:id" element={<BookingStep3 />} />
        <Route path="bookingHotelStepUno/:id" element={<BookingHotelStep1 />} />
        <Route
          path="bookingHotelStepTres/:id"
          element={<BookingHotelStep3 />}
        />
        <Route path="blog" element={<BlogGrid />} />
        <Route path="blog/:id" element={<BlogIndividual />} />
        <Route path="aviso" element={<AvisoPrivacidad />} />
        {currentUser ? (
          <Route path="dashboard/" element={<DashboardUser />} />
        ) : (
          <>
            <Route path="login" element={<Login />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route
              path="resetPassword/:id/:token"
              element={<ResetPassword />}
            />
          </>
        )}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Route>
    </Routes>
  );

  const adminRoutes = (
    <Routes>
      <Route path="/" element={<PrivateLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="carousel" element={<CarruselTable />} />
        <Route path="carousel/nuevo" element={<CrearCarrusel />} />
        <Route path="carousel/editar/:id" element={<EditarCarrusel />} />
        <Route path="carousel/eliminar/:id" element={<EliminarCarrusel />} />

        <Route path="blog" element={<TablaBlog />} />
        <Route path="blog/nuevo" element={<CrearBlog />} />
        <Route path="blog/editar/:id" element={<EditarBlog />} />
        <Route path="blog/eliminar/:id" element={<EliminarBlog />} />
        <Route path="categoriaBlog" element={<TablaCategoriaBlog />} />

        <Route path="paquete" element={<TablaPaquetes />} />
        <Route path="paquete/nuevo" element={<CrearPaquete />} />
        <Route path="paquete/eliminar/:id" element={<EliminarPaquete />} />
        <Route path="paquete/:id" element={<PaqueteIndividual />} />
        <Route path="paquete/editar/:id" element={<EditarPaquet />} />

        <Route path="usuario" element={<TablaUsuarios />} />
        <Route path="usuario/nuevo" element={<NuevoUsuario />} />
        <Route path="usuario/editar/:id" element={<EditarUsuario />} />
        <Route path="usuario/eliminar/:id" element={<EliminarUsuario />} />

        <Route path="ubicacion" element={<TablaUbicacion />} />
        <Route path="ubicacion/nuevo" element={<NuevaUbicacion />} />
        <Route path="ubicacion/editar/:id" element={<EditarUbicacion />} />
        <Route path="ubicacion/eliminar/:id" element={<EliminarUbicacion />} />

        <Route path="aviso" element={<TablaAviso />} />
        <Route path="footer" element={<TablaFooter />} />
        <Route path="terminos" element={<TablaTerminos />} />
        <Route path="index" element={<TablaIndex />} />

        <Route path="places" element={<Places />} />
        <Route path="hoteles" element={<TablaHoteles />} />
        <Route path="hoteles/nuevo" element={<CrearHotel />} />
        <Route path="hoteles/editar/:id" element={<EditarHotel />} />
        <Route path="hoteles/:id/habitaciones" element={<HabitacionesGrid />} />
        <Route path="hoteles/eliminar/:id" element={<EliminarHotel />} />
        <Route
          path="hoteles/:id/habitaciones/nuevo/:id"
          element={<CrearHabitacion />}
        />
        <Route
          path="hoteles/:id/habitaciones/editar/:id"
          element={<EditarHabitacion />}
        />
        <Route
          path="hoteles/:id/habitaciones/eliminar/:id"
          element={<EliminarHabitacion />}
        />

        <Route path="pagos" element={<TablaPagos />} />
        <Route path="pagos/corte/:id" element={<Corte />} />
        <Route path="pagos/eliminar/:id" element={<EliminarPago />} />

        <Route path="hoteles/:id/reservaciones" element={<Reservaciones />} />

        {/* <Route path="*" element={<FullPageLoading />} /> */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Route>
    </Routes>
  );

  const getRoute = () => {
    if (loading) {
      return (
        <Routes>
          <Route path="/" element={<FullPageLoading />} />
        </Routes>
      );
    }

    //

    /*if (currentUser) {
      return adminRoutes;
    }*/
    if (!currentUser) {
      return publicRoutes;
    } else {
      if (currentUser.tipo == "Administrador") {
        return adminRoutes;
      } else {
        return publicRoutes;
      }
    }
  };

  return (
    <>
      <BrowserRouter>{getRoute()}</BrowserRouter>
    </>
  );
}

export default App;
