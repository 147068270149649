import httpClient from "./HttpClient";
const prefix = "/habitacion";

export default class HabitacionService {
  static async create(paquete) {
    return (await httpClient.post(`${prefix}`, paquete)).data;
  }

  static async update(paquete) {
    return (await httpClient.put(`${prefix}/${paquete.id}`, paquete)).data;
  }

  static async remove(id) {
    return (await httpClient.delete(`${prefix}/${id}`)).data;
  }

  static async getById(id) {
    return (await httpClient.get(`${prefix}/${id}`)).data;
  }

  static async getId(id) {
    return (await httpClient.get(`${prefix}-get/${id}`)).data;
  }

  static async getTotal() {
    return (await httpClient.get(`${prefix}-total/`)).data;
  }

  static async list(data) {
    return (await httpClient.post(`${prefix}es/`, data)).data;
  }

  static async listReservas(data) {
    return (await httpClient.post(`${prefix}es-reservas/`, data)).data;
  }

  static async listReservasHabitacion(data) {
    return (await httpClient.post(`${prefix}-reservas/`, data)).data;
  }

  static async habitacionDisponible(data) {
    return (await httpClient.post(`${prefix}-disponible/`, data)).data;
  }

  static async listHabitaciones(data) {
    return (await httpClient.post(`${prefix}es-all/`, data)).data;
  }

  static async mostrarInicio(limit) {
    return (await httpClient.get(`${prefix}-mostrarInicio/${limit}`)).data;
  }

  static async filtroCiudad(limit, filtros) {
    return (await httpClient.post(`${prefix}-filtroCiudad/${limit}`, filtros))
      .data;
  }

  static async filtroFechas(limit, filtro) {
    return (await httpClient.post(`${prefix}-filtroFecha/${limit}`, filtro))
      .data;
  }

  //   static async filtroCiudadFecha(limit, filtro) {
  //     return (
  //       await httpClient.post(`${prefix}-filtroCiudadFecha/${limit}`, filtro)
  //     ).data;
  //   }

  //   static async reservasAltas(limit) {
  //     return (await httpClient.get(`${prefix}-reservas/${limit}`)).data;
  //   }
}
