import React, { Component } from "react";
import Uploader from "./Uploader";
import Preview from "./Preview";
import "./uploader.css";

class DropComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesPreviewUrls: [],
      imagenesDefault: props.imagenesDefault,
      update: false,
    };
  }

  imagesPreviewUrls = (result, updateState, index) => {
    const { subirImagen } = this.props;
    let arrayM = this.state.imagesPreviewUrls;

    arrayM.push(result);
    console.log(result);
    console.log(arrayM);
    this.setState({
      imagesPreviewUrls: arrayM,
      update: updateState,
    });

    subirImagen(arrayM);
  };

  imagesDefaultPreviewUrls = (result, updateState, index, arrayM) => {
    const { subirImagen } = this.props;
    arrayM.push(result);
    this.setState({
      imagesPreviewUrls: arrayM,
      update: updateState,
    });

    subirImagen(arrayM);
  };

  deleteImage = (id) => {
    try {
      const { imagesPreviewUrls } = this.state;
      const { subirImagen } = this.props;
      if (imagesPreviewUrls.length > 0) {
        const filterImages = imagesPreviewUrls.filter(
          (image) => image.id !== id
        );
        subirImagen(filterImages);
        // this.setState({
        //   imagesPreviewUrls: filterImages,
        // });
        this.setState((state, props) => ({
          imagesPreviewUrls: filterImages,
          update: true,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidUpdate(prevProps) {
    if (this.state.update) {
      return;
    }
    if (!this.props.imagenesDefault) {
      return;
    }
    if (
      this.props.imagenesDefault?.length !== prevProps.imagenesDefault.length ||
      this.state.imagesPreviewUrls?.length < this.props.imagenesDefault.length
    ) {
      try {
        let arrayM = [];

        this.props.imagenesDefault.map((record, index) => {
          const result = {
            urlImaen: record,
            id: index,
          };

          this.imagesDefaultPreviewUrls(result, false, index, arrayM);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  render() {
    const { imagesPreviewUrls, imagenesDefault } = this.state;
    const { subirImagen, label, footer } = this.props;
    // subirImagen(imagesPreviewUrls);
    return (
      <div>
        <Uploader
          label={label}
          footer={footer}
          imagenesDefault={imagenesDefault}
          imagesPreviewUrls={this.imagesPreviewUrls}
        />

        {/* <button onClick={subirImagen(imagesPreviewUrls)} /> */}
        {imagesPreviewUrls.length > 0 ? (
          <Preview
            imagesPreviewUrls={imagesPreviewUrls}
            deleteImage={this.deleteImage}
          />
        ) : null}
      </div>
    );
  }
}

export default DropComponent;
