/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

/* Components & dependencies */
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";
import Carousel from "react-bootstrap/Carousel";
import { useCart } from "react-use-cart";

/* services */
import S3Service from "../../../services/S3.service";
import PaqueteService from "../../../services/Hoteles.service";
import HabitacionService from "../../../services/Habitacion.service";

import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImageLine from "./ImageLine";
import MapLibreUe from "./MapLibreUe";
import MapGoogle from "./MapGoogle/MapGoogle";
import Amenidades from "./Amenidades";
import ItemHabitacion from "./ItemHabitacion";
import { IconButton } from "@mui/material";
import Busqueda from "./Busqueda";

const HotelIndividual = () => {
  const { id } = useParams();
  let today = new Date();
  const matchesmd = useMediaQuery("(max-width:1000px)");
  const navigate = useNavigate();
  const [paquete, setPaquete] = useState({ lat: "", long: "" });
  const [habitaciones, setHabitaciones] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [imagenesDias, setImagenesDias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disponibilidad, setDisponibilidad] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [Extra, setExtra] = useState(0);
  const [Nino, setNino] = useState(0);
  const [huespedesSearch, setHuespedesSearch] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalMin, setTotalMin] = useState(0);
  const [fecha, setFecha] = useState();
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );
  const [filtroFechaFin, setFiltroFechaFin] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );
  const [ubicacion, setUbicacion] = useState();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const matches = useMediaQuery("(min-width:600px)");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  let dias = 0;
  let cargaPrecio = 0;
  let check = 0;
  let adultoPrecio = 0;
  let adultoQuantity = 0;

  const {
    items,
    addItem,
    removeItem,
    removeLineItem,
    clearCart,
    lineItemsCount,
  } = useCart();

  const scroolUp = () => {
    const titleElement = document.getElementById("page-title");
    if (titleElement) {
      titleElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const scroolUpHabitaciones = () => {
    const headerHabitacionesElement =
      document.getElementById("habitaciones-tipos");
    if (headerHabitacionesElement) {
      headerHabitacionesElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  useEffect(() => {
    getData();
    vaciarCart();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getHabitaciones();
  }, [page, limit]);

  const handleChangeCarouselIndex = (newIndex) => {
    setCarouselIndex(newIndex);
  };

  const handleDiponibilidad = (newDisponibilidad) => {
    setDisponibilidad(newDisponibilidad);
  };

  const vaciarCart = async () => {
    items.map((record, index) => {
      removeItem(record.id);
    });
  };

  const getData = async () => {
    setLoading(true);
    setImagenes([]);
    try {
      const result = await PaqueteService.getById(parseInt(id));
      setPaquete(result);

      for (const url of result.imagenes) {
        const imagen = await S3Service.get(url);
        setImagenes((imagenes) => [...imagenes, imagen]);
      }

      setLoading(false);
      scroolUp();
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const getHabitacionesSearch = async () => {
    try {
      if (filtroFechaFin < filtroFechaInicio) {
        toast.error("Debes de selecciona fechas validas");
        return;
      }
      const result = await HabitacionService.filtroFechas(100, {
        limit: limit,
        offset: page * limit,
        hotelId: id,
        fechaInicial: filtroFechaInicio,
        fechaFinal: filtroFechaFin,
        cupo: huespedesSearch,
      });
      setHabitaciones(result.data);
      setTotal(result.total);
      handleDiponibilidad(true);
      scroolUpHabitaciones();
    } catch (error) {
      console.log(error);
    }
  };

  const getHabitaciones = async () => {
    setLoading(true);
    try {
      const result = await HabitacionService.list({
        limit: limit,
        offset: page * limit,
        hotelId: id,
      });
      setHabitaciones(result.data);
      setTotal(result.total);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const menos = (tipo) => {
    if (tipo == "Extra") {
      setExtra(Extra >= 1 ? Extra - 1 : 0);
    } else {
      setNino(Nino >= 1 ? Nino - 1 : 0);
    }
  };

  const mas = (tipo) => {
    if (tipo == "Extra") {
      setExtra(Extra + 1);
    } else {
      setNino(Nino + 1);
    }
  };

  const sumar = (precio) => {
    setTotal(Number(total) + Number(precio));
  };

  const restar = (precio) => {
    if (Number(total) - Number(precio) >= Number(totalMin)) {
      setTotal(Number(total) - Number(precio));
    }
  };

  const irPagar = () => {
    if (fecha == undefined) {
      toast.error("Debes de seleccionar una fecha");
    } else if (currentUser == undefined) {
      toast.error("Por favor inicia sesión para comprar");
    } else {
      let fechaFinal = new Date(fecha);
      //let fechaFinal=f.getDate()+paquete.dias.length();
      fechaFinal.setDate(fechaFinal.getDate() + dias);

      const object = {
        id: idUnico(id + 1),
        idpaquete: id,
        name: "Adulto",
        price: adultoPrecio,
        quantity: adultoQuantity,
        cantidad: adultoQuantity,
        fechaInicial: fecha,
        fechaFinal: fechaFinal,
      };
      addItem(object);
      console.log("Item agregado Adulto:" + object.name);
      console.log("Nino:" + Nino);
      console.log("Extra:" + Extra);
      navigate(`/bookingHotelStepUno/${id}`);
    }
  };

  const idUnico = (length) => {
    try {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    } catch (error) {
      CSSConditionRule.log(error);
    }
  };

  const addHuesped = () => {
    setHuespedesSearch((prev) => prev + 1);
  };
  const subHuesped = () => {
    setHuespedesSearch((prev) => prev - 1);
  };
  const handleFiltroFechaInicio = (newFecha) => {
    setFiltroFechaInicio(newFecha);
  };
  const handleFiltroFechaFin = (newFecha) => {
    setFiltroFechaFin(newFecha);
  };

  const locale = "en-gb";

  return (
    <>
      <div id="page-title"></div>
      {loading ? (
        <FullPageLoading />
      ) : (
        <div className="main-wrapper single-package-right-sidebar">
          {/* Titulo */}
          <section
            className="page-title"
            style={
              matchesmd ? { paddingTop: "100px" } : { paddingTop: "130px" }
            }
          >
            <div
              className="page-title-img bg-img bg-overlay-darken"
              style={{
                backgroundImage: "url(/assets/img/pages/page-title-bg13.jpg)",
              }}
            >
              <div className="containerPublic">
                <div
                  className="row align-items-center justify-content-center"
                  style={{ height: "200px" }}
                >
                  <div className="col-lg-6">
                    <div className="page-title-content">
                      <div className="">
                        <h2 className="text-uppercase text-white font-weight-bold">
                          {paquete?.nombre}
                        </h2>
                      </div>
                      <p className="text-white mb-0">
                        {paquete?.descripcionCorta.slice(0, 400)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="pb-10 pt-10">
            <div class="containerPublic">
              <div class="row">
                <Busqueda
                  total={total}
                  search={getHabitacionesSearch}
                  filtroFechaInicio={filtroFechaInicio}
                  filtroFechaFin={filtroFechaFin}
                  huespedSearch={huespedesSearch}
                  addHuesped={addHuesped}
                  subHuesped={subHuesped}
                  handleFiltroFechaInicio={handleFiltroFechaInicio}
                  handleFiltroFechaFin={handleFiltroFechaFin}
                />
                <div class="col-md-12 col-lg-8">
                  <div style={{ padding: "1rem" }}>
                    <div
                      style={{
                        height: "5rem",
                        display: "flex",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <ImageLine
                        imagenes={imagenes}
                        selectedIndex={carouselIndex}
                      />
                    </div>
                    <div className="swiper-container swiper-btn-group swiper-btn-group-end text-white p-0 overflow-hidden">
                      <div className="swiper-wrapper h-100">
                        <Carousel
                          slide={false}
                          nextLabel={""}
                          prevLabel={""}
                          onSelect={handleChangeCarouselIndex}
                          indicators={false}
                          style={{ marginTop: "0.5rem" }}
                        >
                          {imagenes.map((record, index) => {
                            return (
                              <Carousel.Item
                                key={index}
                                style={{
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                              >
                                <img
                                  className="d-block"
                                  src={imagenes[index].result}
                                  alt="imagen"
                                  style={{
                                    height: "37rem",
                                    objectFit: "scale-down",
                                  }}
                                />
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </div>
                      {/* Add Arrows */}
                      <div className="swiper-button-next swiper-button-white" />
                      <div className="swiper-button-prev swiper-button-white" />
                    </div>
                  </div>
                  <div class="mt-8">
                    <h2 class="text-uppercase mb-5">Descripción</h2>
                    <p class="mb-6">{paquete?.descripcion}</p>

                    <div class="mb-5">
                      <div class="row">
                        <Amenidades amenidades={paquete.servicios} />
                      </div>
                    </div>
                  </div>

                  <div class="mb-6">
                    <h2 id="habitaciones-tipos" class="text-uppercase mb-5">
                      Habitaciones tipos
                    </h2>
                    <p class="mb-5"></p>
                  </div>

                  {!habitaciones[0] && (
                    <div
                      style={{
                        width: "100%",
                        marginTop: "10%",
                        marginBottom: "10%",
                        textAlign: "center",
                      }}
                    >
                      <p>
                        LO SENTIMOS PERO NO TENEMOS HABITACIONES PARA ESTA FECHA
                      </p>
                    </div>
                  )}

                  {habitaciones?.map((habitaicon) => (
                    <ItemHabitacion
                      habitacion={habitaicon}
                      fechas={{
                        fechaInicio: filtroFechaInicio,
                        fechaFinal: filtroFechaFin,
                      }}
                      disponibilidad={disponibilidad}
                      hotelId={id}
                    />
                  ))}

                  <div class="mb-7">
                    <h2 class="text-uppercase mb-6">Ubicacion</h2>
                    <p class="mb-0">{paquete?.direccion}</p>
                  </div>

                  <div
                    class="mb-7"
                    id="map"
                    style={{
                      width: "100%",
                      height: "400px",
                      position: "relative",
                    }}
                  >
                    {/* <MapLibreUe
                      positionAprox={[
                        Number(paquete?.long),
                        Number(paquete?.lat),
                      ]}
                    /> */}
                    <MapGoogle
                      positionApproximate={[
                        Number(paquete?.lat),
                        Number(paquete?.long),
                      ]}
                      text={paquete?.nombre}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default HotelIndividual;
