/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

/* Components & dependencies */
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";

/* Model & services */
import CarruselService from "../../../services/Carrusel.service";
import S3Service from "../../../services/S3.service";

import styles from "../layout.module.css"

const EliminarCarrusel = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(undefined);
  const [imagen, setImagen] = useState("");
  const [carrusel, setCarrusel] = useState();

  useEffect(() => {
    loadCarruselItem();
  }, []);

  const loadCarruselItem = async () => {
    setLoading(true);
    try {
      const result = await CarruselService.getById(parseInt(id));
      if (result.imagen) {
        const imagen = await S3Service.get(result.imagen);
        setImagen(imagen.result);
      }
      setCarrusel(result);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(true);
      return;
    }
  };

  const eliminarCarrusel = async () => {
    setLoading(true);
    try {
      await CarruselService.remove(parseInt(id));
      toast.info("Item de carrusel eliminado");
      navigate("/carousel");
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(true);
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <div className={styles.fulldiv}>
          <div>
            <h1>Eliminar Carrusel</h1>
            <div className={styles.recentorders}>
              <table
                id="recent-orders--table"
                style={{ boxShadow: "initial", textAlign: "justify" }}
              >
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Descripcion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        placeholder="Titulo"
                        style={{ width: "-webkit-fill-available" }}
                        value={carrusel?.titulo}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        placeholder="Subtitulo"
                        style={{ width: "-webkit-fill-available" }}
                        value={carrusel?.subtitulo}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
              {carrusel.imagen ? (
                <>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={String(imagen)}
                      style={{
                        height: 300,
                        objectFit: "contain",
                        width: "-webkit-fill-available",
                      }}
                    />
                  </div>
                </>
              ) : null}
              <table
                id="recent-orders--table"
                style={{ boxShadow: "initial", textAlign: "justify" }}
              >
                <tr>
                  <td>
                    <Button color="error" onClick={() => navigate("/carousel")}>
                      Cancelar
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => eliminarCarrusel()}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EliminarCarrusel;
