/* React */
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

/* Components & dependencies */
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";
import Carousel from "react-bootstrap/Carousel";
import PaquetePrecio from "./PaquetePrecio";
import ItemBlog from "../blog/ItemBlog";
import GoogleMaps from "simple-react-google-maps";
import { useCart } from "react-use-cart";
import ImageLine from "./ImageLine";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* services */
import S3Service from "../../../services/S3.service";
import PaqueteService from "../../../services/Paquete.service";
import UbicacionService from "../../../services/UbicacionService";

import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

const PaqueteIndividual = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [paquete, setPaquete] = useState({});
  const [imagenes, setImagenes] = useState([]);
  const [imagenesDias, setImagenesDias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [precio, setPrecio] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [Extra, setExtra] = useState(0);
  const [Nino, setNino] = useState(0);
  const [ExtraPrecio, setExtraPrecio] = useState(0);
  const [NinoPrecio, setNinoPrecio] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalMin, setTotalMin] = useState(0);
  const [fecha, setFecha] = useState();
  const [ubicacion, setUbicacion] = useState();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const matches = useMediaQuery("(min-width:600px)");

  let dias = 0;
  let cargaPrecio = 0;
  var today = new Date();
  let check = 0;
  let adultoPrecio = 0;
  let adultoQuantity = 0;

  const {
    items,
    addItem,
    removeItem,
    removeLineItem,
    clearCart,
    lineItemsCount,
  } = useCart();

  const scroolUp = () => {
    const titleElement = document.getElementById("page-title");
    console.log(titleElement);
    if (titleElement) {
      titleElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    getData();
    vaciarCart();
    window.scrollTo(0, 0);
  }, []);

  const handleChangeCarouselIndex = (newIndex) => {
    setCarouselIndex(newIndex);
  };

  const vaciarCart = async () => {
    items.map((record, index) => {
      removeItem(record.id);
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      const result = await PaqueteService.getById(parseInt(id));
      const ubi = await UbicacionService.getById(parseInt(result.ubicacion));
      setUbicacion(ubi);
      console.log("ubicacion********************" + ubi.lat + ubi.lng);
      setPaquete(result);

      /*  if (currentUser == undefined || currentUser.tipo == "Usuario" || currentUser==null) {*/
      if (result.paquetesUsuario && cargaPrecio == 0) {
        for (const paquete of result.paquetesUsuario) {
          setPrecio((paquetes) => [...paquetes, paquete]);
          if (paquete.tipo == "Adulto") {
            setTotal(paquete.precio);
            setTotalMin(paquete.precio);
          }
        }

        cargaPrecio = 1;
      }
      /*  } else {
        console.log("******tipo user:" + currentUser.tipo);
        if (result.paquetesEmpresa && cargaPrecio == 0) {

          for (const paquete of result.paquetesEmpresa) {
            setPrecio((paquetes) => [...paquetes, paquete]);
            if (paquete.props.tipo == "Adulto") {
              setTotal(paquete.props.precio);
              setTotalMin(paquete.props.precio);
              console.log("---totalMin" + totalMin);
            }
          }
          cargaPrecio = 1;
        }
      }*/
      for (const url of result.imagenes) {
        const imagen = await S3Service.get(url);
        setImagenes((imagenes) => [...imagenes, imagen]);
      }
      for (const dia of result.diasJson) {
        const imagen = await S3Service.get(dia.imagen);
        console.log("imagen dia " + imagen.result);
        setImagenesDias((imagenesDias) => [...imagenesDias, imagen.result]);
      }
      setLoading(false);
      scroolUp();
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const menos = (tipo) => {
    if (tipo == "Extra") {
      setExtra(Extra >= 1 ? Extra - 1 : 0);
    } else {
      setNino(Nino >= 1 ? Nino - 1 : 0);
    }
  };

  const mas = (tipo) => {
    if (tipo == "Extra") {
      setExtra(Extra + 1);
    } else {
      setNino(Nino + 1);
    }
  };

  const sumar = (precio) => {
    setTotal(Number(total) + Number(precio));
  };

  const restar = (precio) => {
    if (Number(total) - Number(precio) >= Number(totalMin)) {
      setTotal(Number(total) - Number(precio));
    }
  };

  const irPagar = () => {
    if (fecha == undefined) {
      toast.error("Debes de seleccionar una fecha");
    } else if (currentUser == undefined) {
      toast.error("Por favor inicia sesión para comprar");
    } else {
      let fechaFinal = new Date(fecha);
      //let fechaFinal=f.getDate()+paquete.dias.length();
      fechaFinal.setDate(fechaFinal.getDate() + dias);

      const object = {
        id: idUnico(id + 1),
        idpaquete: id,
        name: "Adulto",
        price: adultoPrecio,
        quantity: adultoQuantity,
        cantidad: adultoQuantity,
        fechaInicial: fecha,
        fechaFinal: fechaFinal,
      };
      addItem(object);
      console.log("Item agregado Adulto:" + object.name);
      console.log("Nino:" + Nino);
      console.log("Extra:" + Extra);
      navigate(`/bookingStepUno/${id}`);
    }
  };

  const idUnico = (length) => {
    try {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    } catch (error) {
      CSSConditionRule.log(error);
    }
  };

  const locale = "en-gb";

  return (
    <>
      <div id="page-title"></div>
      {loading ? (
        <FullPageLoading />
      ) : (
        <div className="main-wrapper single-package-right-sidebar">
          {/* Titulo */}
          <section className="page-title" style={{ paddingTop: "7%" }}>
            <div
              className="page-title-img bg-img bg-overlay-darken"
              style={{
                backgroundImage: "url(/assets/img/pages/page-title-bg13.jpg)",
              }}
            >
              <div className="containerPublic">
                <div
                  className="row align-items-center justify-content-center"
                  style={{ height: "200px" }}
                >
                  <div className="col-lg-6">
                    <div className="page-title-content">
                      <div className="">
                        <h2 className="text-uppercase text-white font-weight-bold">
                          {paquete?.titulo}
                        </h2>
                      </div>
                      <p className="text-white mb-0">{paquete?.subtitulo}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="py-10">
            <div className="containerPublic">
              <div className="row">
                {/**Reserva */}
                <div className="col-md-5 col-lg-4 order-2">
                  {/*<form>*/}
                  <div className="card border">
                    <h2 className="card-header text-uppercase text-center bg-smoke border-bottom">
                      Reservar
                    </h2>
                    <div className="card-body px-3 py-4">
                      <div className="border-bottom mb-5">
                        <div className="form-group mb-5">
                          <div className="form-group form-group-icon form-group-icon-category-2 mb-0">
                            <TextField
                              id="date"
                              label="Fecha"
                              type="date"
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                style: { color: "#e9036e" },
                                shrink: true,
                              }}
                              style={{ width: "100%" }}
                              inputProps={{
                                min: paquete.fechaInicial.slice(0, 10),
                                max: paquete.fechaFinal.slice(0, 10),
                              }}
                              onChange={(e) => setFecha(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="form-group mb-5"></div>

                        <div>
                          {precio.map((record, index) => {
                            if (record.tipo == "Adulto") {
                              if (check == 0) {
                                adultoPrecio = record.precio;
                                adultoQuantity = record.cantidad;
                                check = 1;
                              }

                              return (
                                <div className="form-group mb-5">
                                  <div
                                    className="row align-items-center"
                                    style={{ marginLeft: "-40%" }}
                                  >
                                    <label className="control-label col-xl-5 text-center text-xl-right">
                                      Adulto
                                    </label>
                                    <div className="col-xl-5">
                                      <div className="count-input mx-auto">
                                        <input
                                          type="number"
                                          value={adultoQuantity}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-xl-2">
                                      <p className="text-center mt-3 mt-xl-0 mb-0">
                                        ${record.precio}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <PaquetePrecio
                                  tipo={record.tipo}
                                  precio={record.precio}
                                  menos={(data) => menos(data)}
                                  mas={(data) => mas(data)}
                                  sumar={(data) => sumar(data)}
                                  restar={(data) => restar(data)}
                                  cant={record.tipo == "Extra" ? Extra : Nino}
                                />
                              );
                            }
                          })}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between border-bottom mb-5 pb-5">
                        <span className="text-uppercase h4 mb-0">
                          Precio total
                        </span>
                        <span className="font-weight-bold text-primary h3 mb-0">
                          ${Number(total).toFixed(2)}
                        </span>
                      </div>

                      <div className="text-center px-4">
                        <button
                          type="button"
                          onClick={() => {
                            irPagar();
                          }}
                          className="btn btn-hover btn-lg btn-block btn-outline-secondary text-uppercase"
                        >
                          Ir a pagar
                          <span className="ml-4">
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/*</form>*/}
                </div>

                {/**Descripción */}
                <div className="col-md-7 col-lg-8">
                  {/**Carrusel */}
                  <div
                    style={{
                      height: "5rem",
                      display: "flex",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <ImageLine
                      imagenes={imagenes}
                      selectedIndex={carouselIndex}
                    />
                  </div>
                  <div className="swiper-container swiper-btn-group swiper-btn-group-end text-white p-0 overflow-hidden">
                    <div className="swiper-wrapper h-100">
                      <Carousel
                        slide={false}
                        nextLabel={""}
                        prevLabel={""}
                        onSelect={handleChangeCarouselIndex}
                        indicators={false}
                        style={{ marginTop: "0.5rem" }}
                      >
                        {imagenes.map((record, index) => {
                          return (
                            <Carousel.Item key={index}>
                              <img
                                className="d-block w-100"
                                src={imagenes[index].result}
                                alt="imagen"
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>
                    {/* Add Arrows */}
                    <div className="swiper-button-next swiper-button-white" />
                    <div className="swiper-button-prev swiper-button-white" />
                  </div>
                  {/**Descripción */}
                  <div className="mt-8">
                    <h2 className="text-uppercase mb-5">Descripcion</h2>
                    <div className="row">
                      <div
                        className="col-lg-6 mb-6"
                        style={{ padding: "0.5rem" }}
                      >
                        <div className="blog__details__text">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: paquete.descripcion,
                            }}
                            style={{ textAlign: "justify" }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-6">
                        <div className="card border">
                          <h4
                            className="card-header text-uppercase text-center bg-transparent"
                            style={{ marginTop: "3%" }}
                          >
                            Incluye
                          </h4>
                          <div className="card-body">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: paquete.incluye,
                              }}
                              style={{
                                textAlign: "justify",
                                marginRight: "10%",
                                marginTop: "-3%",
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**Programa */}
                  <div className="mb-8">
                    <h3 className="text-uppercase mb-5">Programa</h3>
                    <div></div>
                    {paquete.diasJson.map((record, index) => {
                      dias += 1;
                      return (
                        <>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>
                                <p className="mb-5 mb-lg-0">
                                  {" "}
                                  {record.titulo}{" "}
                                </p>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <div className="row">
                                  <div className="col-lg-10 mb-6">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: record.descripcion,
                                      }}
                                      style={{ textAlign: "justify" }}
                                    ></p>
                                    <img
                                      id={`imagen${index}`}
                                      className="lazyestload"
                                      src={imagenesDias[index]}
                                      alt="image"
                                    />
                                  </div>
                                  <div className="col-lg-2 mb-6">
                                    <div className="media-body d-flex">
                                      <div className="badge badge-rounded-circle border">
                                        <span className="d-block text-primary">
                                          dia {dias}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      );
                    })}
                  </div>
                  {/**Ubicación */}
                  <div className="mb-7">
                    <h3 className="text-uppercase mb-6">Ubicacion</h3>
                  </div>
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <GoogleMaps
                        apiKey={"AIzaSyAkITgblqZ2pdFC9-JBv6T3fZogUc93TY8"}
                        style={{ height: "400px", width: "100%" }}
                        zoom={6}
                        center={{
                          lat: Number(ubicacion.lat),
                          lng: Number(ubicacion.lng),
                        }}
                        markers={{
                          lat: Number(ubicacion.lat),
                          lng: Number(ubicacion.lng),
                        }} //optional
                      />
                      {/*
                        <iframe
                          className="mb-7"
                          id="map"
                          style={{ width: "100%", height: 400 }}
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14111.279725886337!2d-98.14111784090707!3d18.9424276689983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfbf41d9b5c177%3A0x98264d215aa459df!2sAfricam%20Safari!5e0!3m2!1ses-419!2smx!4v1669953191565!5m2!1ses-419!2smx"
                          frameBorder={0}
                          scrolling="no"
                          marginHeight={0}
                          marginWidth={0}
                        />
                  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default PaqueteIndividual;
