import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Navigate, useNavigate, useParams } from "react-router-dom";

//Services
import PaqueteService from "../../../services/Hoteles.service";

//Components
import Titulo from "./Titulo";
import ItemPaquete from "./ItemHotel";
import TextField from "@mui/material/TextField";
import { TablePagination } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";

import UbicacionService from "../../../services/UbicacionService";

const HotelesGrid = () => {
  const { filtroI } = useParams();
  const [loading, setLoading] = useState(true);
  const [paquetes, setPaquetes] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [tipo, setTipo] = useState(0);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const [filtro, setFiltro] = useState(false);
  const [filtroCiudades, setFiltroCiudades] = useState([]);
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(undefined);
  const [filtroFechaFin, setFiltroFechaFin] = useState(undefined);
  const [value, setValue] = React.useState([0, 10000]);
  const [names, setNames] = useState([]);

  useEffect(() => {
    if (!filtroI) {
      setPersonName([]);
      getPaquetes();
      setFiltroCiudades([]);
    }
  }, [filtroI]);

  useEffect(() => {
    getPaquetes();
    window.scrollTo(0, 0);
  }, [page, limit, filtroI]);

  const getPaquetes = async () => {
    setLoading(true);
    try {
      if (currentUser != undefined && currentUser.tipo == "Empresa") {
        setTipo("Empresa");
      } else {
        setTipo("Usuario");
      }

      let data = await UbicacionService.mostrar(5);
      console.log("----+------subicaciones" + data.data);
      setNames(data.data);

      if (filtroI !== undefined) {
        setFiltro(true);
        // setFiltroCiudades({
        //   ciudades: filtroI,
        // });
        setPersonName((prev) => [...prev, filtroI]);
        setFiltroCiudades((prev) => [...prev, filtroI]);
        console.log("filtro por url: " + filtroI);
        console.log("Filtro inicio");
        console.log(filtroI);
        const data = await PaqueteService.filtroCiudad(100, {
          ciudades: filtroI,
        });
        console.log("tipo:" + data.data);
        setPaquetes(data.data);
      } else {
        const data = await PaqueteService.listHoteles(limit, page * limit);
        setPaquetes(data.data);
        setTotal(data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const buscar = async () => {
    setLoading(true);

    try {
      if (filtro) {
        console.log(
          "--Hay filtros" +
            filtroCiudades +
            "," +
            filtroFechaInicio +
            "," +
            filtroFechaFin +
            ","
        );
        if (
          filtroCiudades.length > 0 &&
          filtroFechaInicio == undefined &&
          filtroFechaFin == undefined
        ) {
          let cds = [];
          for (const cd of filtroCiudades) {
            const filtros = {
              ciudades: cd,
            };
            console.log("filtro de ciudades");
            console.log("la ciudad:" + filtros.ciudades);
            const data = await PaqueteService.filtroCiudad(100, filtros);
            console.log("--------- en el rango: " + value[0] + "," + value[1]);
            /*if (tipo == "Usuario") {*/
            data.data.forEach((element) => {
              let precio = [];
              for (const pre of element.habitaciones) {
                if (pre.precio >= value[0] && pre.precio <= value[1]) {
                  precio.push(pre);
                  console.log(
                    "--------- en el rango: " + value[0] + "," + value[1]
                  );
                }
              }
              if (precio.length > 0) {
                element.habitaciones = precio;
                cds.push(element);
              }
            });
            /*} else {
              data.data.forEach((element) => {
                for (const pre of element.paquetesEmpresa) {
                  if (pre.props.tipo == "Adulto") {
                    if (
                      pre.props.precio >= value[0] &&
                      pre.props.precio <= value[1]
                    ) {
                      cds.push(element);
                    }
                  }
                }
              });
            }*/
          }
          setPaquetes(cds);
          console.log("paquetes del filtro de ciudades:" + cds.length);
        }

        if (currentUser != undefined && currentUser.tipo == "Empresa") {
          setTipo("Empresa");
        } else {
          setTipo("Usuario");
        }
      } else {
        console.log("sin filtros");
        let paqs = [];
        const data = await PaqueteService.list(limit, page * limit);
        console.log("----el current data" + data.data.length);
        data.data.forEach((element) => {
          for (const pre of element.paquetesUsuario) {
            if (pre.tipo == "Adulto") {
              if (pre.precio >= value[0] && pre.precio <= value[1]) {
                paqs.push(element);
              }
            }
          }
        });
        setPaquetes(paqs);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [personName, setPersonName] = useState([]);

  const setFiltrosCiudades = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroCiudades(value);
    setFiltro(true);
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="main-wrapper packages-grid">
      <Titulo />

      <section className="bg-smoke py-10">
        <div className="containerPublic">
          <div className="row">
            {/*Izquierda */}
            <div class="col-lg-3">
              {/*#region BUSCAR */}
              <div class="row">
                <div class="col-md-6 col-lg-12">
                  <div class="mb-6 bg-white px-3 py-6 border-top border-top-5 border-primary rounded">
                    <form className="" action="index.html" method="GET">
                      <h4 className="text-uppercase font-weight-bold">
                        BUSCAR
                      </h4>
                      <div className="mb-5" style={{ minHeight: "26px" }}>
                        {filtroCiudades.length > 0
                          ? filtroCiudades.map((ciudad) => {
                              return <p>{ciudad}</p>;
                            })
                          : ""}
                      </div>
                      <div className="mb-5">
                        <FormControl
                          sx={{
                            m: 1,
                            width: "100%",
                            marginLeft: "-1%",
                            marginTop: "-3%",
                          }}
                        >
                          <InputLabel id="demo-multiple-checkbox-label">
                            Ciudad
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={personName}
                            onChange={setFiltrosCiudades}
                            input={<OutlinedInput label="Ciudad" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            sx={{
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#e9036e !important",
                              },
                            }}
                          >
                            {names.map((name) => (
                              <MenuItem key={name.ciudad} value={name.ciudad}>
                                <Checkbox
                                  checked={personName.indexOf(name.ciudad) > -1}
                                />
                                <ListItemText primary={name.ciudad} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          buscar();
                        }}
                        className="btn btn-block btn-xs btn-outline-secondary text-uppercase"
                        style={{ width: "50%", display: "inline-block" }}
                      >
                        BUSCAR
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          if (filtroI !== undefined) {
                            navigate("/paquetes");
                          } else {
                            setPersonName([]);
                            getPaquetes();
                            setFiltroCiudades([]);
                          }
                        }}
                        className="btn btn-xs btn-outline-secondary text-uppercase"
                        style={{ width: "50%", display: "inline-block" }}
                      >
                        Todo
                      </button>
                    </form>
                  </div>
                </div>
                {/*#endregion */}
                <div class="col-md-6 col-lg-12">
                  <div class="mb-6 bg-white px-3 py-6 border-top border-top-5 border-primary rounded">
                    <h4
                      className="text-uppercase font-weight-bold"
                      style={{ minHeight: "1.66rem" }}
                    >
                      Rango de precios
                    </h4>
                    <div className="price-range mt-5" id="price-range">
                      <p className="text-uppercase font-weight-bold">
                        RANGO DE PRECIO:
                      </p>
                      <p>
                        ${value[0]}{" "}
                        <i className="fa fa-minus" aria-hidden="true" /> $
                        {value[1]}
                      </p>
                      <Slider
                        getAriaLabel={() => "precio"}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        color="primary"
                        max={10000}
                        sx={{ color: "#09879c" }}
                      />
                      <div className="mb-6" id="slider-non-linear-step" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Derecha */}
            <div class="col-lg-9">
              {paquetes.length == 0 ? (
                <div
                  style={{
                    width: "100%",
                    marginTop: "25%",
                    textAlign: "center",
                  }}
                >
                  <p>LO SENTIMOS PERO NO TENEMOS HOTELES PARA ESE DESTINO</p>
                </div>
              ) : (
                !loading && (
                  <>
                    {paquetes.map((record, index) => {
                      return (
                        <>
                          <ItemPaquete
                            key={index}
                            id={record.id}
                            nombre={record.nombre}
                            ciudad={record.ciudad}
                            descuento={record.descuento}
                            imagenes={record.imagenes}
                            descripcionCorta={record.descripcionCorta}
                            dias={record.diasJson}
                            tipo={tipo}
                            habitaciones={record.habitaciones}
                          />
                        </>
                      );
                    })}
                  </>
                )
              )}
            </div>
            {/**Pagination */}
            <>
              {/**Escritorio */}
              {paquetes.length == 0 ? (
                ""
              ) : (
                <div className="d-none d-md-block w-100">
                  <div style={{ width: "100%" }}>
                    <div>
                      <TablePagination
                        style={{
                          color: "var(--color-dark-variant)",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                        component="div"
                        classes="recent-orders"
                        labelRowsPerPage="Items por página"
                        //  rowsPerPageOptions={[]}
                        count={total}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={limit}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/**Movil */}
              {paquetes.length == 0 ? (
                ""
              ) : (
                <div className="d-md-none">
                  <div className="mb-4">
                    <TablePagination
                      style={{
                        color: "var(--color-dark-variant)",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                      component="div"
                      classes="recent-orders"
                      labelRowsPerPage="Items por página"
                      //  rowsPerPageOptions={[]}
                      count={total}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HotelesGrid;
