import Drawer from "@mui/material/Drawer";
import { Fragment, useState } from "react";
import CrearHabitacion from "./operacionesModal/CrearHabitacion";
import EditarHabitacion from "./operacionesModal/EditarHabitacion";
import EliminarHabitacion from "./operacionesModal/EliminarHabitacion";

const HabitacionModal = ({ status, handleStatus, dataOperation, onSumit }) => {
  const [changes, setChanges] = useState(0);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (changes > 0) {
      onSumit();
    }

    handleStatus(open);
  };

  const onChange = () => {
    setChanges((prev) => prev + 1);
  };

  const onClose = (cambios) => {
    if (changes > 0 || cambios) {
      onSumit();
    }
    handleStatus(false);
  };

  return (
    <div>
      <Fragment key={"right"}>
        <Drawer anchor={"right"} open={status} onClose={toggleDrawer(false)}>
          {status && dataOperation.type === "create" && (
            <CrearHabitacion handleStatusModal={onClose} onSumit={onChange} />
          )}
          {status && dataOperation.type === "edit" && (
            <EditarHabitacion
              dataEdit={dataOperation}
              handleStatusModal={onClose}
              onSumit={onChange}
            />
          )}
          {status && dataOperation.type === "delete" && (
            <EliminarHabitacion
              dataEdit={dataOperation}
              handleStatusModal={onClose}
              onSumit={onChange}
            />
          )}
        </Drawer>
      </Fragment>
    </div>
  );
};

export default HabitacionModal;
