import { useState, useEffect, useContext } from "react";
import { CircularProgress, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import HabitacionService from "../../../services/Habitacion.service";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { AuthContext } from "../../../context/AuthContext";
import ReservaService from "../../../services/Reserva.service";
import { nanoid } from "nanoid";
import { toast } from "react-toastify";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#e3036d",
  backgroundColor: "white",
  borderColor: "#e3036d",
}));

const FormReservacion = ({
  handleShowFormReserva,
  fechaData,
  onSumit,
  agenda,
}) => {
  const [habitacion, setHabitacion] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  let today = new Date();
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );
  const [filtroFechaFin, setFiltroFechaFin] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );

  useEffect(() => {
    if (fechaData?.date) {
      console.log(fechaData.date);
      let newDate = new Date(`${fechaData.date} 00:00:00`);
      setFiltroFechaInicio(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(newDate)
      );
      setFiltroFechaFin(
        new Intl.DateTimeFormat("az", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(newDate)
      );
    }
    getHabitacion();
  }, [fechaData]);

  const getHabitacion = async () => {
    try {
      const resutl = await HabitacionService.getId(fechaData.value);
      setHabitacion(resutl);
      console.log(resutl);
      console.log(currentUser);
      setLoading(false);
    } catch (error) {}
  };

  const handleFiltroFechaInicio = (newFecha) => {
    setFiltroFechaInicio(newFecha);
  };
  const handleFiltroFechaFin = (newFecha) => {
    setFiltroFechaFin(newFecha);
  };

  const generarReservacion = async () => {
    try {
      const usuario = {
        nombre: currentUser.nombre,
        id: currentUser.id,
      };
      const paquetePago = {
        id: habitacion.id,
        hotelName: habitacion.hotel.nombre,
        precio: habitacion.precio,
        fechaInicial: filtroFechaInicio,
        fechaFinal: filtroFechaFin,
        tipo: habitacion.tipo,
        hotelId: habitacion.hotel.id,
        habitacionId: habitacion.id,
        cupo: habitacion.cupo,
        agendaId: agenda.id,
      };

      const dataOrden = {
        usuario,
        paquetes: paquetePago,
        precio: habitacion.precio,
        currentUser: currentUser.id,
        uuid: nanoid(17),
        detalles: "",
      };

      await ReservaService.create(dataOrden);
      onSumit();
      handleShowFormReserva();
    } catch (error) {
      toast.info(error.message);
    }
  };

  return (
    <Box sx={{ width: "450px" }} role="presentation">
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div
          style={{
            padding: "1.8rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <h2 class="card-header text-uppercase text-center bg-smoke border-bottom">
            Agendar fecha
          </h2>

          <div>
            <TextField
              id="date"
              label="Fecha de inicio"
              type="date"
              value={filtroFechaInicio}
              InputLabelProps={{
                style: { color: "#e9036e" },
                shrink: true,
              }}
              style={{
                width: "100%",
                color: "#6c757d !important",
                marginTop: "1rem",
              }}
              sx={{
                color: "#6c757d !important",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#e9036e !important",
                },
              }}
              onChange={(e) => {
                handleFiltroFechaInicio(e.target.value);
              }}
            />
            <TextField
              id="date"
              label="Fecha de inicio"
              value={filtroFechaFin}
              type="date"
              InputLabelProps={{
                style: { color: "#e9036e" },
                shrink: true,
              }}
              style={{
                width: "100%",
                color: "#6c757d !important",
                marginTop: "2rem",
              }}
              sx={{
                color: "#6c757d !important",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#e9036e !important",
                },
              }}
              onChange={(e) => {
                handleFiltroFechaFin(e.target.value);
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <h3>{habitacion?.hotel?.nombre}</h3>
            <div
              style={{
                display: "flex",
                gap: "2rem",
              }}
            >
              <div>
                <h4>Tipo:</h4>
                {habitacion?.tipo}
              </div>
              <div>
                <h4>Precio:</h4> ${habitacion?.precio}
              </div>
              <div>
                {" "}
                <h4>Cupo:</h4>
                {habitacion?.cupo}
              </div>
            </div>
            <div>
              <ColorButton
                variant="outlined"
                onClick={() => {
                  handleShowFormReserva();
                }}
              >
                Cancelar
              </ColorButton>{" "}
              <ColorButton
                variant="outlined"
                onClick={() => {
                  generarReservacion();
                }}
              >
                Crear reservación
              </ColorButton>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default FormReservacion;
