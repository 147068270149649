/* React */
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

/* Components & dependencies */
import { toast } from "react-toastify";
import FullPageLoading from "../../FullPageLoading";
import Carousel from "react-bootstrap/Carousel";
import ItemBlog from "../blog/ItemBlog";
import GoogleMaps from "simple-react-google-maps";
import { useCart } from "react-use-cart";
import DetallesProductoRenglon from "./DetallesProductoRenglon";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
/* services */
import S3Service from "../../../services/S3.service";
import PaqueteService from "../../../services/Paquete.service";
import PaypalService from "../../../services/Paypal.service";
import TerminosService from "../../../services/Terminos.service";
import { validarCorreo } from "../../../utils/Validator";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BookingStep1 = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { items, emptyCart } = useCart();
  const [orden, setOrden] = useState({});
  let paqueteArray = [];
  const [nino, setNino] = useState(0);
  let ninos = 0;
  const [extra, setExtra] = useState(0);
  let extras = 0;
  let check = 0;
  const [fecha, setFecha] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [imagenes, setImagenes] = useState([]);
  const [paquete, setPaquete] = useState();
  const [checked, setChecked] = useState(false);
  const [total, setTotal] = useState(0);
  const { currentUser } = useContext(AuthContext);
  let tipo = "";
  let titulo = "";
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [terminos, setTerminos] = useState();
  const [adulto, setAdulto] = useState(0);
  const [loading, setLoading] = useState(true);
  const emailValido = validarCorreo(orden.email);

  useEffect(() => {
    window.scrollTo(0, 0);
    getInformacion();
  }, []);

  const getInformacion = async () => {
    tipo = currentUser.tipo;
    const result = await PaqueteService.getById(parseInt(id));
    setPaquete(result);
    titulo = result.titulo;
    const imagen = await S3Service.get(result.imagenes[0]);
    setImagenes(imagen.result);
    let niño = 0;
    let niñoPrecio = 0;
    let extraa = 0;
    let extraPrecio = 0;
    let adultoPrecio = 0;
    items.map((record, index) => {
      console.log("Record:" + record.name);
      if (record.name == "Niño") {
        console.log("Niño!!:" + record.name);
        niño = niño + Number(record.price);
        ninos = ninos + 1;
        niñoPrecio = niñoPrecio + Number(record.price);
      }
      if (record.name == "Extra") {
        extraa = extraa + record.price;
        extras += 1;
        extraPrecio = extraPrecio + Number(record.price);
      }
      if (record.name == "Adulto") {
        setAdulto(record.cantidad);
        let fechaInicial = new Date(record.fechaInicial);
        setFecha(fechaInicial.toLocaleDateString());
        let f = new Date(record.fechaFinal);
        setFechaFinal(f.toLocaleDateString());
        adultoPrecio = Number(record.price);
      }
    });
    setTotal(Number(adultoPrecio) + Number(niñoPrecio) + Number(extraPrecio));
    setNino(ninos);
    setExtra(extras);
    const dataTerminos = await TerminosService.get();
    setTerminos(dataTerminos[0]);
    setLoading(false);
  };

  const generarOrden = async () => {
    if (!orden.nombre) {
      toast.info("Debes de colocar tu nombre");
    } else if (!orden.apellido) {
      toast.info("Debes de colocar tu(s) apellidos");
    } else if (!orden.calle) {
      toast.info("Debes de colocar tu calle");
    } else if (!orden.colonia) {
      toast.info("Debes de colocar tu colonia");
    } else if (!orden.ciudad) {
      toast.info("Debes de colocar tu ciudad");
    } else if (!orden.cp) {
      toast.info("Debes de colocar tu código postal");
    } else if (!orden.estado) {
      toast.info("Debes de colocar tu estado");
    } else if (!orden.pais) {
      toast.info("Debes de colocar tu pais");
    } /*else if (!orden.telefono) {
      toast.info("Debes de colocar tu número de teléfono");
    }*/ else if (!emailValido) {
      toast.info("Debes de colocar tu correo");
    } else if (!orden.detalles) {
      toast.info("Debes de colocar detalles");
    } else if (!checked) {
      toast.info("Debes aceptar los terminos y condiciones");
    } else {
      setLoading(true);
      try {
        const usuario = {
          nombre: orden.nombre,
          apellido: orden.apellido,
          calle: orden.calle,
          colonia: orden.colonia,
          ciudad: orden.ciudad,
          cp: orden.cp,
          estado: orden.estado,
          pais: orden.pais,
          telefono: orden.telefono,
          email: orden.email,
          state: "En proceso",
        };

        const paquetePago = {
          id: id,
          titulo: paquete.titulo,
          adulto: adulto,
          nino: nino,
          extra: extra,
          fechaInicial: fecha,
          fechaFinal: fechaFinal,
          tipo: tipo,
        };

        const detalles = orden.detalles;
        const precio = total.toFixed(2);
        const dataOrden = {
          usuario,
          paquetes: paquetePago,
          precio,
          detalles,
        };

        //console.log("BEFORE: dataOrden: " + dataOrden.precio);
        let cupoActual = Number(paquete.cupo) - Number(paquete.reservas);
        //toast.success("cupo Actual"+cupoActual);
        let totalPersonas = Number(adulto) + Number(nino) + Number(extra);
        //toast.success("total "+totalPersonas);
        let cup = cupoActual - totalPersonas;
        //toast.success("cup "+cup);
        if (cup >= 0) {
          PaypalService.generarOrden(dataOrden).then((response) => {
            console.log("---------dataOrden" + response.message);
            emptyCart();
            window.location.href = response.links[1].href;
          });
        } else {
          toast.error(
            "No hay cupo suficiente, solo se cuenta con " +
              cupoActual +
              " asientos. Pero no dudes en comunicarte con nosotros."
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          <div className="main-wrapper booking-step-1">
            {/* ====================================
———	PAGE TITLE
===================================== */}
            <section className="page-title">
              <div
                className="page-title-img bg-img bg-overlay-darken"
                style={{
                  backgroundImage: "url(assets/img/pages/page-title-bg7.jpg)",
                }}
              >
                <div className="containerPublic">
                  <div
                    className="row align-items-center justify-content-center"
                    style={{ height: 200 }}
                  >
                    <div className="col-lg-6">
                      <div className="page-title-content">
                        <div className="title-border">
                          <h2 className="text-uppercase text-white font-weight-bold">
                            Reservar - paso 1
                          </h2>
                        </div>
                        <p className="text-white mb-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* ====================================
———	BOOKING SECTION
===================================== */}
            <section className="py-8 py-md-10">
              <div className="containerPublic">
                {/* Paso */}
                <div className="mb-8">
                  <div className="row progress-wizard">
                    <div className="col-4 progress-wizard-step active">
                      <div className="progress">
                        <div className="progress-bar" />
                      </div>
                      <a className="progress-wizard-dot">
                        <div className="progress-wizard-content">
                          <i className="fa fa-user" aria-hidden="true" />
                          <span className="d-block">
                            1. Informacion personal
                          </span>
                        </div>
                      </a>
                    </div>
                    <div className="col-4 progress-wizard-step incomplete">
                      <div className="progress">
                        <div className="progress-bar" />
                      </div>
                      <a className="progress-wizard-dot">
                        <div className="progress-wizard-content">
                          <i className="fa fa-usd" aria-hidden="true" />
                          <span className="d-block">2. Pago</span>
                        </div>
                      </a>
                    </div>
                    <div className="col-4 progress-wizard-step incomplete">
                      <div className="progress">
                        <div className="progress-bar" />
                      </div>
                      <a className="progress-wizard-dot">
                        <div className="progress-wizard-content">
                          <i className="fa fa-check" aria-hidden="true" />
                          <span className="d-block">3. Confirmación</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                {/* Formulario */}
                <div className="row">
                  <div className="col-md-7 col-lg-8 order-1 order-md-0">
                    <h3 className="text-capitalize mb-5">
                      Informacion personal
                    </h3>
                    <form action="" method="post" target="_blank">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="inputName">Nombre(s)</label>
                            <input
                              type="text"
                              className="form-control border-0 bg-smoke"
                              value={orden?.nombre}
                              onChange={(e) =>
                                setOrden({ ...orden, nombre: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="inputName">Apellido(s)</label>
                            <input
                              type="text"
                              className="form-control border-0 bg-smoke"
                              value={orden?.apellido}
                              onChange={(e) =>
                                setOrden({ ...orden, apellido: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="inputName">Calle</label>
                            <input
                              type="text"
                              className="form-control border-0 bg-smoke"
                              value={orden?.calle}
                              onChange={(e) =>
                                setOrden({ ...orden, calle: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="inputName">Colonia</label>
                            <input
                              type="text"
                              className="form-control border-0 bg-smoke"
                              value={orden?.colonia}
                              onChange={(e) =>
                                setOrden({ ...orden, colonia: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="inputName">Ciudad</label>
                            <input
                              type="text"
                              className="form-control border-0 bg-smoke"
                              value={orden?.ciudad}
                              onChange={(e) =>
                                setOrden({ ...orden, ciudad: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="inputName">Codigo postal</label>
                            <input
                              type="number"
                              className="form-control border-0 bg-smoke"
                              value={orden?.cp}
                              onChange={(e) =>
                                setOrden({ ...orden, cp: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="inputName">Estado/Region</label>
                            <input
                              type="text"
                              className="form-control border-0 bg-smoke"
                              value={orden?.estado}
                              onChange={(e) =>
                                setOrden({ ...orden, estado: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="inputName">Pais</label>
                            <input
                              type="text"
                              className="form-control border-0 bg-smoke"
                              value={orden?.pais}
                              onChange={(e) =>
                                setOrden({ ...orden, pais: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="inputName">
                              Email{" "}
                              {emailValido ? (
                                ""
                              ) : (
                                <span style={{ color: "red" }}>
                                  Coloca un correo valido
                                </span>
                              )}
                            </label>
                            <input
                              type="email"
                              className="form-control border-0 bg-smoke"
                              value={orden?.email}
                              onChange={(e) =>
                                setOrden({ ...orden, email: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="inputName">
                              Número de teléfono
                            </label>
                            <input
                              type="number"
                              className="form-control border-0 bg-smoke"
                              value={orden?.telefono}
                              onChange={(e) =>
                                setOrden({ ...orden, telefono: e.target.value })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-5">
                        <label htmlFor="exampleFormControlTextarea1">
                          Detalles
                        </label>
                        <textarea
                          className="form-control border-0 bg-smoke"
                          rows={7}
                          defaultValue={""}
                          value={orden?.detalles}
                          onChange={(e) =>
                            setOrden({ ...orden, detalles: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group form-check mb-9">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                          onChange={() => {
                            checked ? setChecked(false) : setChecked(true);
                          }}
                          checked={checked}
                          style={{ appearance: "checkbox" }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheck1"
                        >
                          He leído y acepto los {"  "}
                          <a href="#" onClick={handleOpen}>
                            términos y condiciones
                          </a>
                        </label>
                      </div>
                      <div className="text-center text-md-left text-lg-right">
                        <button
                          type="button"
                          onClick={() => generarOrden()}
                          className="btn btn-primary text-uppercase"
                        >
                          Siguiente
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* Detalles */}
                  <div className="col-md-5 col-lg-4">
                    <h3 className="mb-5">Detalles</h3>
                    <div className="card bg-smoke mb-6 mb-md-0">
                      <img
                        className="card-img-top lazyestload"
                        data-src="assets/img/booking/booking.jpg"
                        src={imagenes}
                        alt="Card image cap"
                      />
                      <div className="card-body">
                        <h6 className="card-title text-dark">
                          {paquete?.titulo}
                        </h6>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item bg-transparent border-top-0 px-0 py-4">
                            <span>
                              <i
                                className="fa fa-calendar mr-1"
                                aria-hidden="true"
                              />
                              Empieza:{"  "}
                            </span>
                            <span className="text-gray-color">{fecha}</span>
                          </li>
                          <li className="list-group-item bg-transparent px-0 py-4 border-off-white">
                            <span>
                              <i
                                className="fa fa-calendar mr-1"
                                aria-hidden="true"
                              />
                              Termina:{"  "}
                            </span>
                            <span className="text-gray-color">
                              {fechaFinal}
                            </span>
                          </li>
                          <li className="list-group-item bg-transparent px-0 py-4 border-off-white">
                            <span>
                              <i
                                className="fa fa-user mr-1"
                                aria-hidden="true"
                              />
                              Huespedes:{"  "}
                            </span>
                            <span className="text-gray-color">
                              {adulto} Adultos, {nino} Niño, {extra} Extras
                            </span>
                          </li>
                          <li className="list-group-item bg-transparent px-0 py-4 d-none" />
                        </ul>
                      </div>
                      <div className="card-footer mt-6">
                        <h2 className="mb-0">
                          <span>Total:{"   "}</span>
                          <span className="text-primary font-weight-bold">
                            ${total}
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ position: "absolute" }}
            >
              <Box
                sx={style}
                style={{
                  overflow: "auto",
                  textOverflow: "ellipsis",
                  maxHeight: "500px",
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Terminos y condiciones
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: terminos?.terminos,
                    }}
                    style={{ textAlign: "justify" }}
                  ></p>
                </Typography>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="btn btn-primary text-uppercase"
                  >
                    OK
                  </button>
                </div>
              </Box>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default BookingStep1;
