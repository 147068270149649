import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import PagoService from "../../../services/Pago.service";
import ReservaService from "../../../services/Reserva.service";
import { toast } from "react-toastify";

import { useEffect, useState } from "react";

const Reservacion = ({ reservacion, onSumit, handleRemove }) => {
  let today = new Date();
  const statusFormat = {
    EnProceso: "1px solid #e9036e",
    Cancelado: "1px solid #ddebf3",
    Confirmado: "1px solid #009074",
  };
  const [filtroFechaInicio, setFiltroFechaInicio] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );
  const [filtroFechaFin, setFiltroFechaFin] = useState(
    new Intl.DateTimeFormat("az", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(today)
  );

  const cancelReservacion = async (data, reservacionId) => {
    try {
      try {
        data.status = "Cancelado";
        PagoService.update({ data, reservacionId });
        onSumit();
      } catch (error) {}
    } catch (error) {}
  };

  const confirmarPago = async (data, reservacionId) => {
    let statusSave = data.status;
    try {
      data.status = "Confirmado";
      await PagoService.update({ data, reservacionId });
      onSumit();
    } catch (error) {
      console.log(error);
      toast.info(error.message);
      data.status = statusSave;
    }
  };

  const eliminarReserva = async (data, reservacionId) => {
    try {
      await ReservaService.remove(reservacionId);
      onSumit();
      handleRemove(reservacionId);
    } catch (error) {}
  };

  useEffect(() => {
    let newDate = new Date(reservacion.fechaInicio);
    let newDateFinal = new Date(reservacion.fechaFinal);
    setFiltroFechaInicio(
      new Intl.DateTimeFormat("az", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(newDate)
    );
    setFiltroFechaFin(
      new Intl.DateTimeFormat("az", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(newDateFinal)
    );
  }, []);

  return (
    <Card
      sx={{ display: "flex", border: statusFormat[reservacion?.pago?.status] }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            Identificador
          </Typography>
          <Typography component="div" variant="h5">
            {reservacion?.paypal_id}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {filtroFechaInicio} {filtroFechaFin}
          </Typography>
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
          <Typography component="div" variant="h6">
            {reservacion?.pago?.status}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{ width: 151, display: "flex", alignItems: "center", pl: 1, pb: 1 }}
      >
        <IconButton
          aria-label="cancel"
          onClick={() => {
            cancelReservacion(reservacion?.pago, reservacion.id);
          }}
        >
          <CancelIcon sx={{ height: 28, width: 28 }} />
        </IconButton>
        <IconButton
          aria-label="check"
          onClick={() => {
            confirmarPago(reservacion?.pago, reservacion.id);
          }}
        >
          <CheckCircleIcon sx={{ height: 28, width: 28 }} />
        </IconButton>
        <IconButton
          aria-label="check"
          onClick={() => {
            eliminarReserva(reservacion?.pago, reservacion.id);
          }}
        >
          <DeleteIcon sx={{ height: 28, width: 28 }} />
        </IconButton>
      </Box>
    </Card>
  );
};

export default Reservacion;
