import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3Service from "../../../services/S3.service";
import UserService from "../../../services/User.Service";

const BlogCard = ({ id, titulo, imagenes, fecha }) => {
  const navigate = useNavigate();
  const [imag, setImag] = useState([]);
  const [autor, setAutor] = useState([]);
  let imagen =
    "https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/circular_progress_indicator_square_small.gif";
  let autorNombre;

  useEffect(() => {
    getImagen();
  }, []);

  const getImagen = async () => {
    try {
      const result = await S3Service.get(imagenes);
      setImag(result.result);
      imagen = result.result;
      const image = document.querySelector(`#imagenProducto${id}`);
      image.style.backgroundImage = `url('${result.result}')`;
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="media meta-post-sm border-bottom border-off-white pb-3 mb-3">
      <div className="img-overlay rounded mr-2">
        <img
          className="lazyestload"
          data-src="assets/img/blog/tab-01.jpg"
          src={imag}
          alt="Generic placeholder image"
          height={"70px"}
          width={"100px"}
          style={{ resize: "both", width: "100px" }}
        />
        <a
          href="javascript:void(0)"
          onClick={() => navigate(`/blog/${id}`)}
          className="hover-img-overlay-dark"
        />
      </div>
      <div className="media-body">
        <a
          href="javascript:void(0)"
          onClick={() => navigate(`/blog/${id}`)}
          className="text-dark hover-text-primary text-capitalize mb-1"
        >
          {titulo.slice(0, 15)}
        </a>
        <ul className="list-unstyled d-flex flex-wrap mb-0">
          <li className="meta-tag text-gray-color mr-4 mb-1">
            <i className="fa fa-calendar" aria-hidden="true" />
            <span className="ml-1 text-capitalize">{fecha.slice(0, 10)}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BlogCard;
