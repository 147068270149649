import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Amenidades = ({ amenidades }) => {
  return (
    <>
      {amenidades?.map((amenidad, index) => (
        <div key={index} class="col-lg-6">
          <h3 style={{ display: "flex", alignItems: "center" }}>
            {amenidad?.label}
          </h3>
          <ul class="list-unstyled mb-0">
            {amenidad?.options?.map((option, index) => (
              // <li key={index} style={{ display: "flex", alignItems: "center" }}>
              //   <FiberManualRecordIcon sx={{ fontSize: 6 }} />{" "}
              //   <span style={{ paddingInline: "0.5rem" }}>{option.text}</span>
              // </li>
              <li class="media">
                <div class="text-gray-color mr-2">
                  <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
                </div>
                <div class="media-body">
                  <p>{option.text}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

export default Amenidades;
