/* React */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/* Componentes y dependencias */
import { Typography } from "@mui/material";
import Preloader from "../../utils/Preloader";
import { Button, TablePagination } from "@mui/material";
import { toast } from "react-toastify";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import MUIRichTextEditor from "mui-rte";

/* Servicios */
import TerminosService from "../../../services/Terminos.service";

import styles from "../layout.module.css";

const TablaTerminos = () => {
  const [terminos, setTerminos] = useState({ undefined });
  const [loading, setLoading] = useState(true);
  const [contenido, setContenido] = useState({});
  const myTheme = createTheme({
    overrides: {
      MUIRichTextEditor: {
        root: {
          marginTop: 20,
          backgroundColor: "whitesmoke",
          minHeight: "15rem",
        },
        editor: {},
        toolbar: {
          backgroundColor: "white",
        },
      },
    },
  });

  useEffect(() => {
    getAviso();
  }, []);

  const getAviso = async () => {
    setLoading(true);
    try {
      const ter = await TerminosService.get();
      setTerminos(ter[0]);
      console.log("----" + ter[0].terminos);

      const contentHTML = convertFromHTML(ter[0].terminos);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      const content = JSON.stringify(convertToRaw(state));

      setContenido(content);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateTerminos = async () => {
    if (!terminos.terminos) {
      toast.info(
        "Error, tienes que agregar texto a los terminos y condiciones"
      );
    } else {
      setLoading(true);
      try {
        const dataTerminos = terminos;
        const updateTerminos = await TerminosService.update(dataTerminos);
        toast.success("Terminos y condiciones actualizados con exito!");
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
        getAviso();
      }
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className={styles.recentorders}>
          <div style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <h1
                    className="card-title"
                    style={{ marginRight: "20px", marginTop: "6px" }}
                  >
                    TERMINOS Y CONDICIONES
                  </h1>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">
                    <ThemeProvider theme={myTheme}>
                      <MUIRichTextEditor
                        label="Descripcion del paquete"
                        controls={[
                          "italic",
                          "underline",
                          "strikethrough",
                          "highlight",
                          "undo",
                          "redo",
                          "link",
                          "numberList",
                          "bulletList",
                          "quote",
                          "code",
                          "clear",
                          // "save"
                        ]}
                        // onSave={save}
                        inlineToolbar={true}
                        onChange={(value) => {
                          console.log(value.getCurrentContent());
                          setTerminos({
                            ...terminos,
                            terminos: stateToHTML(value.getCurrentContent()),
                          });
                        }}
                        defaultValue={contenido}
                      />
                    </ThemeProvider>
                  </td>
                </tr>
                <tr>
                  <div
                    className="col-lg-12"
                    style={{ textAlign: "center", marginTop: "3%" }}
                  >
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => updateTerminos()}
                    >
                      Guardar
                    </Button>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default TablaTerminos;
